import type { CancelablePromise, TListFuelPathwaysRequest, TListFuelPathwaysResponse } from '/src/api';
import { OpenAPI, request as __request } from '/src/api';

export class FuelPathwayService {
  /**
   * List FuelPathways
   * @returns FuelPathway[] FuelPathway[]
   * @throws ApiError
   */
  public static list(req?: TListFuelPathwaysRequest): CancelablePromise<TListFuelPathwaysResponse> {
    return __request(OpenAPI, { method: 'GET', url: '/fuelPathways', query: req });
  }
}
