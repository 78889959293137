import { Grid, Layer, Main } from 'grommet';
import { observer } from 'mobx-react-lite';
import { PropsWithChildren, useEffect, useState } from 'react';
import { ActiveClientModal, Box } from 'src/components';
import { useClientStore, useUserStore } from 'src/context';

export const UseActiveClient: React.FC<UseActiveClientProps> = observer(({ children }) => {
  const userStore = useUserStore();
  const clientStore = useClientStore();

  const [modalIsVisible, setModalIsVisible] = useState(false);

  const isLoading = !userStore.user || !clientStore.activeClientId;

  useEffect(() => {
    if (userStore.user && userStore.isExternalUser && !clientStore.activeClientId) {
      if (userStore.user.clients.length === 1) {
        clientStore.setActiveClientId(userStore.user.clients[0].id);
      } else {
        setModalIsVisible(true);
      }
    }
  }, [userStore.user, userStore.isExternalUser, clientStore.activeClientId]);

  return userStore.isInternalUser ? (
    <>{children}</>
  ) : (
    <>
      {!isLoading && children}
      {userStore.user && modalIsVisible && (
        <Layer full animate={false}>
          <Grid
            height="100vh"
            rows={['flex']}
            columns={['auto']}
            areas={[{ name: 'main', start: [0, 0], end: [0, 0] }]}
          >
            <Main gridArea="main" background="light">
              <Box justify="center" align="center" flex="grow">
                <ActiveClientModal user={userStore.user} setIsVisible={setModalIsVisible} canBeClosed={false} />
              </Box>
            </Main>
          </Grid>
        </Layer>
      )}
    </>
  );
});

export type UseActiveClientProps = PropsWithChildren;
