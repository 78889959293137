export type ErrorMessages = {
  default: string;
  invalidLogin: string;
  invalidPasswordResetToken: string;
  invalidVerifyEmailToken: string;
  clientNameSearchNoResult: string;
  fuelPathwayNameSearchNoResult: string;
  notCurrentPassword: string;
  passwordNotConfirmed: string;
  passwordNotEntered: string;
  passwordTooShort: string;
  passwordTooShortGeneric: string;
  passwordsNotMatch: string;
  invalidCurrentPassword: string;
};

export const errorMessages: ErrorMessages = {
  // Used on RootErrorPage
  default: 'Something went wrong! Refresh the page to try again.',

  // Login
  invalidLogin: 'Invalid username or password',

  // Tokens
  invalidPasswordResetToken:
    'This token is invalid or has expired. Please request a new token by clicking "Reset Password" on the Login page.',
  invalidVerifyEmailToken:
    'This token is invalid or has expired. To request a new verification email, re-enter your new email address on your User Profile page.',

  // Searches
  clientNameSearchNoResult: 'No Companies found with that name.',
  fuelPathwayNameSearchNoResult: 'No fuel pathways found with that name.',

  // Passwords
  notCurrentPassword: 'Must provide current password',
  passwordNotConfirmed: 'Must confirm password',
  passwordNotEntered: 'Must enter password',
  passwordTooShort: 'Password must be at least 10 characters',
  passwordTooShortGeneric: 'Password must be longer',
  passwordsNotMatch: 'Passwords must match',
  invalidCurrentPassword: 'Invalid current password',
};
