import { Route } from 'react-router-dom';
import { CreateEquipmentPage } from './create-equipment';
import { EquipmentDetailsPage } from './equipment-details';
import { EquipmentUsageDetailsPage } from './equipment-usage-details';
import { ListEquipmentPage } from './list-equipment';
import { UseActiveClient, RequireAuth } from '/src/components';

export { CreateEquipmentPage, EquipmentDetailsPage, EquipmentUsageDetailsPage, ListEquipmentPage };

export const equipmentRoutes: React.ReactElement[] = [
  // Create Client Equipment
  <Route
    path="/clients/:client_id/equipment/create"
    element={
      <RequireAuth>
        <UseActiveClient>
          <CreateEquipmentPage />
        </UseActiveClient>
      </RequireAuth>
    }
  />,

  // Equipment Usage Details
  <Route
    path="/clients/:client_id/equipment/:equipment_id/usages/:equipment_usage_id"
    element={
      <RequireAuth>
        <UseActiveClient>
          <EquipmentUsageDetailsPage />
        </UseActiveClient>
      </RequireAuth>
    }
  />,

  // Equipment Details
  <Route
    path="/clients/:client_id/equipment/:equipment_id"
    element={
      <RequireAuth>
        <UseActiveClient>
          <EquipmentDetailsPage />
        </UseActiveClient>
      </RequireAuth>
    }
  />,

  // List Equipment by Facility
  <Route
    path="/clients/:client_id/facilities/:facility_id/equipment"
    element={
      <RequireAuth>
        <UseActiveClient>
          <ListEquipmentPage />
        </UseActiveClient>
      </RequireAuth>
    }
  />,
];
