import { Table, TableBody, TableCell, TableHeader, TableRow } from 'grommet';
import * as React from 'react';
import { Box, BoxProps, Text } from '/src/components';
import { pxToPt } from '/src/utils';

export const IncentiveStatementCalculationKey: React.FC = () => {
  const calculationKeys = React.useRef<{ key: string; definition: string }[]>([
    {
      key: 'Credits',
      definition: 'Credit generation is based on the amount of reported kWh used/dispensed by your electric equipment.',
    },
    {
      key: 'Credit Price',
      definition:
        'Credit price is determined by the value of each credit sold to regulated entities (oil & gas companies) on the open market.',
    },
    {
      key: 'REC Cost',
      definition:
        "Renewable Energy Certificates (RECs) are megawatt-hrs. of clean electricity procured on your behalf to enhance the amount of credits generated, when financially beneficial. Purchasing REC's at this cost resulted in an increased credit generation by 20-30%.",
    },
    {
      key: 'Broker Cost',
      definition:
        'Administrative cost associated with brokerage services, REC procurement, and contracting with regulated entities.',
    },
    {
      key: 'FuSE Cost',
      definition: 'Agreed upon commission retained by FuSE after REC Cost and Broker Cost are subtracted.',
    },
    {
      key: 'Cost Recoup',
      definition:
        'Funds withheld in cases where FuSE has fronted cost of metering hardware or other Exchange activities.',
    },
    {
      key: 'Net Incentive',
      definition: 'Amount remitted to your organization after all costs and fees have been subtracted.',
    },
  ]);

  // Cells
  const border: BoxProps['border'] = { side: 'all', color: 'text' };
  const headerVerticalPad = pxToPt('10px');
  const itemVerticalPad = pxToPt('8px');
  const itemHorizontalPad = pxToPt('6px');

  // Text
  const lineHeight = pxToPt('14px');
  const headerSize = pxToPt('11px');
  const descriptionSize = pxToPt('10px');

  const getBackground = (rowIndex: number) => (rowIndex % 2 ? 'white' : 'light-6');

  return (
    <Box fill="horizontal">
      <Table>
        <TableHeader>
          <TableRow>
            <TableCell
              scope="col"
              size="20%"
              align="center"
              justify="center"
              border={border}
              pad={{ vertical: headerVerticalPad }}
            >
              <Text size={headerSize} lineHeight={lineHeight} weight={700}>
                Calculation Key
              </Text>
            </TableCell>
            <TableCell
              scope="col"
              size="80%"
              align="center"
              justify="center"
              border={border}
              pad={{ vertical: headerVerticalPad }}
            >
              <Text size={headerSize} lineHeight={lineHeight} weight={700}>
                Definition
              </Text>
            </TableCell>
          </TableRow>
        </TableHeader>
        <TableBody>
          {calculationKeys.current.map(({ key, definition }, i) => (
            <TableRow key={key}>
              <TableCell
                scope="row"
                size="20%"
                align="center"
                justify="center"
                border={border}
                pad={{ vertical: itemVerticalPad, horizontal: itemHorizontalPad }}
                background={getBackground(i)}
              >
                <Text size={descriptionSize} lineHeight={lineHeight} weight={700}>
                  {key}
                </Text>
              </TableCell>
              <TableCell
                scope="row"
                size="80%"
                justify="center"
                border={border}
                background={getBackground(i)}
                pad={{ vertical: itemVerticalPad, horizontal: itemHorizontalPad }}
              >
                <Box>
                  <Text size={descriptionSize} lineHeight={lineHeight}>
                    {definition}
                  </Text>
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};
