import { Button, Layer } from 'grommet';
import { Close } from 'grommet-icons';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ApiError, EquipmentService } from '/src/api';
import { EntityIcon, FormCard, Select } from '/src/components';
import { useClientStore, useGlobalStore } from '/src/context';
import { Equipment, ModelName } from '/src/lib/models';
import { toastMessages } from '/src/lib/toast';
import { DateString } from '/src/lib/types';
import { getFormErrors, getModelLabel, updateFormValue } from '/src/utils';

export type TRetireEquipmentModalForm = {
  last_active_reporting_quarter: DateString;
};

export const RetireEquipmentModal: React.FC<RetireEquipmentModalProps> = observer((props) => {
  const { equipment, setIsVisible, setShouldRefresh } = props;

  /** Stores **/
  const globalStore = useGlobalStore();
  const clientStore = useClientStore();

  /** State **/
  const [isRetiring, setIsRetiring] = useState(false);
  const [formValues, setFormValues] = useState<TRetireEquipmentModalForm>({ last_active_reporting_quarter: '' });
  const [formErrors, setFormErrors] = useState<Record<string, string>>({});

  /** Computed **/
  const isLoading = !clientStore.reportingPeriodOptions;

  /** Methods **/
  const updateValue = (key: string, value: any) =>
    updateFormValue(key, value, formValues, setFormValues, formErrors, setFormErrors);

  const retireEquipment = async () => {
    if (isRetiring) return;
    const errors = getFormErrors(
      {
        last_active_reporting_quarter: { label: 'Retirement Period', required: true },
      },
      formValues
    );
    if (errors) {
      setFormErrors(errors);
      return;
    }

    try {
      setIsRetiring(true);
      await EquipmentService.retire({
        id: equipment.id,
        last_active_reporting_quarter: formValues.last_active_reporting_quarter,
      });
      setShouldRefresh(true);
      setIsVisible(false);
      toast.success(toastMessages.retireEquipment.success);
    } catch (err) {
      globalStore.handleApiError(err as ApiError, toastMessages.retireEquipment.error);
    } finally {
      setIsRetiring(false);
    }
  };

  /** Effects **/
  useEffect(() => {
    if (equipment.client_id) {
      clientStore.fetchClientReportingPeriods(equipment.client_id, equipment.reporting_period_type_id);
    }
  }, [equipment.client_id, equipment.reporting_period_type_id]);

  /** Render **/
  return (
    <Layer onEsc={() => setIsVisible(false)} onClickOutside={() => setIsVisible(false)} background="transparent">
      <FormCard
        width="30rem"
        title={`Retire ${getModelLabel(equipment, ModelName.Equipment)}`}
        icon={<EntityIcon entityName={ModelName.Equipment} />}
        onSubmit={() => retireEquipment()}
        isLoading={isLoading || isRetiring}
        hideRequiredText
        saveButtonLabel="SAVE"
        saveButtonLoadingLabel="SAVING..."
        headerChildren={[
          <Button
            key="close"
            pad="none"
            onClick={() => setIsVisible(false)}
            icon={<Close size="20px" />}
            tip="Close"
          />,
        ]}
      >
        <Select
          width="50%"
          label="Retirement Period"
          placeholder="Select Period..."
          value={formValues.last_active_reporting_quarter}
          setValue={(value) => updateValue('last_active_reporting_quarter', value)}
          options={clientStore.reportingPeriodOptions ?? []}
          error={formErrors['last_active_reporting_quarter']}
          hideRequiredMarker
          required
          fill="horizontal"
        />
      </FormCard>
    </Layer>
  );
});

export type RetireEquipmentModalProps = {
  equipment: Equipment;
  setIsVisible: (isVisible: boolean) => void;
  setShouldRefresh: (shouldRefresh: boolean) => void;
};
