import { Select as GrommetSelect } from 'grommet';
import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { Box, BoxProps, Text, TextProps } from '/src/components';
import { useSearchStore } from '/src/context';
import { filterStringOptions } from '/src/utils';

export const SearchFieldSelect: React.FC<SearchFieldSelectProps> = observer((props) => {
  const { labelSize, labelWeight, ...boxProps } = props;

  // const {
  //   fieldFilter,
  //   fields,
  //   isFieldFocused,
  //   searchEntity,
  //   searchField,
  //   setSearchField,
  //   setFieldFilter,
  //   setIsFieldFocused,
  // } = useSearchStore();

  const searchStore = useSearchStore();

  const fieldOptions = useMemo(() => {
    return searchStore.searchEntity
      ? filterStringOptions(
          searchStore.fieldFilter,
          searchStore.fields[searchStore.searchEntity].map((f) => f.label)
        )
      : [];
  }, [searchStore.searchEntity, searchStore.fieldFilter, searchStore.fields]);

  return (
    <Box direction="row" fill="horizontal" {...boxProps}>
      <Box align="center" justify="center" width="20%">
        <Text toUpperCase size={labelSize || 'small'} weight={labelWeight || 300}>
          By
        </Text>
      </Box>
      <Box
        justify="center"
        fontFamily="Lato, sans-serif"
        width="80%"
        border={{ color: searchStore.isFieldFocused ? 'accent-1' : 'light-5' }}
        round="6px"
      >
        <GrommetSelect
          plain
          name="search_field"
          dropAlign={{ top: 'bottom', right: 'right' }}
          value={searchStore.searchField}
          placeholder="Choose..."
          disabled={!searchStore.searchEntity}
          options={fieldOptions}
          searchPlaceholder="Find field by name..."
          onFocus={() => searchStore.setIsFieldFocused(true)}
          onBlur={() => searchStore.setIsFieldFocused(false)}
          onClose={() => searchStore.setFieldFilter('')}
          onSearch={(filter) => searchStore.setFieldFilter(filter)}
          onChange={({ value }) => searchStore.setSearchField(value)}
        />
      </Box>
    </Box>
  );
});

export type SearchFieldSelectProps = BoxProps & {
  labelSize?: TextProps['size'];
  labelWeight?: TextProps['weight'];
};
