import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ClientService } from '/src/api';
import { BreadcrumbNav, ListPage, PageHeader, StatementList } from '/src/components';
import { useGlobalStore, useUserStore } from '/src/context';
import { toastMessages } from '/src/lib/toast';

export const ListStatementsPage = observer(() => {
  /* Context */
  const { user: currentUser, isClientUser, isFacilityUser } = useUserStore();
  const { handleApiError } = useGlobalStore();
  const { client_id } = useParams();
  const clientId = parseInt(client_id ?? '');

  /* State */
  const [clientName, setClientName] = useState('');

  /* Memos */
  const isLoading = !clientName;

  /* Effects */
  useEffect(() => {
    if (!clientName && currentUser) {
      if (isFacilityUser) {
        setClientName(currentUser.clients.find((client) => client.id === clientId)?.name ?? '');
      } else {
        ClientService.get({ id: clientId })
          .then((client) => setClientName(client.name))
          .catch((err) => handleApiError(err, toastMessages.listIncentiveStatements.error));
      }
    }
  }, [clientName]);

  /* Render */
  return (
    <ListPage
      title={clientName}
      pageHeader={<PageHeader title={clientName} />}
      breadcrumbNav={
        <BreadcrumbNav
          previousPages={[
            { name: 'Clients', link: !isClientUser && !isFacilityUser ? '/clients' : undefined },
            { name: clientName, link: !isFacilityUser ? `/clients/${clientId}` : undefined },
          ]}
          currentPageName="Statements"
        />
      }
      isLoading={isLoading}
    >
      <StatementList clientId={clientId} />
    </ListPage>
  );
});
