import { action, makeObservable, observable } from 'mobx';
import {
  ApiError,
  ModelAuditLogService,
  // TCreateModelAuditLogStatusRequest,
  TListModelAuditLogsRequest,
  TListModelAuditLogsStatesRequest,
  TUpdateModelAuditLogStateRequest,
} from '/src/api';
import BaseStore from '/src/context/stores/baseStore';
import RootStore from '/src/context/stores/rootStore';
import { ModelAuditLog, ModelAuditLogState, ModelName, TModelAuditLogId } from '/src/lib/models';
import { toastMessages } from '/src/lib/toast';
import { AuditorType, ModelAuditLogState as TModelAuditLogState, TResponseMetadata } from '/src/lib/types';

const defaultUnresolvedStatus = {
  id: 1,
  name: 'Unresolved',
} as ModelAuditLogState;

const defaultResolvedStatus = {
  id: 2,
  name: 'Resolved',
} as ModelAuditLogState;

export default class ModelAuditLogStore extends BaseStore {
  isLoading = true;
  hasUnresolved = false;
  modelAuditLog?: ModelAuditLog;
  modelAuditLogs?: ModelAuditLog[];
  modelAuditLogsMeta?: TResponseMetadata;
  modelAuditLogStates?: ModelAuditLogState[] = [];
  selectedModelAuditLog?: ModelAuditLog;
  fieldAliases: Record<string, string> = {
    Fein: 'FEIN',
  };
  currentPage = 1;
  limit = 20;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      isLoading: observable,
      hasUnresolved: observable,
      modelAuditLog: observable,
      modelAuditLogs: observable,
      modelAuditLogsMeta: observable,
      modelAuditLogStates: observable,
      selectedModelAuditLog: observable,
      fieldAliases: observable,
      currentPage: observable,
      limit: observable,
      setIsLoading: action,
      setHasUnresolved: action,
      setModelAuditLog: action,
      setModelAuditLogs: action,
      setModelAuditLogsMeta: action,
      setModelAuditLogStates: action,
      setSelectedModelAuditLog: action,
      setCurrentPage: action,
      setLimit: action,
      reset: action,
      fetchModelAuditLog: action,
      fetchModelAuditLogs: action,
      fetchModelAuditLogStates: action,
      updateModelAuditLogState: action,
    });
    Promise.all([
      this.fetchModelAuditLogs({
        action_taken_by: AuditorType.ExternalUser,
        model_audit_log_state_id: TModelAuditLogState.Unresolved,
      }),
      this.fetchModelAuditLogStates(),
    ]).finally(() => this.setIsLoading(false));
  }

  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  };

  setHasUnresolved = (hasUnresolved: boolean) => {
    this.hasUnresolved = hasUnresolved;
  };

  setModelAuditLog = (modelAuditLog?: ModelAuditLog) => {
    this.modelAuditLog = modelAuditLog;
  };

  setModelAuditLogs = (modelAuditLogs?: ModelAuditLog[]) => {
    this.modelAuditLogs = modelAuditLogs;
  };

  setModelAuditLogsMeta = (modelAuditLogsMeta?: TResponseMetadata) => {
    this.modelAuditLogsMeta = modelAuditLogsMeta;
  };

  setModelAuditLogStates = (modelAuditLogStates?: ModelAuditLogState[]) => {
    this.modelAuditLogStates = modelAuditLogStates;
  };

  setSelectedModelAuditLog = (modelAuditLog?: ModelAuditLog) => {
    this.selectedModelAuditLog = modelAuditLog;
  };

  setCurrentPage = (currentPage: number) => {
    this.currentPage = currentPage;
  };

  setLimit = (limit: number) => {
    this.limit = limit;
  };

  reset = () => {
    this.setModelAuditLogs(undefined);
    this.setModelAuditLogsMeta(undefined);
  };

  fetchModelAuditLog = async (id: TModelAuditLogId) => {
    if (!this.rootStore.userStore.isFuseAdmin) return;

    if (!this.modelAuditLogs) {
      await this.fetchModelAuditLogs();
    }
    const auditLog = (this.modelAuditLogs ?? []).find((log) => log.id === id);
    this.setModelAuditLog(
      auditLog
        ? {
            ...auditLog,
            is_resolved: !!auditLog.model_audit_log_state,
          }
        : undefined
    );
  };

  fetchModelAuditLogs = async (req?: TListModelAuditLogsRequest) => {
    if (!this.rootStore.userStore.isFuseAdmin) return;

    try {
      const response = await ModelAuditLogService.list({ page: this.currentPage, limit: this.limit, ...req });
      const { meta, data } = response;
      let hasUnresolved = false;
      this.setModelAuditLogs(
        data
          .filter((log) => log.model && Object.values(ModelName).includes(log.model_name as ModelName))
          .map((auditLog) => {
            if (!auditLog.model_audit_log_state) hasUnresolved = true;
            return {
              ...auditLog,
              is_resolved: !!auditLog.model_audit_log_state,
            };
          })
      );
      this.setModelAuditLogsMeta(meta);
      this.setHasUnresolved(hasUnresolved);
    } catch (err) {
      this.rootStore.globalStore.handleApiError(err as ApiError, toastMessages.listAuditLogs.error);
    }
  };

  fetchModelAuditLogStates = async (req?: TListModelAuditLogsStatesRequest) => {
    if (!this.rootStore.userStore.isFuseAdmin) return;
    try {
      const data = await ModelAuditLogService.listStates(req);
      this.setModelAuditLogStates(data);
      // this.setModelAuditLogStates(
      //   data?.length > 0 ? [defaultUnresolvedStatus, ...data] : [defaultUnresolvedStatus, defaultResolvedStatus]
      // );
    } catch (err) {
      this.rootStore.globalStore.handleApiError(err as ApiError, toastMessages.listAuditLogs.error);
    }
  };

  updateModelAuditLogState = async (req: TUpdateModelAuditLogStateRequest) => {
    if (!this.rootStore.userStore.isFuseAdmin) return;

    await ModelAuditLogService.updateState(req);
  };
}
