import { Button, Card, CardBody, Grid, Keyboard, Main, Spinner, TextInput } from 'grommet';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ApiError, UserService } from '../api';
import { Box, FooterNav, FuseLogo, Link, Text } from '/src/components';
import { useGlobalStore } from '/src/context';
import { toastMessages } from '/src/lib/toast';

const defaultFormData: Record<string, string> = {
  email: '',
};

const RequestPasswordReset = observer(() => {
  const globalStore = useGlobalStore();

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  // Form values
  const [formValues, setFormValues] = useState(defaultFormData);
  const [formErrors, setFormErrors] = useState<Record<string, string>>({});

  const validateForm = () => {
    const errors: Record<string, string> = {};
    if (!formValues.email) errors['email'] = 'Email address is required';
    if (Object.keys(errors).length) {
      setFormErrors(errors);
      return false;
    }
    return true;
  };

  const sendResetEmail = async () => {
    if (!validateForm()) return;

    try {
      setIsLoading(true);

      await UserService.requestPasswordReset({
        email: formValues.email,
      });
      toast.success(toastMessages.requestPasswordReset.success);
      navigate('/login');
    } catch (err) {
      globalStore.handleApiError(err as ApiError, toastMessages.requestPasswordReset.error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Grid height="100vh" rows={['flex']} columns={['auto']} areas={[{ name: 'main', start: [0, 0], end: [0, 0] }]}>
      <Main gridArea="main" background="light">
        <Box justify="center" flex="grow">
          <Box alignSelf="center" margin={{ bottom: '2rem' }}>
            <FuseLogo width="14rem" height="3.75rem" />
          </Box>
          <Card alignSelf="center" pad="2rem" width="28rem" flex={undefined}>
            <CardBody flex="grow">
              <Box margin={{ bottom: '0.5rem' }} align="center">
                <Text size="xlarge" weight={300}>
                  Reset Password
                </Text>
              </Box>
              <Box margin={{ bottom: '0.5rem' }} align="center">
                <Text textAlign="center" fontFamily="Lato, sans-serif">
                  Enter the email address associated with your account and we’ll send you a link to reset your password.
                </Text>
              </Box>
              <Box>
                {formErrors['email'] && (
                  <Text color="red" size="small">
                    {formErrors['email']}
                  </Text>
                )}
                <Keyboard onEnter={() => sendResetEmail()}>
                  <TextInput
                    name="email"
                    type="email"
                    placeholder="Email"
                    autoComplete="username"
                    required
                    value={formValues.email}
                    onChange={(e) => setFormValues({ ...formValues, email: e.target.value })}
                    style={{ borderRadius: '0.375rem', fontFamily: 'Lato, sans-serif' }}
                  />
                </Keyboard>
              </Box>
              <Button
                type="submit"
                disabled={!formValues.email}
                onClick={() => sendResetEmail()}
                margin={{ bottom: '1rem' }}
              >
                <Box background="accent-1" pad={{ horizontal: '1rem', vertical: '0.5rem' }} borderRadius="0.5rem">
                  {!isLoading && (
                    <Text alignSelf="center" size="1.25rem" color="white" lineHeight="1.875rem">
                      RESET PASSWORD
                    </Text>
                  )}
                  {isLoading && <Spinner height="1.875rem" width="1.875rem" alignSelf="center" color="white" />}
                </Box>
              </Button>
            </CardBody>
          </Card>
          <Box direction="row" justify="center" margin={{ vertical: '1rem' }}>
            <Text lineHeight="1.5rem" fontFamily="Lato, sans-serif">
              Already have an account?{' '}
              <Link to="/login" style={{ textDecoration: 'none' }}>
                Sign In
              </Link>
            </Text>
          </Box>
        </Box>
        <FooterNav flex="shrink" />
      </Main>
    </Grid>
  );
});

export default RequestPasswordReset;
