import { TClientId, TProgramId, TReportingPeriodTypeId } from '/src/lib/models';
import { DateString, FloatString } from '/src/lib/types';

export type TClientBrokerFeeRateId = number;

export interface ClientBrokerFeeRate {
  id: TClientBrokerFeeRateId;
  created_at: DateString;
  updated_at: DateString;
  client_id: TClientId;
  program_id: TProgramId;
  reporting_period_type_id: TReportingPeriodTypeId;
  start_reporting_quarter: DateString;
  end_reporting_quarter: DateString;
  percent_rate: FloatString;
}
