import { TResponseMetadata } from '/src/lib/types';

export * from './Addresses';
export * from './Auth';
export * from './ClientFiles';
export * from './ClientReportingPeriods';
export * from './Clients';
export * from './Dashboard';
export * from './Equipment';
export * from './EquipmentCategories';
export * from './EquipmentUsages';
export * from './Facilities';
export * from './FuelPathways';
export * from './IncentiveStatements';
export * from './ModelAuditLogs';
export * from './Programs';
export * from './ReportingPeriodTypes';
export * from './Roles';
export * from './SalesCommissions';
export * from './Users';
export * from './Utilities';

export type TListResponse<PayloadType> = {
  meta: TResponseMetadata;
  data: PayloadType;
};
