import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ClientService } from '/src/api';
import { BreadcrumbNav, FacilityList, ListPage, PageHeader } from '/src/components';
import { useClientStore, useGlobalStore, useUserStore } from '/src/context';
import { TClientId } from '/src/lib/models';
import { toastMessages } from '/src/lib/toast';

export const ListFacilitiesPage = observer(() => {
  const userStore = useUserStore();
  const clientStore = useClientStore();
  const { handleApiError } = useGlobalStore();
  const { client_id } = useParams();
  const clientId = parseInt(client_id ?? '');

  const [clientName, setClientName] = useState('');

  const isLoading = !clientName;

  const fetchClientName = useCallback(
    (clientId: TClientId) => {
      if (!userStore.user) return;

      if (userStore.isExternalUser) {
        setClientName(clientStore.activeClient?.name ?? '');
      } else {
        ClientService.get({ id: clientId })
          .then((client) => setClientName(client.name))
          .catch((err) => handleApiError(err, toastMessages.listFacilities.error));
      }
    },
    [userStore.user, userStore.isExternalUser, clientStore.activeClient]
  );

  useEffect(() => {
    if (!clientName) {
      fetchClientName(clientId);
    }
  }, [clientName]);

  return (
    <ListPage
      title={clientName}
      pageHeader={
        <PageHeader
          title={clientName}
          showAddButton={userStore.isAdminUser && !userStore.isFacilityUser}
          addButtonLabel="ADD FACILITY"
          addButtonUrl={`/clients/${clientId}/facilities/create`}
        />
      }
      breadcrumbNav={
        <BreadcrumbNav
          previousPages={[
            { name: 'Clients', link: !userStore.isExternalUser ? '/clients' : undefined },
            { name: clientName, link: !userStore.isFacilityUser ? `/clients/${clientId}` : undefined },
          ]}
          currentPageName="Facilities"
        />
      }
      isLoading={isLoading}
    >
      <FacilityList clientId={clientId} showSearchInput />
    </ListPage>
  );
});
