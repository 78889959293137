import { User } from '.';
import { Nullable } from '../types';

export type TSalesCommissionId = number;

export interface SalesCommission {
  id: TSalesCommissionId;
  user: User;
  start_reporting_quarter: string;
  end_reporting_quarter: Nullable<string>;
  percent_rate: string;
  comments: Nullable<string>;
}
