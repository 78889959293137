import { makeObservable, observable } from 'mobx';
import ClientStore from '/src/context/stores/clientStore';
import DashboardStore from '/src/context/stores/dashboardStore';
import EquipmentStore from '/src/context/stores/equipmentStore';
import EquipmentUsageStore from '/src/context/stores/equipmentUsageStore';
import FacilityStore from '/src/context/stores/facilityStore';
import GlobalStore from '/src/context/stores/globalStore';
import ModelAuditLogStore from '/src/context/stores/modelAuditLogStore';
import SearchStore from '/src/context/stores/searchStore';
import UserStore from '/src/context/stores/userStore';

export default class RootStore {
  clientStore: ClientStore;
  dashboardStore: DashboardStore;
  equipmentStore: EquipmentStore;
  equipmentUsageStore: EquipmentUsageStore;
  facilityStore: FacilityStore;
  userStore: UserStore;
  searchStore: SearchStore;
  globalStore: GlobalStore;
  modelAuditLogStore: ModelAuditLogStore;

  constructor() {
    this.dashboardStore = new DashboardStore(this);
    this.equipmentStore = new EquipmentStore(this);
    this.equipmentUsageStore = new EquipmentUsageStore(this);
    this.facilityStore = new FacilityStore(this);
    this.userStore = new UserStore(this);
    this.searchStore = new SearchStore(this);
    this.clientStore = new ClientStore(this);
    this.globalStore = new GlobalStore(this);
    this.modelAuditLogStore = new ModelAuditLogStore(this);

    makeObservable(this, {
      clientStore: observable,
      dashboardStore: observable,
      equipmentStore: observable,
      equipmentUsageStore: observable,
      facilityStore: observable,
      userStore: observable,
      searchStore: observable,
      globalStore: observable,
      modelAuditLogStore: observable,
    });
  }
}
