import type { CancelablePromise } from '/src/api';
import { OpenAPI, request as __request } from '/src/api';
import { Region } from '/src/lib/models';

export class RegionService {
  /**
   * List Regions
   * @returns Region[] Region[]
   * @throws ApiError
   */
  public static listRegions(): CancelablePromise<Region[]> {
    return __request(OpenAPI, { method: 'GET', url: '/regions' });
  }
}
