import {
  CIXDStandard,
  Client,
  EMCCommission,
  Equipment,
  EquipmentTypeEER,
  FinalSalePrice,
  FuelPathwayCIScore,
  OwnershipType,
  ProgramRecCost,
} from '.';
import { DateString } from '../types';

export type TProjectedValueId = number;

export interface ProjectedValue {
  id: TProjectedValueId;
  client: Client;
  equipment: Equipment;
  reporting_quarter: DateString;
  fuel_pathway_ci_score: FuelPathwayCIScore;
  equipment_type_eer: EquipmentTypeEER;
  cixd_standard: CIXDStandard;
  program_rec_cost: ProgramRecCost;
  final_sale_price: FinalSalePrice;
  emc_commission: EMCCommission;
  ownership_type?: OwnershipType;
  is_metered: boolean;
  battery_capacity_rating_ah?: number;
  voltage?: number;
  charge_cycles_per_shift?: number;
  work_days_per_quarter?: number;
  shifts_per_day?: number;
  percent_charger_efficiency_rating?: number;
  percent_charge_return_factor?: number;
  percent_depth_of_discharge?: number;
  kwh_per_quarter_for_other?: number;
  is_book_and_claim_applied: boolean;
}
