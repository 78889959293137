import { Button } from 'grommet';
import { useEffect, useMemo, useState } from 'react';
import { Box, Input, Select, Text } from '/src/components';
import { RoleTypes, TUserId, User } from '/src/lib/models';

export const ContactsSelect: React.FC<ContactsSelectProps> = (props) => {
  const { id, contacts = [], isUpdatePage, isCorporate, isRemittance, isFacility } = props;

  const [selectedContactId, setSelectedContactId] = useState<TUserId>();

  // const contacts = useMemo(() => (users ?? []).filter((user) => user.role.id === RoleTypes.ClientAdmin), [users]);
  const entityName = useMemo(
    () => (isCorporate || isRemittance ? 'Client' : isFacility ? 'Facility' : undefined),
    [isCorporate, isRemittance, isFacility]
  );
  const contactTypeName = useMemo(
    () => (isCorporate ? 'Corporate' : isRemittance ? 'Remittance' : isFacility ? 'Facility' : undefined),
    [isCorporate, isRemittance]
  );
  const selectedContact = useMemo(
    () => contacts.find((contact) => contact.id === selectedContactId) ?? contacts[0],
    [contacts, selectedContactId]
  );

  useEffect(() => {
    if (!!contacts?.length && !selectedContactId) {
      setSelectedContactId(contacts[0]?.id);
    }
  }, [contacts, selectedContactId]);

  return (
    <Box gap="1rem" fill="horizontal">
      {!isUpdatePage && (
        <Box
          id={id}
          pad="1rem"
          gap="0.5rem"
          background="light-6"
          border={{ size: 'small', color: 'light-5' }}
          borderRadius="6px"
          fontFamily="Lato, sans-serif"
        >
          <Text size="large">Points of Contact</Text>
          <Text size="medium">All Admin users for this {entityName} will be considered points of contact.</Text>
        </Box>
      )}
      {isUpdatePage && (
        <Box id={id} gap="1rem">
          <Text color="dark-1" size="large" weight={300}>
            {contactTypeName} Contacts
          </Text>
          <Select
            name="contact_name"
            label="Contact Name"
            placeholder="Choose..."
            value={selectedContactId}
            setValue={(value) => setSelectedContactId(value)}
            options={contacts.map((user) => ({ label: user.name, value: user.id }))}
            hideOptionalText
          />
          <Box height="8rem">
            {selectedContact && (
              <Box gap="1rem">
                <Button plain>
                  <Input
                    name="contact_email"
                    label="Contact Email"
                    value={selectedContact.email}
                    placeholder=""
                    setValue={() => {}}
                    hideOptionalText
                    disabled
                  />
                </Button>
                <Input
                  name="contact_phone"
                  label="Contact Phone"
                  value={selectedContact.phone ?? ''}
                  placeholder=""
                  setValue={() => {}}
                  hideOptionalText
                  flex="grow"
                  disabled
                />
              </Box>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export type ContactsSelectProps = {
  id?: string;
  contacts?: User[];
  isUpdatePage?: boolean;
  isClient?: boolean;
  isCorporate?: boolean;
  isRemittance?: boolean;
  isFacility?: boolean;
};
