import { BoxProps as GrommetBoxProps, Card as GrommetCard } from 'grommet';
import React, { PropsWithChildren } from 'react';

export const Card: React.FC<CardProps> = (props) => {
  const { children, borderRadius, fontFamily, style, ...cardProps } = props;

  const customStyles: Record<string, string> = {};
  if (borderRadius) customStyles.borderRadius = borderRadius;
  if (fontFamily) customStyles.fontFamily = fontFamily;

  return (
    <GrommetCard style={{ ...customStyles, ...style }} {...cardProps}>
      {children}
    </GrommetCard>
  );
};

export type CardProps = PropsWithChildren &
  GrommetBoxProps & {
    id?: string;
    borderRadius?: string;
    fontFamily?: string;
    style?: React.CSSProperties;
  };
