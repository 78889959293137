import dayjs from 'dayjs';
import { Button, DateInput, Keyboard, MaskedInput, Stack, TextArea, TextInput, TextInputProps } from 'grommet';
import { Calendar, Hide, View } from 'grommet-icons';
import React, { useMemo, useState } from 'react';
import { Box, BoxProps, Line, Text } from '/src/components';
import { pxToRem } from '/src/utils';

export const Input: React.FC<InputProps> = (props) => {
  const {
    label,
    value = Number.isNaN(props.value) ? '' : props.value,
    setValue,
    id,
    name,
    error,
    placeholder,
    type,
    required,
    minLength,
    maxLength,
    rows,
    fuseAdminOnly,
    hideOptionalText,
    hideRequiredMarker,
    centerLabel = true,
    disabled,
    disabledBackground,
    textArea,
    date,
    password,
    percent,
    autoComplete,
    componentType,
    onSubmit,
    ...boxProps
  } = props;

  const [showPassword, setShowPassword] = useState(false);
  const [isButtonFocused, setIsButtonFocused] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const isActiveLabel = useMemo(
    () =>
      (value !== undefined && value !== null && value !== '') || isFocused || !centerLabel || placeholder || disabled,
    [value, isFocused, centerLabel, placeholder, disabled]
  );

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value) {
      if (minLength) {
        if (typeof e.target.value === 'number' && (e.target.value as number).toString().length < minLength) return;
        else if (typeof e.target.value === 'string' && (e.target.value as string).length < minLength) return;
      }
      if (maxLength) {
        if (typeof e.target.value === 'number' && (e.target.value as number).toString().length > maxLength) return;
        else if (typeof e.target.value === 'string' && (e.target.value as string).length > maxLength) return;
      }
    }
    setValue(e.target.value);
  };

  const renderInput = () => (
    <TextInput
      id={id}
      name={name || id}
      placeholder={placeholder}
      minLength={minLength}
      maxLength={maxLength}
      type={type}
      value={value}
      onChange={onChange}
      required={required}
      disabled={disabled}
      autoComplete={autoComplete}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      plain
      style={{
        fontFamily: 'Lato, sans-serif',
        height: pxToRem(58),
        paddingTop: pxToRem(26),
        paddingBottom: pxToRem(10),
      }}
    />
  );

  const renderPercentInput = () => (
    <TextInput
      id={id}
      name={name || id}
      placeholder={placeholder}
      minLength={minLength}
      maxLength={maxLength}
      icon={
        <Text size="20px" color="dark-1" weight={700} style={{ fontFamily: 'Lato, sans-serif' }}>
          %
        </Text>
      }
      reverse
      type="number"
      className="percent-input"
      value={value}
      onChange={(e) => setValue(e.target.value)}
      required={required}
      disabled={disabled}
      autoComplete={autoComplete}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      plain
      style={{
        fontFamily: 'Lato, sans-serif',
        height: pxToRem(58),
        paddingTop: pxToRem(26),
        paddingBottom: pxToRem(10),
      }}
    />
  );

  const renderDateInput = () => (
    <Box direction="row">
      <MaskedInput
        id={id}
        name={name || id}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        mask={
          isActiveLabel
            ? [
                {
                  length: 4,
                  regexp: /^[1-2]$|^19$|^20$|^19[0-9]$|^20[0-9]$|^19[0-9][0-9]$|^20[0-9][0-9]$/,
                  placeholder: 'yyyy',
                },
                { fixed: '-' },
                {
                  length: [1, 2],
                  regexp: /^1[0,1-2]$|^0?[1-9]$|^0$/,
                  placeholder: 'mm',
                },
                { fixed: '-' },
                {
                  length: [1, 2],
                  regexp: /^[1-2][0-9]$|^3[0-1]$|^0?[1-9]$|^0$/,
                  placeholder: 'dd',
                },
              ]
            : undefined
        }
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        reverse
        plain
        style={{
          fontFamily: 'Lato, sans-serif',
          height: pxToRem(58),
          paddingTop: pxToRem(26),
          paddingBottom: pxToRem(10),
        }}
        required={required}
        disabled={disabled}
      />
      {!disabled && (
        <Box alignSelf="center" margin={{ right: '18px' }}>
          <DateInput
            id={id}
            name={name || id}
            icon={<Calendar size="20px" color={isButtonFocused ? 'accent-1' : 'text'} />}
            value={typeof value === 'number' ? value?.toString() : value}
            onChange={({ value }) => setValue(dayjs(typeof value === 'string' ? value : value[0]).format('YYYY-MM-DD'))}
            calendarProps={{
              style: { fontFamily: 'Lato, sans-serif', borderRadius: '6px' },
              margin: { bottom: '0.5rem' },
              fill: true,
            }}
            disabled={disabled}
            onFocus={() => setIsButtonFocused(true)}
            onBlur={() => setIsButtonFocused(false)}
          />
        </Box>
      )}
    </Box>
  );

  const renderTextArea = () => (
    <TextArea
      id={id}
      name={name || id}
      placeholder={placeholder}
      minLength={minLength}
      maxLength={maxLength}
      rows={rows}
      value={typeof value === 'number' ? value?.toString() : value}
      onChange={(e) => setValue(e.target.value)}
      required={required}
      disabled={disabled}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      resize={false}
      fill
      plain
      style={{
        fontFamily: 'Lato, sans-serif',
        paddingTop: pxToRem(26),
        paddingBottom: pxToRem(10),
      }}
    />
  );

  const renderPasswordInput = () => (
    <TextInput
      id={id}
      name={name || id}
      focusIndicator={false}
      placeholder={placeholder}
      minLength={minLength || 10}
      maxLength={maxLength}
      type={showPassword ? 'text' : 'password'}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      required={required}
      reverse
      disabled={disabled}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      plain
      style={{
        fontFamily: 'Lato, sans-serif',
        height: pxToRem(58),
        paddingTop: pxToRem(26),
        paddingBottom: pxToRem(10),
      }}
    />
  );

  const renderInputComponent = () => {
    switch (componentType) {
      case 'date':
        return renderDateInput();
      case 'percent':
        return renderPercentInput();
      case 'percentage':
        return renderPercentInput();
      case 'password':
        return renderPasswordInput();
      case 'textArea':
        return renderTextArea();
      default:
        return renderInput();
    }
  };

  const borderColor = error ? 'red' : isFocused ? 'accent-1' : 'light-5';
  const buttonBorderColor = isButtonFocused ? 'accent-1' : 'transparent';

  return (
    <Box flex={componentType === 'textArea' ? 'grow' : undefined} {...boxProps}>
      <Box
        border={disabled ? undefined : { color: borderColor, size: 'small' }}
        background={disabled ? disabledBackground || 'light-6' : undefined}
        round="6px"
        height={pxToRem(58)}
        flex={componentType === 'textArea' ? 'grow' : undefined}
      >
        <Box round="6px" direction="row" fill="vertical">
          <Stack anchor={isActiveLabel ? 'top-left' : 'left'} guidingChild="last" fill="vertical">
            <Box pad={{ top: isActiveLabel ? 'xsmall' : undefined, left: 'small' }}>
              <Text size={isActiveLabel ? 'small' : 'medium'} color={isActiveLabel ? 'accent-1' : undefined}>
                {label}
                {required && !hideRequiredMarker && '*'}
              </Text>
            </Box>
            <Keyboard onEnter={onSubmit}>{renderInputComponent()}</Keyboard>
          </Stack>
          {componentType === 'password' && (
            <Button
              icon={
                showPassword ? (
                  <View size="medium" color={isButtonFocused ? 'brand' : undefined} />
                ) : (
                  <Hide size="medium" color={isButtonFocused ? 'brand' : undefined} />
                )
              }
              onClick={() => setShowPassword(!showPassword)}
              onFocus={() => setIsButtonFocused(true)}
              onBlur={() => setIsButtonFocused(false)}
              onMouseOver={() => setIsButtonFocused(true)}
              onMouseOut={() => setIsButtonFocused(false)}
            />
          )}
        </Box>
      </Box>
      {(error || fuseAdminOnly) && (
        <Box row gap="small" margin={{ top: '0.5rem', left: '0.75rem' }}>
          {error && (
            <Text color="red" size="0.75rem">
              {error}
            </Text>
          )}
          {error && fuseAdminOnly && <Line direction="vertical" margin="0" />}
          {fuseAdminOnly && (
            <Text color="dark-1" size="0.75rem">
              FuSE ADMINS ONLY
            </Text>
          )}
        </Box>
      )}
    </Box>
  );
};

export type InputProps = BoxProps & {
  label: string;
  value: string | number | undefined;
  setValue: (value: any) => void;
  id?: string;
  name?: string;
  error?: string;
  type?: JSX.IntrinsicElements['input']['type'];
  placeholder?: string;
  required?: boolean;
  minLength?: number;
  maxLength?: number;
  rows?: number;
  fuseAdminOnly?: boolean;
  hideOptionalText?: boolean;
  hideRequiredMarker?: boolean;
  centerLabel?: boolean;
  disabled?: boolean;
  disabledBackground?: BoxProps['background'];
  componentType?: 'textArea' | 'date' | 'password' | 'percent' | 'percentage';
  autoComplete?: TextInputProps['autoComplete'];
  onSubmit?: (event: React.KeyboardEvent<HTMLElement>) => void;
};
