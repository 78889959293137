import { Program, TProgramId, TRegionId } from '/src/lib/models';

/**
 * List Programs
 */
export type TListProgramsRequest = {
  region_id?: TRegionId;
};

export type TListProgramsResponse = Program[];
