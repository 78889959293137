/**
 * via SVG Repo, www.svgrepo.com
 */
import { IconProps } from 'grommet-icons';
import React from 'react';

export const ForkliftIcon: React.FC<IconProps> = ({ size = '24', color = '#EAAD47' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={size}
    width={size}
    viewBox="0 0 512 512"
    enable-background="new 0 0 512 512"
  >
    <path
      fill={color}
      d="m496,400h-112v-352c0-8.836-7.164-16-16-16s-16,7.164-16,16v256h-36.465l-45.769-137.305c-10.906-32.71-41.406-54.695-75.89-54.695h-81.875c-8.836,0-16,7.164-16,16v144h-79.999c-8.836,0-16,7.164-16,16v128c0,8.836 7.165,16 16,16h16c0,26.469 21.531,48 48,48 26.469,0 48-21.531 48-48h66.272c7.156,27.523 31.995,48 61.727,48 35.289,0 64-28.711 64-64v-16h32v16c0,8.836 7.165,16 16,16h128c8.836,0 16-7.164 16-16 0-8.836-7.164-16-16-16zm-256.594-223.179l42.394,127.179h-52.948l-37.781-25.184c-4.004-34.429-29.072-62.37-63.072-69.198v-65.618h65.876c20.687,0 38.992,13.188 45.531,32.821zm-111.407,66.129c14,4.832 24.219,15.496 29.052,29.051h-29.052v-29.051zm-47.999,205.05c-8.82,0-16-7.18-16-16 0-8.82 7.18-16 16-16s16,7.18 16,16c0,8.821-7.18,16-16,16zm35.515-48c-8.792-9.75-21.385-16-35.516-16-14.131,0-26.724,6.25-35.516,16h-12.484v-96h139.157l43.977,29.313c2.633,1.75 5.719,2.688 8.875,2.688h63.991v24.902c-9-5.512-20.292-8.902-32-8.902-29.732,0-54.571,20.477-61.727,48h-78.757zm140.485,47.999c-17.648,0-32-14.352-32-32 0-17.648 14.352-32 32-32 17.648,0 32,14.352 32,32 0,17.649-14.352,32-32,32zm63.999-80v-32h32v32h-32z"
    />
  </svg>
);
