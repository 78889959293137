/**
 * via https://mui.com/material-ui/material-icons/?query=electrical&selected=ElectricalServices
 */
import { IconProps } from 'grommet-icons';
import React from 'react';

export const EquipmentUsageIcon: React.FC<IconProps> = ({ size = '24', color = '#EAAD47' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    enableBackground="new 0 0 24 24"
    height={size}
    viewBox="0 0 24 24"
    width={size}
  >
    <g>
      <rect fill="none" height={size} width={size} />
    </g>
    <g>
      <path
        fill={color}
        d="M14.69,2.21L4.33,11.49c-0.64,0.58-0.28,1.65,0.58,1.73L13,14l-4.85,6.76c-0.22,0.31-0.19,0.74,0.08,1.01h0 c0.3,0.3,0.77,0.31,1.08,0.02l10.36-9.28c0.64-0.58,0.28-1.65-0.58-1.73L11,10l4.85-6.76c0.22-0.31,0.19-0.74-0.08-1.01l0,0 C15.47,1.93,15,1.92,14.69,2.21z"
      />
    </g>
  </svg>
);
