import { BoxProps as GrommetBoxProps, CardHeader as GrommetCardHeader } from 'grommet';
import React, { PropsWithChildren } from 'react';
import { Box, Text } from '/src/components';

export const CardHeader: React.FC<CardHeaderProps> = ({ children, ...props }) => {
  const { title, icon, ...boxProps } = props;

  return (
    <GrommetCardHeader {...boxProps}>
      {icon && (
        <Box height="xxlarge" justify="center">
          {icon}
        </Box>
      )}
      {title && (
        <Box height="xxlarge" justify="center">
          <Text size="xlarge">{title}</Text>
        </Box>
      )}
      {children && title && icon && <Box flex="grow" />}
      {children}
    </GrommetCardHeader>
  );
};

export type CardHeaderProps = PropsWithChildren &
  GrommetBoxProps & {
    title?: string;
    icon?: React.ReactNode;
    style?: React.CSSProperties;
  };
