import { TListResponse } from '.';
import { Facility, FacilityAddress, TClientId, TFacilityId, TProgramId, TRegionId, TUtilityId } from '/src/lib/models';
import { DateString, Nullable, TResponseMetadata } from '/src/lib/types';

/**
 * List Facilities
 */
export type TListFacilitiesRequest = {
  client_id: TClientId;
  page?: number;
  limit?: number;
  is_active?: boolean;
  name?: string;
  program_id?: TProgramId;
  utility_id?: TUtilityId;
  address_line1?: string;
  address_city?: string;
  address_region_id?: string;
  address_postal_code?: string;
};

export type TListFacilitiesResponse = TListResponse<Facility[]>;

/**
 * List Facility Addresses
 */
export type TListFacilityAddressesResponse = FacilityAddress[];

/**
 * List All Facilities
 */
export type TListAllFacilitiesRequest = {
  page?: number;
  limit?: number;
  is_active?: boolean;
  name?: string;
  program_id?: TProgramId;
  utility_id?: TUtilityId;
  address_line1?: string;
  address_city?: string;
  address_region_id?: string;
  address_post_code?: string;
};

export type TListAllFacilitiesResponse = TListResponse<Facility[]>;

/**
 * Get Facility
 */
export type TGetFacilityRequest = {
  id: TFacilityId;
};

export type TGetFacilityResponse = Facility;

/**
 * Create Facility
 */
export type TCreateFacilityRequest = {
  client_id: TClientId;
  name: Nullable<string>;
  program_id: Nullable<TProgramId>;
  utility_id: Nullable<TUtilityId>;
  first_active_reporting_quarter: DateString;
  address_line1: string;
  address_line2: Nullable<string>;
  address_city: string;
  address_region_id: TRegionId;
  address_post_code: string;
};

export type TCreateFacilityResponse = Facility;

/**
 * Update Facility
 */
export type TUpdateFacilityRequest = {
  id: TFacilityId;
  name: Nullable<string>;
  utility_id: Nullable<TUtilityId>;
  address_line1: string;
  address_line2: Nullable<string>;
  address_city: string;
  address_post_code: string;
  last_active_reporting_quarter: Nullable<DateString>;
};

export type TUpdateFacilityResponse = Facility;

/**
 * Retire Facility
 *
 * last_active_reporting_quarter
 * - string in YYYY-MM-01 format
 * - If the facility’s program uses Quarterly reporting, then MM must equal 01, 04, 07, or 10
 * - If the facility’s program uses Yearly reporting, then MM must equal 10
 */
export type TRetireFacilityRequest = {
  id: TFacilityId;
  last_active_reporting_quarter: DateString;
};
