import { TListResponse } from '.';
import {
  ModelAuditLog,
  ModelAuditLogEventType,
  ModelAuditLogState,
  ModelName,
  TModelAuditLogId,
  TModelAuditLogStateId,
  TModelId,
  TUserId,
} from '/src/lib/models';
import { AuditorType } from '/src/lib/types';

/**
 * List Model Audit Logs
 */
export type TListModelAuditLogsRequest = {
  page?: number;
  limit?: number;
  'model_name[]'?: ModelName;
  model_id?: TModelId;
  action_taken_by?: AuditorType;
  user_id?: TUserId;
  model_audit_log_state_id?: TModelAuditLogStateId;
};

export type TListModelAuditLogsResponse = TListResponse<ModelAuditLog[]>;

/**
 * Get Model Audit Log
 */
export type TGetModelAuditLogRequest = {
  id: TModelId;
};

export type TGetModelAuditLogResponse = ModelAuditLog;

/**
 * List Model Audit Log Event Types
 */
export type TListModelAuditLogEventTypesRequest = {};

export type TListModelAuditLogEventTypesResponse = TListResponse<ModelAuditLogEventType[]>;

/**
 * List Model Audit Log States
 */
export type TListModelAuditLogsStatesRequest = {};

export type TListModelAuditLogsStatesResponse = ModelAuditLogState[];

/**
 * Update Model Audit Log State
 */
export type TUpdateModelAuditLogStateRequest = {
  model_audit_log_id: TModelAuditLogId;
  model_audit_log_state_id: TModelAuditLogStateId;
};

export type TUpdateModelAuditLogStateResponse = unknown;
