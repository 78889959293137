import { EquipmentCategory, TProgramId } from '/src/lib/models';

/**
 * List Equipment Categories
 */
export type TListEquipmentCategoriesRequest = {
  program_id?: TProgramId;
};

export type TListEquipmentCategoriesResponse = EquipmentCategory[];
