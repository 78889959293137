import packageJson from '/package.json';
import type { ApiRequestOptions } from '/src/api/core/ApiRequestOptions';
import { config } from '/src/config';
import { TCredentialsType, TSessionToken } from '/src/lib/types';

type Resolver<T> = (options: ApiRequestOptions) => Promise<T>;
type Headers = Record<string, string>;

export type OpenAPIConfig = {
  BASE: string;
  VERSION: string;
  USE_CREDENTIALS: boolean;
  CREDENTIALS: TCredentialsType;
  TOKEN?: TSessionToken | string | Resolver<string>;
  USERNAME?: string | Resolver<string>;
  PASSWORD?: string | Resolver<string>;
  HEADERS?: Headers | Resolver<Headers>;
  ENCODE_PATH?: (path: string) => string;
};

export const OpenAPI: OpenAPIConfig = {
  BASE: `/${config.api.version}`,
  VERSION: packageJson.version,
  USE_CREDENTIALS: config.api.useCredentials,
  CREDENTIALS: config.api.credentialsType,
  TOKEN: undefined,
  USERNAME: undefined,
  PASSWORD: undefined,
  HEADERS: undefined,
  ENCODE_PATH: undefined,
};
