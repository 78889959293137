import { ProjectedValue, ReferralCommission } from '.';
import { DatabaseEntity } from './base';

export type TProjectedValueReferralCommissionId = number;

export interface ProjectedValueReferralCommission {
  id: TProjectedValueReferralCommissionId;
  projected_value: ProjectedValue;
  referral_commission: ReferralCommission;
}
