import dayjs from 'dayjs';
import { makeObservable, observable, runInAction } from 'mobx';
import { NavigateFunction } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  ApiError,
  BankAccountTypeService,
  FSERegistrationStatusService,
  FuelPathwayService,
  RemittanceMethodService,
  ReportingPeriodTypeService,
} from '/src/api';
import { RegionService } from '/src/api/services/RegionService';
import BaseStore from '/src/context/stores/baseStore';
import RootStore from '/src/context/stores/rootStore';
import { errorMessages } from '/src/lib/errors';
import {
  BankAccountType,
  FSERegistrationStatus,
  FuelPathway,
  Region,
  RemittanceMethod,
  ReportingPeriodType,
  Role,
  TRegionId,
  roleList,
} from '/src/lib/models';
import { ToastMessage, toastMessages } from '/src/lib/toast';
import { DateString, SelectOption, SelectOptions } from '/src/lib/types';
import { getDateStringFromQuarter, getDateStringFromYear, getQuarterFromDate } from '/src/utils';

export default class GlobalStore extends BaseStore {
  isLoading = true;
  bankAccountTypes: BankAccountType[] = [];
  remittanceMethods: RemittanceMethod[] = [];
  regions: Region[] = [];
  regionOptions?: Region[];
  roles: Role[] = [];
  reportingQuarterOptions: SelectOption<string>[] = [];
  reportingYearOptions: SelectOption<string>[] = [];
  reportingPeriodTypes: ReportingPeriodType[] = [];
  fuelPathways?: FuelPathway[];
  fseRegistrationStatuses?: FSERegistrationStatus[];
  previousPath?: string;
  _currentQuarter?: string;
  _previousQuarter?: string;

  // Flags
  didAnimateHeader = false;
  didAnimateDashboard = false;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      // Observables
      isLoading: observable,
      bankAccountTypes: observable,
      remittanceMethods: observable,
      regions: observable,
      regionOptions: observable,
      roles: observable,
      reportingQuarterOptions: observable,
      reportingYearOptions: observable,
      reportingPeriodTypes: observable,
      fuelPathways: observable,
      fseRegistrationStatuses: observable,
      previousPath: observable,
      didAnimateHeader: observable,
      didAnimateDashboard: observable,
    });

    this.fetchAll();
  }

  fetchAll = async () => {
    runInAction(async () => {
      this.isLoading = true;

      await Promise.all([
        this.fetchRoles(),
        this.fetchPeriods(),
        this.fetchReportingPeriodTypes(),
        this.fetchBankAccountTypes(),
        this.fetchRemittanceMethods(),
        this.fetchRegions(),
      ]);

      this.isLoading = false;
    });
  };

  get currentQuarter() {
    const quarter = getQuarterFromDate(new Date());
    if (!this._currentQuarter) {
      this._currentQuarter = quarter;
    }
    return quarter;
  }

  get previousQuarter() {
    const quarter = getQuarterFromDate(
      dayjs(getDateStringFromQuarter(this.currentQuarter)).subtract(3, 'months').toDate()
    );
    if (!this._previousQuarter) {
      this._previousQuarter = quarter;
    }
    return quarter;
  }

  setPreviousPath = (previousPath: string) => {
    runInAction(() => {
      this.previousPath = previousPath;
    });
  };

  fetchRoles = async () => {
    runInAction(() => {
      this.roles = roleList;
    });
  };

  fetchBankAccountTypes = async () => {
    if (!this.rootStore.userStore.user) return;

    try {
      const data = await BankAccountTypeService.listBankAccountTypes();

      runInAction(() => {
        this.bankAccountTypes = data;
      });
    } catch (err) {
      this.handleApiError(err as ApiError, toastMessages.generic.error);
    }
  };

  fetchPeriods = () => {
    if (!this.rootStore.userStore.user) return;

    const firstDate = dayjs().subtract(12, 'months');
    const lastDate = dayjs();

    const quarterOptions: SelectOptions<DateString> = [];
    const yearOptions: SelectOptions<DateString> = [];

    let dateCursor = firstDate;

    while (dateCursor.isBefore(lastDate) || dateCursor.isSame(lastDate)) {
      const quarter = getQuarterFromDate(dateCursor.toDate());
      const quarterDateString = getDateStringFromQuarter(quarter);
      quarterOptions.push({
        label: quarter,
        value: quarterDateString,
      });

      const year = dateCursor.year().toString();
      const yearDateString = getDateStringFromYear(year);
      if (!yearOptions.find((y) => y.value === yearDateString)) {
        yearOptions.push({
          label: year,
          value: yearDateString,
        });
      }

      dateCursor = dateCursor.add(3, 'months');
    }

    runInAction(() => {
      this.reportingQuarterOptions = quarterOptions.reverse();
      this.reportingYearOptions = yearOptions.reverse();
    });
  };

  fetchReportingPeriodTypes = async () => {
    if (!this.rootStore.userStore.user) return;

    try {
      const data = await ReportingPeriodTypeService.list();

      runInAction(() => {
        this.reportingPeriodTypes = data;
      });
    } catch (err) {
      this.handleApiError(err as ApiError, toastMessages.generic.error);
    }
  };

  fetchRemittanceMethods = async () => {
    if (!this.rootStore.userStore.user) return;

    try {
      const data = await RemittanceMethodService.listRemittanceMethods();

      runInAction(() => {
        this.remittanceMethods = data;
      });
    } catch (err) {
      this.handleApiError(err as ApiError, toastMessages.generic.error);
    }
  };

  fetchRegions = async () => {
    if (!this.rootStore.userStore.user) return;

    try {
      const data = await RegionService.listRegions();

      runInAction(() => {
        this.regions = data
          .filter((r) => !!r.short_code && !!r.country_id)
          .sort((a, b) =>
            (a.short_code ?? '') > (b.short_code ?? '') ? 1 : (a.short_code ?? 0) < (b.short_code ?? 0) ? -1 : 0
          )
          .sort((a, b) => (a.country_id ?? 0) - (b.country_id ?? 0));
      });
    } catch (err) {
      this.handleApiError(err as ApiError, toastMessages.generic.error);
    }
  };

  fetchFuelPathways = async () => {
    if (!this.rootStore.userStore.user) return;

    try {
      const data = await FuelPathwayService.listFuelPathways();

      runInAction(() => {
        this.fuelPathways = data;
      });
    } catch (err) {
      this.handleApiError(err as ApiError, toastMessages.generic.error);
    }
  };

  fetchFSERegistrationStatuses = async () => {
    if (!this.rootStore.userStore.user) return;

    try {
      const data = await FSERegistrationStatusService.listFSERegistrationStatuses();

      runInAction(() => {
        this.fseRegistrationStatuses = data;
      });
    } catch (err) {
      this.handleApiError(err as ApiError, toastMessages.generic.error);
    }
  };

  handleApiError = (error: ApiError, messageFunc?: ToastMessage['error'], navigate?: NavigateFunction) => {
    runInAction(() => {
      if (process.env.NODE_ENV === 'development') {
        console.error(error);
      }
      if (error.body?.message?.toLowerCase() === 'invalid credentials') {
        toast.error(errorMessages.invalidLogin);
      } else if (error.status === 401) {
        if (navigate) navigate('/logout');
        else this.rootStore.userStore.logout();
      } else if (messageFunc) toast.error(messageFunc(error));
    });
  };

  setDidAnimateHeader = (didAnimate: boolean) => {
    runInAction(() => {
      this.didAnimateHeader = didAnimate;
    });
  };

  setDidAnimateDashboard = (didAnimate: boolean) => {
    runInAction(() => {
      this.didAnimateDashboard = didAnimate;
    });
  };

  getRegionById = (regionId: TRegionId) => {
    return this.regions?.find((region) => region.id === regionId);
  };
}
