import React, { PropsWithChildren } from 'react';
import { Link, LinkProps } from '/src/components';

export const ExternalLink: React.FC<ExternalLinkProps> = ({ children, to, ...props }: ExternalLinkProps) => {
  return (
    <Link to={to} target="_blank" referrerPolicy="no-referrer" textDecoration="none" {...props}>
      {children}
    </Link>
  );
};

export type ExternalLinkProps = PropsWithChildren<LinkProps>;
