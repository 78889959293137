import { Card, CardBody, CardHeader, ResponsiveContext } from 'grommet';
import { useContext, useMemo, useRef, useState } from 'react';
import {
  Box,
  BoxProps,
  LoadingSpinner,
  RevenueByEquipmentChart,
  RevenueByProgramChart,
  Tabs,
  Text,
} from '/src/components';
import { DashboardData } from '/src/lib/models';

export const RevenueByChart: React.FC<RevenueByChartProps> = (props) => {
  const { data, height, ...cardProps } = props;

  const screenSize = useContext(ResponsiveContext);
  const isMobile = useMemo(() => screenSize === ('small' || 'xsmall'), [screenSize]);

  const [activeTab, setActiveTab] = useState(0);
  const [focusedTab, setFocusedTab] = useState<string>('');

  const chartTitle = useRef('Revenue Breakdown');
  const cardHeight = useRef('30rem');

  /* Render */
  return (
    <Card height={height} {...cardProps}>
      <CardHeader style={{ borderBottom: 'none' }}>
        {/* <CardHeader align="end" pad={{ horizontal: '1rem' }} height={pxToRem(72.5)}> */}
        <Text size="xlarge">{chartTitle.current}</Text>
        {/* <Tabs
          titles={['Equipment Type', 'Program']}
          activeTab={activeTab}
          focusedTab={focusedTab}
          setActiveTab={setActiveTab}
          setFocusedTab={setFocusedTab}
          flex="shrink"
          pad="none"
        /> */}
      </CardHeader>
      <CardBody pad="none" gap="none">
        {!data && <LoadingSpinner />}
        {data && (
          <>
            <Box
              pad={{ horizontal: '1.5rem' }}
              justify="end"
              border={{ side: 'bottom', color: 'light-5', size: 'small' }}
            >
              <Tabs
                titles={['by Equipment Type', 'by Program']}
                activeTab={activeTab}
                focusedTab={focusedTab}
                setActiveTab={setActiveTab}
                setFocusedTab={setFocusedTab}
                flex="shrink"
                pad="none"
                background="none"
              />
            </Box>
            {activeTab === 0 && <RevenueByEquipmentChart data={data} hideHeader round="none" border={undefined} />}
            {activeTab === 1 && <RevenueByProgramChart data={data} hideHeader round="none" border={undefined} />}
          </>
        )}
      </CardBody>
    </Card>
  );
};

export type RevenueByChartProps = BoxProps & {
  data: DashboardData;
};
