import { Route } from 'react-router-dom';
import { IncentiveStatementDetails, IncentiveStatementSummary } from './incentives';
import { ListStatementsPage } from './list-statements';
import { UseActiveClient, RequireAuth } from '/src/components';

export { ListStatementsPage, IncentiveStatementDetails, IncentiveStatementSummary };

export const statementRoutes: React.ReactElement[] = [
  // Incentive Statement Summary
  <Route
    path="/clients/:client_id/statements/incentive/:incentive_statement_id"
    element={
      <RequireAuth>
        <UseActiveClient>
          <IncentiveStatementSummary />
        </UseActiveClient>
      </RequireAuth>
    }
  />,

  // Incentive Statement Details
  <Route
    path="/clients/:client_id/statements/incentive/:incentive_statement_id/details"
    element={
      <RequireAuth>
        <UseActiveClient>
          <IncentiveStatementDetails />
        </UseActiveClient>
      </RequireAuth>
    }
  />,

  // Incentive Statements
  <Route
    path="/clients/:client_id/statements"
    element={
      <RequireAuth>
        <UseActiveClient>
          <ListStatementsPage />
        </UseActiveClient>
      </RequireAuth>
    }
  />,
];
