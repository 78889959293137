import { EquipmentCategory, TEquipmentCategoryId } from '/src/lib/models';

export type TEquipmentTypeId = number;

export type EquipmentByCategory = {
  category_id: TEquipmentCategoryId;
  category_name: string;
  num_equipment: number;
};

export interface EquipmentType {
  id: TEquipmentTypeId;
  name: string;
  equipment_category_id: TEquipmentCategoryId;
  uses_alternate_exd_displaced_formula: boolean;
  equipment_category?: EquipmentCategory;
}
