import type { CancelablePromise } from '/src/api';
import { OpenAPI, request as __request } from '/src/api';
import { RemittanceMethod } from '/src/lib/models';

export class RemittanceMethodService {
  /**
   * List RemittanceMethods
   * @returns RemittanceMethod[] RemittanceMethod[]
   * @throws ApiError
   */
  public static listRemittanceMethods(): CancelablePromise<RemittanceMethod[]> {
    return __request(OpenAPI, { method: 'GET', url: '/remittanceMethods' });
  }
}
