import { Client, Region } from '/src/lib/models';

export type TAddressId = number;

export interface Address {
  id: TAddressId;
  name?: string;
  client?: Client;
  line1: string;
  line2?: string;
  city: string;
  region: Region;
  region_short_code: string;
  post_code: string;
  latitude?: number;
  longitude?: number;
  is_hq_address: boolean;
  is_remittance_address: boolean;
}
