import { Box, Keyboard, Select } from 'grommet';
import React, { useState } from 'react';
import { EntityStatus } from '/src/lib/types';
import { CircleAlert, StatusGood } from 'grommet-icons';
import { Text } from '/src/components';

export const UserStatusSelect: React.FC<UserStatusSelectProps> = (props) => {
  const { isActive, userCanEdit, setIsActive, required, onSubmit } = props;

  const [isFocused, setIsFocused] = useState(false);

  return (
    <Box border={{ side: 'bottom', size: 'small', color: isFocused ? 'accent-1' : 'transparent' }}>
      <Keyboard onEnter={onSubmit}>
        <Select
          id="user-status-select"
          required={required}
          plain
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          value={isActive ? EntityStatus.Active : EntityStatus.Inactive}
          options={[EntityStatus.Active, EntityStatus.Inactive]}
          style={{ fontSize: '1rem', fontFamily: 'Lato, sans-serif', fontWeight: 400 }}
          disabled={!userCanEdit}
          onChange={({ value: nextStatus }) => setIsActive(nextStatus === EntityStatus.Active ? true : false)}
          valueLabel={
            <Box direction="row">
              <Box alignSelf="center">
                {isActive ? <StatusGood color="green" size="16px" /> : <CircleAlert size="16px" />}
              </Box>
              <Text alignSelf="center" margin={{ left: '0.25rem' }} size="1rem" fontFamily="Lato, sans-serif">
                {isActive ? EntityStatus.Active : EntityStatus.Inactive}
              </Text>
            </Box>
          }
        />
      </Keyboard>
    </Box>
  );
};

export type UserStatusSelectProps = {
  isActive: boolean;
  userCanEdit: boolean;
  setIsActive: (isActive: boolean) => void;
  required?: boolean;
  onSubmit?: (event: React.KeyboardEvent<HTMLElement>) => void;
};
