import { Button, Card, CardBody, ResponsiveContext } from 'grommet';
import { Add, Share } from 'grommet-icons';
import { useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { AddButton, Box, BoxProps, Text } from '/src/components';
import { useGlobalStore, useUserStore } from '/src/context';
import { TClientId } from '/src/lib/models';
import { tooltipMessages } from '/src/lib/tooltips';
import { pxToRem } from '/src/utils';

export const DashboardHeader: React.FC<DashboardHeaderProps> = (props) => {
  /** Props **/
  const { clientName, clientId, ...boxProps } = props;

  /** Stores **/
  const navigate = useNavigate();
  const screenSize = useContext(ResponsiveContext);
  const userStore = useUserStore();
  const globalStore = useGlobalStore();

  /** Memos **/
  const isMobile = useMemo(() => screenSize === ('small' || 'xsmall'), [screenSize]);

  /** Render **/
  return (
    <Card
      height={!userStore.isFacilityUser ? pxToRem(80) : undefined}
      animation={!globalStore.didAnimateDashboard ? { type: 'slideDown', duration: 300, size: 'large' } : undefined}
      onAnimationEnd={() => globalStore.setDidAnimateDashboard(true)}
      {...boxProps}
    >
      <CardBody pad={{ horizontal: '1.25rem', vertical: '1.25rem' }} direction="row" align="start">
        {!userStore.isFacilityUser && (
          <Box direction="row" align="center">
            <Text size="1.75rem" weight={400}>
              {clientName}
            </Text>
            <Button
              icon={<Share size="16px" />}
              onClick={() => navigate(`/clients/${clientId}`)}
              tip={tooltipMessages.companyPageLink}
            />
          </Box>
        )}
        {userStore.isFacilityUser && (
          <Box justify="center" gap="xxsmall">
            <Text size="1.75rem" weight={400}>
              Your Facilities
            </Text>
            <Text size="1.1rem" fontFamily="Lato, sans-serif" weight={300}>
              {clientName}
            </Text>
          </Box>
        )}
      </CardBody>
    </Card>
  );
};

export type DashboardHeaderProps = BoxProps & {
  clientName?: string;
  clientId?: TClientId;
};
