import { Region, TRegionId } from './Region';

export type TUtilityId = number;

export interface Utility {
  id: TUtilityId;
  name: string;
  region: Region;
  region_id: TRegionId;
}
