import { BoxProps, Button } from 'grommet';
import { Filter } from 'grommet-icons';
import React, { useState } from 'react';
import { Box, Card, CardBody, CardHeader, Input, Select, Text } from '/src/components';
import { FilterItem } from '/src/lib/types';
import { toSnakeCase } from '/src/utils';

export const Filters: React.FC<FiltersProps> = (props) => {
  const { isFiltering, onSubmit, onClear, filters, width, ...boxProps } = props;

  const [isApplyFocused, setIsApplyFocused] = useState(false);
  const [isClearFocused, setIsClearFocused] = useState(false);

  // original default width: pxToRem(260)

  return (
    <Box width={width || '20%'}>
      <Card flex="shrink" {...boxProps}>
        <CardHeader title="Filters" icon={<Filter size="24px" color="brand" />} />
        <CardBody pad="1.5rem" gap="medium">
          {filters.map((filter) => (
            <Box key={filter.label} gap="xsmall" {...boxProps}>
              {filter.type === 'input' && (
                <Input
                  id={toSnakeCase(filter.label)}
                  name={filter.label}
                  label={filter.label}
                  setValue={filter.setValue}
                  onSubmit={onSubmit}
                  value={filter.value}
                  fontFamily="Lato, sans-serif"
                  hideOptionalText
                />
              )}
              {(!filter.type || filter.type === 'select') && !!filter.options && (
                <Select
                  id={toSnakeCase(filter.label)}
                  name={filter.label}
                  label={filter.label}
                  placeholder={'Choose...'}
                  options={filter.options}
                  value={filter.value}
                  setValue={filter.setValue}
                  fontFamily="Lato, sans-serif"
                  hideOptionalText
                />
              )}
            </Box>
          ))}
          <Box direction="row" justify="between" gap="xsmall">
            <Box
              id="apply_filters"
              justify="center"
              align="center"
              fill="horizontal"
              background={isApplyFocused ? 'button-hover' : 'accent-1'}
              elevation={isApplyFocused ? 'small' : undefined}
              pad="0.75rem"
              borderRadius="6px"
              onMouseOver={() => setIsApplyFocused(true)}
              onMouseOut={() => setIsApplyFocused(false)}
              onClick={() => onSubmit()}
            >
              <Text color="white" size="1rem" weight={500}>
                {!isFiltering ? 'APPLY' : 'APPLYING...'}
              </Text>
            </Box>

            <Box
              id="clear_filters"
              justify="center"
              align="center"
              fill="horizontal"
              pad="0.75rem"
              background={isClearFocused ? 'light-5' : 'light-4'}
              elevation={isClearFocused ? 'small' : undefined}
              border={{ color: 'light-2', size: '0.05rem' }}
              borderRadius="6px"
              onMouseOver={() => setIsClearFocused(true)}
              onMouseOut={() => setIsClearFocused(false)}
              onClick={() => onClear()}
            >
              <Text size="1rem" weight={500}>
                CLEAR
              </Text>
            </Box>
          </Box>
        </CardBody>
      </Card>
    </Box>
  );
};

export type FiltersProps = BoxProps & {
  isFiltering: boolean;
  onSubmit: () => void;
  onClear: () => void;
  filters: FilterItem[];
  width?: BoxProps['width'];
};
