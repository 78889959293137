import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ApiError, ClientService } from '/src/api';
import { BreadcrumbNav, ListPage, PageHeader, UserList } from '/src/components';
import { useGlobalStore, useUserStore } from '/src/context';
import { TClientId } from '/src/lib/models';
import { toastMessages } from '/src/lib/toast';

export const ListUsersPage = observer(() => {
  /* Context */
  const { user: currentUser, isAdminUser, isInternalUser, isFacilityUser } = useUserStore();
  const { handleApiError } = useGlobalStore();
  const { client_id } = useParams();
  const clientId = useMemo(() => parseInt(client_id ?? ''), [client_id]);

  /* State */
  const [clientName, setClientName] = useState('');

  /* Computed */
  const isLoading = clientId ? !clientName : false;

  /* Memos */
  const addButtonUrl = useMemo(() => (clientId ? `/clients/${clientId}/users/create` : '/users/create'), [clientId]);

  const breadcrumbNav = useMemo(
    () =>
      clientId && clientName ? (
        <BreadcrumbNav
          previousPages={[
            { name: 'Clients', link: isInternalUser ? '/clients' : undefined },
            { name: clientName, link: !isFacilityUser ? `/clients/${clientId}` : undefined },
          ]}
          currentPageName="Users"
        />
      ) : undefined,
    [clientId, clientName, isInternalUser, isFacilityUser]
  );

  /* Methods */
  const fetchPageData = useCallback(
    async (clientId: TClientId) => {
      if (!currentUser) return;

      if (isFacilityUser) {
        setClientName(currentUser.clients.find((client) => client.id === clientId)?.name ?? '');
      } else {
        try {
          const client = await ClientService.get({ id: clientId });
          setClientName(client.name);
        } catch (err) {
          handleApiError(err as ApiError, toastMessages.listUsers.error);
        }
      }
    },
    [currentUser, isFacilityUser]
  );

  /* Effects */
  useEffect(() => {
    if (clientId && !clientName) {
      fetchPageData(clientId);
    }
  }, [clientId, clientName]);

  /* Render */
  return (
    <ListPage
      title="Users"
      breadcrumbNav={breadcrumbNav}
      pageHeader={
        <PageHeader
          title="Users"
          showAddButton={isAdminUser && !isFacilityUser}
          addButtonLabel="ADD USER"
          addButtonUrl={addButtonUrl}
        />
      }
      isLoading={isLoading}
    >
      <UserList showSearchInput showFilters clientId={clientId} />
    </ListPage>
  );
});
