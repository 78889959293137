import { BaselineFuelType, Program } from '/src/lib/models';
import { DateString } from '/src/lib/types';

export type TCIXDStandardId = number;

export interface CIXDStandard {
  id: TCIXDStandardId;
  program: Program;
  baseline_fuel_type: BaselineFuelType;
  reporting_quarter: DateString;
  cixd_standard: number;
}
