import { Program } from '/src/lib/models';
import { DateString } from '/src/lib/types';

export type TProgramRecCostId = number;

export interface ProgramRecCost {
  id: TProgramRecCostId;
  program: Program;
  reporting_quarter: DateString;
  rec_cost: number;
}
