import { Box, Button, DropButton, InfiniteScroll, RadioButton } from 'grommet';
import { CaretDownFill, CaretUpFill } from 'grommet-icons';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { UserService } from '/src/api';
import { Text } from '/src/components';
import { useGlobalStore, useUserStore } from '/src/context';
import { User } from '/src/lib/models';
import { toastMessages } from '/src/lib/toast';
import { TResponseMetadata } from '/src/lib/types';
import theme from '/src/theme';

const themeColors = theme.global?.colors ?? {};

export const UsersDropdown: React.FC<UsersDropdownProps> = observer((props) => {
  const { user: currentUser, isFuseAdmin, selectedUser, selectedUserId, setSelectedUser } = useUserStore();
  const { handleApiError } = useGlobalStore();

  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isLoadingUsers, setIsLoadingUsers] = useState(true);
  const [usersList, setUsersList] = useState<User[] | undefined>();
  const [metadata, setMetadata] = useState<TResponseMetadata | undefined>();
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (isLoadingUsers) {
      if (!usersList) {
        fetchUsersList();
      } else {
        if (!selectedUser && usersList.length) {
          setSelectedUser(usersList[0]);
        }
        setIsLoadingUsers(false);
      }
    }
  }, [usersList, isLoadingUsers, selectedUser]);

  const nextPage = () => {
    if (metadata && currentPage + 1 > metadata.last_page) return;
    fetchUsersList(currentPage + 1);
  };

  const fetchUsersList = (page?: number) => {
    if (!currentUser) return;

    if (isFuseAdmin) {
      UserService.list({ page })
        .then(({ data, meta }) => {
          setUsersList([...(usersList ?? []), ...data]);
          setMetadata(meta);
          setCurrentPage(meta.current_page);
        })
        .catch((err) => handleApiError(err, toastMessages.listUsers.error));
    } else {
      setUsersList([currentUser]);
      setCurrentPage(1);
    }
  };

  const openMenu = () => setMenuIsOpen(true);
  const closeMenu = () => setMenuIsOpen(false);

  const selectUser = (user: User, index: number) => {
    setSelectedUser(user);
    setSelectedIndex(index);
    closeMenu();
  };

  return (
    <DropButton
      label={
        <Box direction="row" justify="center" pad="xsmall" gap="xsmall">
          <Text alignSelf="center" size="1rem" weight={500}>
            {selectedUser?.name}
          </Text>
          <Box justify="center">{menuIsOpen ? <CaretUpFill /> : <CaretDownFill />}</Box>
        </Box>
      }
      open={menuIsOpen}
      onOpen={openMenu}
      onClose={closeMenu}
      plain
      dropProps={{ align: { top: 'bottom', right: 'right' } }}
      dropContent={
        <Box
          border={{ size: '0.05rem', color: 'light-2' }}
          height={(usersList ? usersList.length <= 7 : false) ? '100%' : '241px'}
          overflow={(usersList ? usersList.length <= 7 : false) ? 'default' : 'auto'}
        >
          {!isLoadingUsers && !!usersList && (
            <InfiniteScroll show={selectedIndex} step={20} onMore={nextPage} items={usersList}>
              {(user: User, i: number) => {
                const isSelected = selectedUserId === user.id;
                return (
                  <Button key={`user-list-index-${i}`} hoverIndicator="light-4" onClick={() => selectUser(user, i)}>
                    <Box direction="row" gap="7px" pad={{ vertical: 'xsmall', horizontal: 'small' }}>
                      <RadioButton name={user.name} checked={isSelected} readOnly />
                      <Text size="1rem" color="dark-2" truncate={true} lineHeight="1.40625rem">
                        {user.name}
                      </Text>
                    </Box>
                  </Button>
                );
              }}
            </InfiniteScroll>
          )}
        </Box>
      }
    />
  );
});

export type UsersDropdownProps = {};
