import { action, makeObservable, observable } from 'mobx';
import { DashboardService } from '/src/api';
import BaseStore from '/src/context/stores/baseStore';
import RootStore from '/src/context/stores/rootStore';
import { DashboardData, TClientId } from '/src/lib/models';
import { CountryName } from '/src/lib/types';
import { userHasClient } from '/src/utils';

export default class DashboardStore extends BaseStore {
  dashboardData?: DashboardData;

  defaultCountry = CountryName.USA;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      dashboardData: observable,
      defaultCountry: observable,
      setDashboardData: action,
      clearDashboardData: action,
      getAdminView: action,
      getClientView: action,
    });
  }

  setDashboardData = (dashboardData?: DashboardData) => {
    this.dashboardData = dashboardData;
  };

  clearDashboardData = () => {
    this.dashboardData = undefined;
  };

  getAdminView = async (country = this.defaultCountry) => {
    if (!this.rootStore.userStore.isAdminUser) return;

    const dashboardData = await DashboardService.getAdminView({ country: country.toLowerCase() as CountryName });

    this.setDashboardData({ client_id: 0, ...dashboardData });
  };

  getClientView = async (clientId: TClientId) => {
    if (
      !this.rootStore.userStore.user ||
      (this.rootStore.userStore.isExternalUser && !userHasClient(this.rootStore.userStore.user, clientId))
    )
      return;

    const dashboardData = await DashboardService.getClientView({ client_id: clientId });

    this.setDashboardData({ client_id: clientId, ...dashboardData });
  };
}
