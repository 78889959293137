export const getIsMobile = (screenSize: string) => screenSize === 'small' || screenSize === 'xsmall';
export const getIsTablet = (screenSize: string) => screenSize === 'medium';

/**
 * Takes a string and returns an ID number or NaN
 */
export const parseIdQueryParam = (param?: string) => parseInt(param?.replace(/[^0-9]/g, '') || '');

export const getIsInvalidParams = (params: number[] = []) =>
  params
    .map((param) => Number.isNaN(param))
    .reduce((isAllInvalid, isParamInvalid) => (isParamInvalid ? true : isAllInvalid), false);
