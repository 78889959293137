import { DropButton } from 'grommet';
import { Search } from 'grommet-icons';
import { observer } from 'mobx-react-lite';
import React from 'react';
import {
  Box,
  BoxProps,
  SearchDataTable,
  SearchEntitySelect,
  SearchFieldSelect,
  SearchQueryInput,
} from '/src/components';
import { useSearchStore } from '/src/context';
import { tooltipMessages } from '/src/lib/tooltips';

export const SearchDropdown: React.FC<SearchDropdownProps> = observer((props) => {
  const { ...boxProps } = props;
  const { searchEntity, searchField, reset } = useSearchStore();

  return (
    <Box justify="center" {...boxProps}>
      <Box>
        <DropButton
          plain
          tip={tooltipMessages.adminSearch}
          icon={<Search size="20px" />}
          reverse
          onOpen={() => {
            reset();
          }}
          dropAlign={{ top: 'bottom', right: 'right' }}
          dropContent={
            <Box flex="grow">
              <Box pad="small" gap="small">
                <Box direction="row">
                  <SearchEntitySelect />
                  <SearchFieldSelect />
                </Box>
                {!!searchEntity && !!searchField && <SearchQueryInput />}
              </Box>
              <SearchDataTable showResultsLink />
            </Box>
          }
        />
      </Box>
    </Box>
  );
});

export type SearchDropdownProps = BoxProps & {};
