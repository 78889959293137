import React from 'react';
import {
  IncentiveStatementFacilityValue,
  IncentiveStatementProgramTotal,
  Region,
  TAddressId,
  TBankAccountTypeId,
  TClientId,
  TEquipmentCategoryId,
  TEquipmentTypeId,
  TFSERegistrationStatusId,
  TFacilityId,
  TFuelPathwayId,
  TProgramId,
  TRegionId,
  TRemittanceMethodId,
  TRoleId,
  TUserId,
  TUtilityId,
  User,
} from '/src/lib/models';

/**
 * Aliases
 */
export type Integer = number;
export type IntegerString = string;

export type Float = number;
export type FloatString = string;

export type DateString = string;
export type QuarterString = string;

export type Coordinates = [lat: number, lng: number];

export type Nullable<T> = T | null;

export type TNodeEnv = 'development' | 'production';

/**
 * Enums
 */
export enum Regions {
  // United States
  AL = 'AL',
  AK = 'AK',
  AZ = 'AZ',
  AR = 'AR',
  CA = 'CA',
  CO = 'CO',
  CT = 'CT',
  DE = 'DE',
  FL = 'FL',
  GA = 'GA',
  HI = 'HI',
  ID = 'ID',
  IL = 'IL',
  IN = 'IN',
  IA = 'IA',
  KS = 'KS',
  KY = 'KY',
  LA = 'LA',
  ME = 'ME',
  MD = 'MD',
  MA = 'MA',
  MI = 'MI',
  MN = 'MN',
  MS = 'MS',
  MO = 'MO',
  MT = 'MT',
  NE = 'NE',
  NV = 'NV',
  NH = 'NH',
  NJ = 'NJ',
  NM = 'NM',
  NY = 'NY',
  NC = 'NC',
  ND = 'ND',
  OH = 'OH',
  OK = 'OK',
  OR = 'OR',
  PA = 'PA',
  RI = 'RI',
  SC = 'SC',
  SD = 'SD',
  TN = 'TN',
  TX = 'TX',
  UT = 'UT',
  VT = 'VT',
  VA = 'VA',
  WA = 'WA',
  WV = 'WV',
  WI = 'WI',
  WY = 'WY',
  // Canada
  AB = 'AB',
  BC = 'BC',
  MB = 'MB',
  NB = 'NB',
  NL = 'NL',
  NT = 'NT',
  NS = 'NS',
  NU = 'NU',
  ON = 'ON',
  PE = 'PE',
  QC = 'QC',
  SK = 'SK',
  YT = 'YT',
}

export enum CountryName {
  Canada = 'canada',
  USA = 'usa',
}

export enum CountryId {
  USA = 1,
  Canada = 2,
}

export enum StorageKeys {
  Theme = 'theme',
  CurrentDashboardId = 'currentDashboardId',
  SelectedDashboardData = 'selectedDashboardData',
  SelectedClient = 'selectedClient',
  SelectedClientID = 'selectedClientId',
  ActiveClientId = 'activeClientId',
  SelectedUser = 'selectedUser',
  SelectedUserID = 'selectedUserId',
  UserClients = 'userClients',
  UserClientsMeta = 'userClientsMeta',
  SessionToken = 'sessionToken',
  SessionUser = 'sessionUser',
  SessionExpiration = 'sessionExpiration',
  Session = 'session',
}

export enum LeafletOutputFormats {
  JSON = 'json',
  GeoJSON = 'geojson',
  GeocodeJSON = 'geocodejson',
}

export enum ModelType {
  Clients = 'Clients',
  Facilities = 'Facilities',
  Equipment = 'Equipment',
  Users = 'Users',
}

export enum EntityStatus {
  Active = 'Active',
  Inactive = 'Inactive',
}

export enum MeteredStatus {
  Metered = 'Metered',
  Unmetered = 'Unmetered',
}

export enum QuarterMonths {
  Q1 = '01',
  Q2 = '04',
  Q3 = '07',
  Q4 = '10',
}

export enum ReportingPeriodFinalizeType {
  NotFinalized = 0,
  Finalized = 1,
}

export enum FSERegistrationStatusIds {
  'Pending Client Information' = 1,
  'Pending CARB Review' = 2,
  'Approved' = 3,
  'Not Approved' = 4,
  'Deduct from PoS' = 5,
  'Approved - Paused for Seasonality' = 6,
}

/**
 * Form Fields
 */
export type SelectOption<T> = { label: string; value: T };

export type SelectOptions<T> = SelectOption<T>[];

/**
 * Info Form
 */
export enum InfoFormFieldType {
  Input = 'Input',
  Select = 'Select',
  SelectMultiple = 'SelectMultiple',
  Password = 'Password',
}

export type InfoFormField = {
  label: string;
  fieldType: InfoFormFieldType;
  value?: string | number;
  values?: (string | number)[];
  setValue?: (value: any) => void;
  setValues?: (values: (string | number)[]) => void;
  error?: string;
  placeholder?: string;
  required?: boolean;
  inputType?: string;
  hide?: boolean;
  selectOptions?: Record<string, string | number>[];
  selectComponent?: React.ReactNode;
};

export type InfoFormFieldGroup = {
  fields: InfoFormField[];
  title?: string;
  hide?: boolean;
  columnCount?: number;
  emcAdminOnly?: boolean;
  showLineBefore?: boolean;
  showLineAfter?: boolean;
};

/**
 * Filters
 */
export type FilterItem = {
  label: string;
  value: string | number;
  setValue: (value: any) => void;
  type?: 'input' | 'select';
  disabled?: boolean;
  options?: SelectOption<string | number | undefined>[];
};

/**
 * API
 */
export type TResponseMetadata = {
  total: number;
  per_page: number;
  current_page: number;
  last_page: number;
  first_page: number;
  first_page_url: string;
  last_page_url: string;
  next_page_url: string | null;
  previous_page_url: string | null;
};

export type TCredentialsType = 'include' | 'omit' | 'same-origin';

/**
 * Auth
 */
export type TSession = {
  token: TSessionToken;
  user: User;
};

export type TSessionToken = {
  type: string;
  token: string;
  expires_at: string;
};

/**
 * Libraries
 */
export type TNominatimOSMResponse = {
  place_id: number;
  licence: string;
  osm_type: string;
  osm_id: number;
  lat: string;
  lon: string;
  class: string;
  type: string;
  place_rank: number;
  importance: number;
  addresstype: string;
  name: string;
  display_name: string;
  address: {
    house_number: string;
    road: string;
    city: string;
    county: string;
    state: string;
    'ISO3166-2-lvl4': string;
    postcode: string;
    country: string;
    country_code: string;
  };
  boundingbox: string[];
};

/**
 * Charts & Graphs
 */
export type PieChartDataItem = { name: string; value: number; label?: string };
export type PieChartData = PieChartDataItem[];

export type BarChartDataItem = { name: string; barValue: string | number; lineValues?: (string | number)[] };
export type BarChartData = BarChartDataItem[];

/**
 * Revenue By Equipment Chart
 */
export type RevenueByEquipmentChartDatum = {
  name: string;
  value: string | number;
};

export type RevenueByEquipmentChartData = RevenueByEquipmentChartDatum[];

/**
 * Quarterly Incentive Revenue Chart
 */
export type QuarterlyIncentiveRevenueChartDatum = {
  quarter?: string;
  name: string;
  value: string | number;
  type?: 'bar' | 'line';
};
export type QuarterlyIncentiveRevenueChartData = QuarterlyIncentiveRevenueChartDatum[];

/**
 * Enrollment Trends Chart
 */
export type EnrollmentTrendsChartQuarterDatum = {
  quarter: string;
  value: number;
  num_facilities: number;
};

export type EnrollmentTrendsChartDatum = {
  category: string;
  quarters: EnrollmentTrendsChartQuarterDatum[];
};

export type EnrollmentTrendsChartData = EnrollmentTrendsChartDatum[];

/**
 * Equipment By Facility Chart
 */
export type EquipmentByFacilityChartFacilityDatum = {
  name: string;
  value: number;
};

export type EquipmentByFacilityChartDatum = {
  category: string;
  facilities: EquipmentByFacilityChartFacilityDatum[];
};

export type EquipmentByFacilityChartData = EquipmentByFacilityChartDatum[];

/**
 * Current Facility Locations Chart
 */
export type CurrentFacilityLocationsChartDatum = {
  name: string;
  address_1: string;
  address_2: string;
  coords?: Coordinates;
};

export type CurrentFacilityLocationsChartData = CurrentFacilityLocationsChartDatum[];

/**
 * Form Types
 */
export type FormFieldMetadata = {
  label: string;
  min?: number;
  max?: number;
  minValue?: number;
  maxValue?: number;
  minArrayLength?: number;
  required?: boolean;
  nonZero?: boolean;
};
export type FormMetadata = Record<string, FormFieldMetadata>;

/**
 * Login Data Form
 */
export type LoginDataForm = {
  email: string;
  password: string;
};

/**
 * Sales Commission Data Form
 */
export type SalesCommissionDataForm = {
  user_id: TUserId;
  percent_rate: string;
  start_reporting_quarter: string;
  end_reporting_quarter?: Nullable<string>;
  comments?: Nullable<string>;
};

/**
 * Equipment Data Form
 */
export type EquipmentDataForm = {
  facility_id?: TFacilityId;
  category_name?: string;
  equipment_category_id?: TEquipmentCategoryId;
  equipment_type_id?: TEquipmentTypeId;
  fuel_pathway_id: TFuelPathwayId;
  unit_number?: Nullable<string>;
  serial_number: string;
  manufacturer: string;
  model_number: string;
  model_year: IntegerString;
  first_day_in_service?: Nullable<DateString>;
  first_active_reporting_quarter?: DateString;
  last_active_reporting_quarter?: Nullable<DateString>;
  is_metered: boolean;
  battery_capacity_rating_ah?: FloatString;
  voltage?: IntegerString;
  total_kwh?: number;
  is_book_and_claim_applied: Nullable<boolean>;
  latitude: FloatString;
  longitude: FloatString;
  fse_registration_status_id: TFSERegistrationStatusId;
  fse_id: Nullable<string>;
  fse_ru: Nullable<string>;
  comments: Nullable<string>;
};

/**
 * Facility Data Form
 */
export type FacilityDataForm = {
  name: Nullable<string>;
  address_line1: string;
  address_line2: Nullable<string>;
  address_city: string;
  address_region_id: Nullable<TRegionId>;
  address_region?: Region;
  address_post_code: string;
  first_active_reporting_quarter?: DateString;
  last_active_reporting_quarter?: Nullable<DateString>;
  program_id: Nullable<TProgramId>;
  utility_id: Nullable<TUtilityId>;
};

/**
 * Create Client Form
 */
export type CreateClientForm = {
  name: string;
  website: string;
  business_id: string;
  start_date: string;
  end_date: Nullable<string>;
  first_active_reporting_quarter: DateString;
  remittance_method_id: TRemittanceMethodId;
  bank_name: Nullable<string>;
  bank_account_type_id: Nullable<TBankAccountTypeId>;
  routing_number: Nullable<string>;
  account_number: Nullable<string>;
  jumpstart_amount: Nullable<string>;
  comments?: string;
  hq_address_line1: string;
  hq_address_line2: Nullable<string>;
  hq_address_city: string;
  hq_address_region_id: TRegionId;
  hq_address_post_code: string;
  remittance_address_line1: string;
  remittance_address_line2: Nullable<string>;
  remittance_address_city: string;
  remittance_address_region_id: TRegionId;
  remittance_address_post_code: string;
};

/**
 * Client Data Form
 */
export type ClientDataForm = {
  name: string;
  website: string;
  business_id: string;
  start_date: DateString;
  first_active_reporting_quarter: DateString;
  remittance_method_id: number;
  bank_name: Nullable<string>;
  bank_account_type_id: Nullable<TBankAccountTypeId>;
  routing_number: Nullable<string>;
  account_number: Nullable<string>;
  jumpstart_amount: Nullable<string>;
  comments?: Nullable<string>;
  hq_address_line1: string;
  hq_address_line2: Nullable<string>;
  hq_address_city: string;
  hq_address_region_id: TRegionId;
  hq_address_post_code: string;
  remittance_address_line1: string;
  remittance_address_line2: Nullable<string>;
  remittance_address_city: string;
  remittance_address_region_id: TRegionId;
  remittance_address_post_code: string;
  end_date?: DateString;
  hq_address_id?: TAddressId;
  remittance_address_id?: TAddressId;
  [key: string]: any;
};

/**
 * User Data Form
 */
export type UserDataForm = {
  name: string;
  email: string;
  phone?: string;
  is_active?: boolean;
  is_email_verified: boolean;
  role_id: TRoleId;
  client_id?: TClientId;
  facility_ids: TFacilityId[];
  add_facility_ids: TFacilityId[];
  remove_facility_ids: TFacilityId[];
};

/**
 * Incentive Statements
 */
// export type IncentiveStatementDataItem = IncentiveStatementProgramTotal & IncentiveStatementFacilityValue;
export type IncentiveStatementDataItem = {
  program_name: string;
  program_id: TProgramId;
  facility_name?: string;
  facility_id?: TFacilityId;
  credits: number;
  credit_price: number;
  total_value: number;
  rec_cost: number;
  broker_cost: number;
  fuse_cost: number;
  net_incentive: number;
  cost_recoup?: number;
  grand_total?: number;
};

export type IncentiveStatementFacilityValues = Record<TProgramId, IncentiveStatementFacilityValue[]>;

export type IncentiveStatementProgramTotals = Record<TProgramId, IncentiveStatementProgramTotal>;

export type IncentiveStatementFacilityTotals = Record<TProgramId, Record<TFacilityId, IncentiveStatementFacilityValue>>;

export type IncentiveStatementTotal = {
  program_totals: IncentiveStatementProgramTotal;
  facility_totals: Record<TFacilityId, IncentiveStatementFacilityValue>;
};

export type IncentiveStatementTotals = Record<TProgramId, IncentiveStatementTotal>;

/**
 * Model Audit Logs
 */
export enum ModelAuditLogEventType {
  Create = 'Create',
  Update = 'Update',
  Delete = 'Delete',
}

export enum ModelAuditLogState {
  Unresolved = 1,
  Resolved = 2,
}

export enum AuditorType {
  System = 'SYSTEM',
  InternalUser = 'INTERNAL_USER',
  ExternalUser = 'EXTERNAL_USER',
}

export type TModelAuditLogChangeDataItem = {
  field: string;
  old_value: Nullable<string | number>;
  new_value: Nullable<string | number>;
};

export type TModelAuditLogChangeData = TModelAuditLogChangeDataItem[];
