import { Storage } from '.';
import { TLoginResponse } from '/src/api';
import { User } from '/src/lib/models';
import { StorageKeys, TSessionToken } from '/src/lib/types';

/**
 * Session Token
 */
export const getSessionToken = (key?: StorageKeys) => {
  return Storage.get(key ?? StorageKeys.SessionToken);
};

export const setSessionToken = (token: TSessionToken, key?: StorageKeys) => {
  Storage.set(key ?? StorageKeys.SessionToken, token);
};

export const removeSessionToken = (key?: StorageKeys) => {
  Storage.remove(key ?? StorageKeys.SessionToken);
};

/**
 * Session User
 */
export const getSessionUser = (key?: StorageKeys) => {
  return Storage.get(key ?? StorageKeys.SessionUser);
};

export const setSessionUser = (user: User, key?: StorageKeys) => {
  Storage.set(key ?? StorageKeys.SessionUser, user);
};

export const removeSessionUser = (key?: StorageKeys) => {
  Storage.remove(key ?? StorageKeys.SessionUser);
};

/**
 * Session Expiration
 */
export const getSessionExpiration = (key?: StorageKeys) => {
  return Storage.get(key ?? StorageKeys.SessionExpiration);
};

export const setSessionExpiration = (expiration: Date, key?: StorageKeys) => {
  Storage.set(key ?? StorageKeys.SessionExpiration, expiration);
};

export const removeSessionExpiration = (key?: StorageKeys) => {
  Storage.remove(key ?? StorageKeys.SessionExpiration);
};

/**
 * Session Login Data
 */
export const getSession = (key?: StorageKeys) => {
  return Storage.get(key ?? StorageKeys.Session);
};

export const setSession = (loginData: TLoginResponse, key?: StorageKeys) => {
  Storage.set(key ?? StorageKeys.Session, loginData);
};

export const removeSession = (key?: StorageKeys) => {
  Storage.remove(key ?? StorageKeys.Session);
};
