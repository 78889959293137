import { TListResponse } from '.';
import {
  Equipment,
  TClientId,
  TEquipmentId,
  TEquipmentTypeId,
  TFSERegistrationStatusId,
  TFacilityId,
  TFuelPathwayId,
} from '/src/lib/models';
import { DateString, FloatString, Nullable, TResponseMetadata } from '/src/lib/types';

/**
 * List Equipment by Facility
 */
export type TListEquipmentRequest = {
  facility_id: TFacilityId;
  page?: number;
  limit?: number;
  is_active?: boolean;
  is_metered?: boolean;
  equipment_type_id?: TEquipmentTypeId;
  fuel_pathway_id?: TFuelPathwayId;
  name?: string;
  unit_number?: string;
  serial_number?: string;
  manufacturer?: string;
  model_number?: string;
  model_year?: number;
  fse_registration_status_id?: TFSERegistrationStatusId;
  fse_id?: string;
  fse_ru?: string;
};

export type TListEquipmentResponse = TListResponse<Equipment[]>;

/**
 * List Equipment by Client
 */
export type TListClientEquipmentRequest = {
  client_id: TClientId;
  page?: number;
  limit?: number;
  is_active?: boolean;
  is_metered?: boolean;
  equipment_type_id?: TEquipmentTypeId;
  fuel_pathway_id?: TFuelPathwayId;
  name?: string;
  unit_number?: string;
  serial_number?: string;
  manufacturer?: string;
  model_number?: string;
  model_year?: number;
  fse_registration_status_id?: TFSERegistrationStatusId;
  fse_id?: string;
  fse_ru?: string;
};

export type TListClientEquipmentResponse = TListResponse<Equipment[]>;

/**
 * List Metered Equipment
 */
export type TListMeteredEquipmentRequest = {
  facility_id: TFacilityId;
};

export type TListMeteredEquipmentResponse = {
  meta: TResponseMetadata;
  data: Equipment[];
};

/**
 * List All Equipment
 */
export type TListAllEquipmentRequest = {
  page?: number;
  limit?: number;
  is_active?: boolean;
  is_metered?: boolean;
  equipment_type_id?: TEquipmentTypeId;
  fuel_pathway_id?: TFuelPathwayId;
  name?: string;
  unit_number?: string;
  serial_number?: string;
  manufacturer?: string;
  model_number?: string;
  model_year?: number;
  fse_registration_status_id?: TFSERegistrationStatusId;
  fse_id?: string;
  fse_ru?: string;
};

export type TListAllEquipmentResponse = TListResponse<Equipment[]>;

/**
 * Get Equipment
 */
export type TGetEquipmentRequest = {
  id: TEquipmentId;
};

export type TGetEquipmentResponse = Equipment;

/**
 * Create Equipment - Internal Users
 */
export type TCreateEquipmentInternalRequest = {
  facility_id: TFacilityId;
  fuel_pathway_id: TFuelPathwayId;
  equipment_type_id: TEquipmentTypeId;
  fse_registration_status_id: TFSERegistrationStatusId;
  first_active_reporting_quarter: Nullable<DateString>;
  fse_ru: Nullable<string>;
  fse_id: Nullable<string>;
  manufacturer: string;
  model_number: Nullable<string>;
  model_year: number;
  serial_number: string;
  unit_number: Nullable<string>;
  first_day_in_service: Nullable<DateString>;
  is_metered: boolean;
  battery_capacity_rating_ah?: number;
  voltage?: number;
  is_book_and_claim_applied: Nullable<boolean>;
  latitude: FloatString;
  longitude: FloatString;
  comments: Nullable<string>;
};

export type TCreateEquipmentInternalResponse = Equipment;

/**
 * Create Equipment - External Users
 */
export type TCreateEquipmentExternalRequest = {
  facility_id: TFacilityId;
  equipment_type_id: TEquipmentTypeId;
  manufacturer: string;
  model_number: Nullable<string>;
  model_year: number;
  serial_number: string;
  unit_number: Nullable<string>;
  first_day_in_service: Nullable<DateString>;
  is_metered: boolean;
  battery_capacity_rating_ah?: number;
  voltage?: number;
  latitude: FloatString;
  longitude: FloatString;
  comments: Nullable<string>;
};

export type TCreateEquipmentExternalResponse = Equipment;

/**
 * Update NonFinalized Equipment - Internal Users
 */
export type TUpdateNonFinalizedEquipmentInternalRequest = {
  id: TEquipmentId;
  fuel_pathway_id: TFuelPathwayId;
  equipment_type_id: TEquipmentTypeId;
  fse_registration_status_id: TFSERegistrationStatusId;
  first_active_reporting_quarter: Nullable<DateString>;
  fse_id: Nullable<string>;
  fse_ru: Nullable<string>;
  manufacturer: string;
  model_number: Nullable<string>;
  model_year: number;
  serial_number: string;
  unit_number: Nullable<string>;
  first_day_in_service: Nullable<DateString>;
  is_metered: boolean;
  battery_capacity_rating_ah?: number;
  voltage?: number;
  is_book_and_claim_applied: Nullable<boolean>;
  latitude: FloatString;
  longitude: FloatString;
  comments: Nullable<string>;
};

export type TUpdateNonFinalizedEquipmentInternalResponse = Equipment;

/**
 * Update Finalized Equipment - Internal Users
 */
export type TUpdateFinalizedEquipmentInternalRequest = {
  id: TEquipmentId;
  latitude: FloatString;
  longitude: FloatString;
  comments: Nullable<string>;
};

export type TUpdateFinalizedEquipmentInternalResponse = Equipment;

/**
 * Update Pending Equipment - External Users
 */
export type TUpdatePendingEquipmentExternalRequest = {
  id: TEquipmentId;
  equipment_type_id: TEquipmentTypeId;
  manufacturer: string;
  model_number: Nullable<string>;
  model_year: number;
  serial_number: string;
  unit_number: Nullable<string>;
  first_day_in_service: Nullable<DateString>;
  is_metered: boolean;
  battery_capacity_rating_ah?: number;
  voltage?: number;
  latitude: FloatString;
  longitude: FloatString;
  comments: Nullable<string>;
};

export type TUpdatePendingEquipmentExternalResponse = Equipment;

/**
 * Update Non-Pending Equipment - External Users
 */
export type TUpdateNonPendingEquipmentExternalRequest = {
  id: TEquipmentId;
  latitude: FloatString;
  longitude: FloatString;
  comments: Nullable<string>;
};

export type TUpdateNonPendingEquipmentExternalResponse = Equipment;

/**
 * Retire Equipment
 *
 * last_active_reporting_quarter
 * - string in YYYY-MM-01 format
 * - If the facility’s program uses Quarterly reporting, then MM must equal 01, 04, 07, or 10
 * - If the facility’s program uses Yearly reporting, then MM must equal 10
 */
export type TRetireEquipmentRequest = {
  id: TEquipmentId;
  last_active_reporting_quarter: DateString;
};
