import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import React, { PropsWithChildren } from 'react';

export const Link: React.FC<LinkProps> = (props) => {
  const { children, fontFamily, textDecoration = 'none', style, ...routerLinkProps } = props;

  const customStyles: Record<string, string> = {};
  if (fontFamily) customStyles.fontFamily = fontFamily;
  if (textDecoration) customStyles.textDecoration = textDecoration;

  return (
    <RouterLink style={{ ...customStyles, ...style }} {...routerLinkProps}>
      {children}
    </RouterLink>
  );
};

export type LinkProps = PropsWithChildren &
  RouterLinkProps & {
    fontFamily?: string;
    textDecoration?: string;
  };
