import { ResponsiveContext } from 'grommet';
import { observer } from 'mobx-react-lite';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ApiError, ClientService, IncentiveStatementService } from '/src/api';
import {
  Box,
  IncentiveStatementDataTable,
  IncentiveStatementDescription,
  IncentiveStatementFuseInfo,
  IncentiveStatementInfoBar,
  Line,
  LoadingSpinner,
  Text,
} from '/src/components';
import { useGlobalStore, useUserStore } from '/src/context';
import { Client, IncentiveStatement, TIncentiveStatementId } from '/src/lib/models';
import { toastMessages } from '/src/lib/toast';
import { PageNotFound } from '/src/pages';
import { pxToPt } from '/src/utils';

export const IncentiveStatementDetails = observer(() => {
  /* Context */
  const userStore = useUserStore();
  const globalStore = useGlobalStore();
  const screenSize = useContext(ResponsiveContext);

  /* Query Params */
  const params = useParams();
  const incentiveStatementId = parseInt(params.incentive_statement_id ?? '');
  const isInvalidParams = Number.isNaN(incentiveStatementId);

  /* State */
  const [isLoading, setIsLoading] = useState(true);
  const [incentiveStatement, setIncentiveStatement] = useState<IncentiveStatement>();
  const [client, setClient] = useState<Client>();

  /* Computed */
  const isMobile = screenSize === 'small' || screenSize === 'xsmall';

  /* Methods */
  const fetchPageData = async (incentiveStatementId: TIncentiveStatementId) => {
    if (!incentiveStatementId) return;

    try {
      setIsLoading(true);
      const incentiveStatement = await IncentiveStatementService.getIncentiveStatement({ id: incentiveStatementId });
      setIncentiveStatement(incentiveStatement);

      const client =
        userStore.isClientUser || userStore.isFacilityUser
          ? userStore.user?.clients.find((client) => client.id === incentiveStatement.client_id)
          : await ClientService.get({ id: incentiveStatement.client_id });
      setClient(client);
    } catch (err) {
      globalStore.handleApiError(err as ApiError, toastMessages.getIncentiveStatement.error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (incentiveStatementId) {
      fetchPageData(incentiveStatementId);
    }
  }, [incentiveStatementId]);

  useEffect(() => {
    toast.info(toastMessages.statements.info, {
      autoClose: 10000,
    });
  }, []);

  /* Render */
  return isInvalidParams ? (
    <PageNotFound />
  ) : !isLoading && incentiveStatement && client ? (
    <Box>
      <Box fill="horizontal" background="brand" height={pxToPt('8px')} />
      <Box pad={{ horizontal: pxToPt('44px'), vertical: pxToPt('24px') }} fontFamily="Lato, sans-serif">
        {/* Header */}
        <Box direction="row" align="center" justify="between" margin={{ bottom: pxToPt('16px') }}>
          <IncentiveStatementFuseInfo />
          <IncentiveStatementDescription />
        </Box>

        {/* Title */}
        <Box margin={{ bottom: pxToPt('16px') }}>
          <Text size={pxToPt('30px')} weight={700} letterSpacing={pxToPt('-0.4px')}>
            Incentive Statement
          </Text>
        </Box>

        {/* Info Bar */}
        <IncentiveStatementInfoBar
          client={client}
          incentiveStatement={incentiveStatement}
          margin={{ bottom: pxToPt('4px') }}
          pad={{ horizontal: pxToPt('4px') }}
        />

        {/* Line */}
        <Line margin={pxToPt('14px')} size="xsmall" color="text" />

        {/* Data Table */}
        <IncentiveStatementDataTable incentiveStatement={incentiveStatement} margin={{ bottom: pxToPt('24px') }} />
      </Box>
    </Box>
  ) : (
    <LoadingSpinner size="large" />
  );
});
