import { ResponsiveContext } from 'grommet';
import { CircleAlert } from 'grommet-icons';
import { observer } from 'mobx-react-lite';
import { useContext, useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getIsMobile } from '../utils';
import { ApiError, UserService } from '/src/api';
import { Box, Card, CardBody, CardHeader, LoadingSpinner, Text } from '/src/components';
import { useGlobalStore } from '/src/context';
import { DesktopLayout } from '/src/layouts';
import { toastMessages } from '/src/lib/toast';

const VerifyEmail = observer(() => {
  /** Context **/
  const globalStore = useGlobalStore();
  const params = useParams();
  const screenSize = useContext(ResponsiveContext);

  /** State **/
  const [isLoading, setIsLoading] = useState(true);
  const [isValid, setIsValid] = useState(true);

  /** Computed **/
  const isMobile = getIsMobile(screenSize);

  /** Methods **/
  const verifyEmail = async (token: string) => {
    try {
      setIsLoading(true);
      await UserService.verifyEmail(token);
      toast.success(toastMessages.verifyEmail.success);
    } catch (err) {
      const error = err as ApiError;
      if (error.body?.message.toLowerCase() === 'invalid token') {
        setIsValid(false);
      } else {
        globalStore.handleApiError(err as ApiError, toastMessages.verifyEmail.error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  /** Effects **/
  useEffect(() => {
    if (params.token) verifyEmail(params.token);
  }, [params.token]);

  /** Render **/
  const renderErrorPage = () => (
    <DesktopLayout>
      <Box flex="grow" justify="center" align="center">
        <Card flex="shrink" width={!isMobile ? '35%' : '80%'} margin={{ bottom: 'xlarge' }}>
          <CardHeader title="Invalid Token" icon={<CircleAlert color="brand" />}></CardHeader>
          <CardBody>
            <Box>
              <Text fontFamily="Lato, sans-serif">
                This token is invalid or has expired. Please re-enter your new email address on your User Profile page
                to try again.
              </Text>
            </Box>
          </CardBody>
        </Card>
      </Box>
    </DesktopLayout>
  );

  return isLoading ? (
    <LoadingSpinner />
  ) : isValid ? (
    <Navigate to="/login" state={{ didVerify: true }} />
  ) : (
    renderErrorPage()
  );
});

export default VerifyEmail;
