import { observer } from 'mobx-react-lite';
import { ListPage, ModelAuditLogList } from '/src/components';

export const ListModelAuditLogsPage = observer(() => {
  const isLoading = false;

  return (
    <ListPage title="Change Logs" isLoading={isLoading}>
      <ModelAuditLogList showSearchInput showFilters />
    </ListPage>
  );
});
