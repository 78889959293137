import { Route } from 'react-router-dom';
import { CreateFacilityPage } from './create-facility';
import { FacilityDetailsPage } from './facility-details';
import { ListFacilitiesPage } from './list-facilities';
import { UseActiveClient, RequireAuth } from '/src/components';

export { CreateFacilityPage, FacilityDetailsPage, ListFacilitiesPage };

export const facilityRoutes: React.ReactElement[] = [
  // Create Facility
  <Route
    path="/clients/:client_id/facilities/create"
    element={
      <RequireAuth>
        <UseActiveClient>
          <CreateFacilityPage />
        </UseActiveClient>
      </RequireAuth>
    }
  />,

  // Facility Details
  <Route
    path="/clients/:client_id/facilities/:facility_id"
    element={
      <RequireAuth>
        <UseActiveClient>
          <FacilityDetailsPage />
        </UseActiveClient>
      </RequireAuth>
    }
  />,

  // List Facilities
  <Route
    path="/clients/:client_id/facilities"
    element={
      <RequireAuth>
        <UseActiveClient>
          <ListFacilitiesPage />
        </UseActiveClient>
      </RequireAuth>
    }
  />,
];
