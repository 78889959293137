import { ApexOptions } from 'apexcharts';
import { Card, CardBody, CardHeader, ResponsiveContext } from 'grommet';
import { ColorType } from 'grommet/utils';
import { useContext, useMemo, useRef } from 'react';
import Chart from 'react-apexcharts';
import { Box, BoxProps, LoadingSpinner, Text } from '/src/components';
import { DashboardData } from '/src/lib/models';
import theme from '/src/theme';

export const RevenueByEquipmentChart: React.FC<RevenueByEquipmentChartProps> = (props) => {
  /* Props */
  const { data, hideHeader, height, ...cardProps } = props;

  /* Context */
  const screenSize = useContext(ResponsiveContext);

  /* Memos */
  const isMobile = useMemo(() => screenSize === ('small' || 'xsmall'), [screenSize]);

  const seriesData = useMemo(() => {
    const sortedCategories = data.percent_revenue_per_equipment_category.sort((a, b) =>
      a.category.toLowerCase() > b.category.toLowerCase() ? 1 : -1
    );

    const seriesData: Record<string, number> = {};

    sortedCategories.forEach(({ category, percentage }) => {
      seriesData[category] = parseFloat(percentage.toString());
    });

    return seriesData;
  }, [data]);

  const chartData = useMemo(() => Object.values(seriesData), [seriesData]);
  const chartLabels = useMemo(() => Object.keys(seriesData), [seriesData]);

  /* Refs */
  const themeColors = useRef(theme.global?.colors ?? {});
  const chartTitle = useRef('Percent Revenue By Equipment Type');
  const cardHeight = useRef('32.5rem');
  const chartColors = useRef<ColorType[]>([
    themeColors.current['accent-3'],
    themeColors.current['accent-5'],
    themeColors.current['brand'],
  ]);
  const chartOptions = useRef({
    chart: {
      type: 'donut',
      fontFamily: 'Roboto Condensed, sans-serif',
      foreColor: themeColors.current['dark-3'] as string,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: false,
    },
    plotOptions: {
      pie: {
        donut: {
          size: '50%',
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '14px',
      },
      y: {
        formatter: (value) => `${value}%`,
      },
    },
    colors: chartColors.current,
    legend: {
      fontSize: '16px',
      position: 'bottom',
    },
  } as ApexOptions);

  /* Render */
  return (
    <Card height={height || cardHeight.current} {...cardProps}>
      {!hideHeader && (
        <CardHeader>
          <Text size="xlarge">{chartTitle.current}</Text>
        </CardHeader>
      )}
      <CardBody pad="none" gap="none">
        {!chartData || !chartLabels ? (
          <LoadingSpinner />
        ) : (
          <Box alignSelf="center" pad="1.5rem" fill>
            <Chart
              options={{ ...chartOptions.current, labels: chartLabels }}
              series={chartData}
              type="donut"
              width="100%"
              height="100%"
            />
          </Box>
        )}
      </CardBody>
    </Card>
  );
};

export type RevenueByEquipmentChartProps = BoxProps & {
  data: DashboardData;
  hideHeader?: boolean;
};
