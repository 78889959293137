import type {
  CancelablePromise,
  TGetAdminDashboardDataRequest,
  TGetAdminDashboardDataResponse,
  TGetClientDashboardDataRequest,
  TGetClientDashboardDataResponse,
} from '/src/api';
import { OpenAPI, request as __request } from '/src/api';

export class DashboardService {
  /**
   * Get Admin Dashboard Data
   * @returns DashboardData
   * @throws ApiError
   */
  public static getAdminView({
    country,
  }: TGetAdminDashboardDataRequest): CancelablePromise<TGetAdminDashboardDataResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/dashboard/{country}',
      path: { country },
    });
  }

  /**
   * Get Client Dashboard Data
   * @returns DashboardData
   * @throws ApiError
   */
  public static getClientView({
    client_id,
  }: TGetClientDashboardDataRequest): CancelablePromise<TGetClientDashboardDataResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/dashboard/{client_id}',
      path: { client_id },
    });
  }
}
