import { ColumnConfig, DataTable } from 'grommet';
import { LinkNext } from 'grommet-icons';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo } from 'react';
import { AddButton, SearchResultsBar, Box, DataTableHeader, DataTableItem, Link, Text } from '/src/components';
import { useClientStore, useGlobalStore, useSearchStore, useUserStore } from '/src/context';
import { Client, Equipment, Facility, TClientId, User } from '/src/lib/models';
import { EntityStatus, ModelType, SelectOption } from '/src/lib/types';
import { formatNumber } from '/src/utils';

export const SearchDataTable: React.FC<SearchDataTableProps> = observer((props) => {
  const { maxItems = 10, showResultsLink } = props;

  const searchStore = useSearchStore();
  const globalStore = useGlobalStore();
  const clientStore = useClientStore();
  const userStore = useUserStore();

  const getClientColumns = () => {
    const columns = [
      {
        property: 'name',
        primary: true,
        size: '100%',
        header: <DataTableHeader margin="none">NAME</DataTableHeader>,
        render: (client: Client) => (
          <DataTableItem>
            <Link to={`/clients/${client.id}`} textDecoration="none">
              {client.name}
            </Link>
          </DataTableItem>
        ),
      },
    ] as ColumnConfig<Client | Facility | Equipment | User>[];

    return columns;
  };

  const getFacilityColumns = (searchField: string, clientOptions: SelectOption<TClientId>[]) => {
    const columns: ColumnConfig<Client | Facility | Equipment | User>[] = [];

    const getFacilityUrl = (facility: Facility) => `/clients/${facility.client_id}/facilities/${facility.id}`;

    columns.push({
      property: 'id',
      primary: true,
      header: <DataTableHeader margin="none">ADDRESS</DataTableHeader>,
      render: (facility: Facility) => (
        <Box pad={{ vertical: 'xsmall' }}>
          <Link to={getFacilityUrl(facility)} textDecoration="none">
            <Box>
              <Text size="medium" weight={700} color="accent-1" fontFamily="Lato, sans-serif" lineHeight="1.25rem">
                {facility.address_line1}
              </Text>
              <Text size="small" color="accent-1" fontFamily="Lato, sans-serif" lineHeight="1rem">
                {facility.address_city},{' '}
                {(facility.address_region ?? globalStore.getRegionById(facility.address_region_id))?.short_code}{' '}
                {facility.address_post_code}
              </Text>
            </Box>
          </Link>
        </Box>
      ),
    } as ColumnConfig<Client | Facility | Equipment | User>);

    switch (searchField.toLowerCase()) {
      case 'name':
        columns.push({
          property: 'name',
          header: <DataTableHeader margin="none">NAME</DataTableHeader>,
          render: (facility: Facility) => (
            <DataTableItem>
              <Link to={getFacilityUrl(facility)} textDecoration="none">
                {facility.name}
              </Link>
            </DataTableItem>
          ),
        } as ColumnConfig<Client | Facility | Equipment | User>);
        break;
      case 'program_id':
        columns.push({
          property: 'program_id',
          header: <DataTableHeader margin="none">PROGRAM</DataTableHeader>,
          render: (facility: Facility) => <DataTableItem>{facility.program?.name}</DataTableItem>,
        } as ColumnConfig<Client | Facility | Equipment | User>);
        break;
      case 'utility_id':
        columns.push({
          property: 'utility_id',
          header: <DataTableHeader margin="none">UTILITY</DataTableHeader>,
          render: (facility: Facility) => <DataTableItem>{facility.utility.name}</DataTableItem>,
        } as ColumnConfig<Client | Facility | Equipment | User>);
        break;
      case 'is_active':
        columns.push({
          property: 'is_active',
          header: <DataTableHeader margin="none">STATUS</DataTableHeader>,
          render: (facility: Facility) => (
            <DataTableItem>{facility.is_active ? EntityStatus.Active : EntityStatus.Inactive}</DataTableItem>
          ),
        } as ColumnConfig<Client | Facility | Equipment | User>);
        break;
      default:
        break;
    }

    columns.push({
      property: 'client.name',
      size: columns.length === 1 ? '50%' : '30%',
      header: <DataTableHeader margin="none">CLIENT</DataTableHeader>,
      render: (facility: Facility) => (
        <DataTableItem>
          {!userStore.isFacilityAdmin ? (
            <Link to={`/clients/${facility.client_id}`}>
              {clientOptions.find((option) => option.value === facility.client_id)?.label}
            </Link>
          ) : (
            clientOptions.find((option) => option.value === facility.client_id)?.label
          )}
        </DataTableItem>
      ),
    } as ColumnConfig<Client | Facility | Equipment | User>);

    return columns;
  };

  const getEquipmentColumns = (searchField: string, clientOptions: SelectOption<TClientId>[]) => {
    const getEquipmentUrl = (equipment: Equipment) => `/clients/${equipment.client_id}/equipment/${equipment.id}`;

    const columns = [
      {
        property: 'manufacturer',
        header: <DataTableHeader margin="none">MANUFACTURER</DataTableHeader>,
        render: (equipment: Equipment) => (
          <DataTableItem>
            <Link to={getEquipmentUrl(equipment)}>{equipment.manufacturer}</Link>
          </DataTableItem>
        ),
      },
      {
        property: 'serial_number',
        header: <DataTableHeader margin="none">SERIAL NUMBER</DataTableHeader>,
        render: (equipment: Equipment) => (
          <DataTableItem>
            <Link to={getEquipmentUrl(equipment)}>{equipment.serial_number}</Link>
          </DataTableItem>
        ),
      },
      {
        property: 'equipment_type_id',
        header: <DataTableHeader margin="none">EQUIPMENT TYPE</DataTableHeader>,
        render: (equipment: Equipment) => <DataTableItem>{equipment.equipment_type.name}</DataTableItem>,
      },
    ] as ColumnConfig<Client | Facility | Equipment | User>[];

    switch (searchField) {
      case 'model_year':
        columns.push({
          property: 'model_year',
          header: <DataTableHeader margin="none">MODEL YEAR</DataTableHeader>,
          render: (equipment: Equipment) => <DataTableItem>{equipment.model_year}</DataTableItem>,
        } as ColumnConfig<Client | Facility | Equipment | User>);
        break;
      case 'model_number':
        columns.push({
          property: 'model_number',
          header: <DataTableHeader margin="none">MODEL NUMBER</DataTableHeader>,
          render: (equipment: Equipment) => <DataTableItem>{equipment.model_number}</DataTableItem>,
        } as ColumnConfig<Client | Facility | Equipment | User>);
        break;
      case 'fse_id':
        columns.push({
          property: 'fse_id',
          header: <DataTableHeader margin="none">FSE ID</DataTableHeader>,
          render: (equipment: Equipment) => <DataTableItem>{equipment.fse_id}</DataTableItem>,
        } as ColumnConfig<Client | Facility | Equipment | User>);
        break;
      case 'fse_ru':
        columns.push({
          property: 'fse_ru',
          header: <DataTableHeader margin="none">FSE RU</DataTableHeader>,
          render: (equipment: Equipment) => <DataTableItem>{equipment.fse_ru}</DataTableItem>,
        } as ColumnConfig<Client | Facility | Equipment | User>);
        break;
      case 'is_active':
        columns.push({
          property: 'is_active',
          header: <DataTableHeader margin="none">STATUS</DataTableHeader>,
          render: (equipment: Equipment) => (
            <DataTableItem>{equipment.is_active ? EntityStatus.Active : EntityStatus.Inactive}</DataTableItem>
          ),
        } as ColumnConfig<Client | Facility | Equipment | User>);
        break;
      case 'is_metered':
        columns.push({
          property: 'is_metered',
          header: <DataTableHeader margin="none">METERED</DataTableHeader>,
          render: (equipment: Equipment) => <DataTableItem>{equipment.is_metered ? 'Yes' : 'No'}</DataTableItem>,
        } as ColumnConfig<Client | Facility | Equipment | User>);
        break;
      default:
        break;
    }

    return columns;
  };

  const getUserColumns = () => {
    const getUserUrl = (user: User) => `/users/${user.id}`;

    const columns = [
      {
        property: 'name',
        width: '50%',
        header: <DataTableHeader margin="none">NAME</DataTableHeader>,
        render: (user: User) => (
          <DataTableItem boxProps={{ pad: { vertical: 'xsmall' } }}>
            <Link to={getUserUrl(user)} textDecoration="none">
              {user.name}
            </Link>
          </DataTableItem>
        ),
      },
      {
        property: 'email',
        header: <DataTableHeader margin="none">EMAIL</DataTableHeader>,
        render: (user: User) => <DataTableItem>{user.email}</DataTableItem>,
      },
      {
        property: 'role_id',
        header: <DataTableHeader margin="none">ROLE</DataTableHeader>,
        render: (user: User) => (
          <DataTableItem>{globalStore.roles?.find((role) => role.id === user.role_id)?.name}</DataTableItem>
        ),
      },
    ] as ColumnConfig<Client | Facility | Equipment | User>[];

    return columns;
  };

  const searchResultColumns = useMemo(() => {
    if (!searchStore.searchEntity || !searchStore.searchField || !clientStore.clientOptions) return [];
    if (searchStore.searchEntity === ModelType.Clients) {
      return getClientColumns();
    } else if (searchStore.searchEntity === ModelType.Facilities) {
      return getFacilityColumns(searchStore.searchField, clientStore.clientOptions);
    } else if (searchStore.searchEntity === ModelType.Equipment) {
      return getEquipmentColumns(searchStore.searchField, clientStore.clientOptions);
    } else if (searchStore.searchEntity === ModelType.Users) {
      return getUserColumns();
    }
  }, [searchStore.searchEntity, searchStore.searchField, clientStore.clientOptions]);

  const topResults = useMemo(() => searchStore.results?.slice(0, maxItems), [searchStore.results]);

  const totalText = useMemo(() => {
    if (!searchStore.resultsMetadata) return;

    return !!searchStore.resultsMetadata.total ? (
      <Text size="small">
        Showing{' '}
        <b>
          {formatNumber(
            searchStore.resultsMetadata.total < maxItems ? searchStore.resultsMetadata.total : maxItems,
            true
          ) ?? 0}
        </b>{' '}
        of <b>{formatNumber(searchStore.resultsMetadata.total, true)}</b> results
      </Text>
    ) : (
      <></>
    );
  }, [searchStore.resultsMetadata, maxItems]);

  useEffect(() => {
    if (!clientStore.clientOptions) {
      clientStore.fetchClientOptions();
    }
  }, [clientStore.clientOptions]);

  return !!searchStore.results && !!searchStore.resultsMetadata && !!clientStore.clientOptions ? (
    <Box>
      <Box>
        <SearchResultsBar />
      </Box>
      <Box
        border={{
          side: searchStore.resultsMetadata.total < maxItems ? 'top' : 'horizontal',
          color: 'brand',
          size: '2px',
        }}
        transitionProperty="height"
        transitionDuration="0.3s"
        transitionTimingFunction="ease"
      >
        {!!searchStore.results.length && (
          <DataTable
            data={topResults}
            columns={searchResultColumns}
            background={['light-6', 'white']}
            border={{ color: 'light-2', side: 'bottom', size: 'small' }}
          />
        )}
        {!searchStore.results.length && (
          <Box pad={{ vertical: 'medium' }} background="light-6" justify="center">
            <Text alignSelf="center" size="medium" fontFamily="Lato, sans-serif">
              No results found.
            </Text>
          </Box>
        )}
      </Box>
      {showResultsLink && searchStore.resultsMetadata.total > maxItems && (
        <Box pad="small" align="center">
          <Link to={`/search`}>
            <AddButton border={{ size: '0' }} label="View all results" icon={<LinkNext size="14px" />} reverse />
          </Link>
        </Box>
      )}
    </Box>
  ) : (
    <></>
  );
});

export type SearchDataTableProps = {
  maxItems?: number;
  showResultsLink?: boolean;
};
