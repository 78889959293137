import { Add, IconProps, Trash, Update } from 'grommet-icons';
import { AuditLogIcon } from '/src/components/icons';
import { ModelAuditLogEventType } from '/src/lib/types';

export const AuditLogEventTypeIcon: React.FC<AuditLogEventTypeIconProps> = ({
  auditLogEventTypeName,
  ...iconProps
}) => {
  switch (auditLogEventTypeName) {
    case ModelAuditLogEventType.Create:
      return <Add {...iconProps} />;
    case ModelAuditLogEventType.Update:
      return <Update {...iconProps} />;
    case ModelAuditLogEventType.Delete:
      return <Trash {...iconProps} />;
    default:
      return <AuditLogIcon {...iconProps} />;
  }
};

export type AuditLogEventTypeIconProps = IconProps & { auditLogEventTypeName: string };
