import { SelectOption } from '/src/lib/types';

export type TRoleId = number;

export enum RoleTypes {
  FuseAdmin = 1,
  FuseSalesRep = 2,
  ClientAdmin = 3,
  FacilityAdmin = 4,
}

export const InternalRoles = [RoleTypes.FuseAdmin, RoleTypes.FuseSalesRep];

export const SalesRoles = [RoleTypes.FuseSalesRep];

export const ClientRoles = [RoleTypes.ClientAdmin];

export const FacilityRoles = [RoleTypes.FacilityAdmin];

export const ExternalRoles = [...ClientRoles, ...FacilityRoles];

export const AdminRoles = [RoleTypes.FuseAdmin, RoleTypes.ClientAdmin, RoleTypes.FacilityAdmin];

export const roleList: Role[] = [
  { id: 1, name: 'Admin' },
  { id: 2, name: 'Sales Rep' },
  { id: 3, name: 'Client Admin' },
  { id: 4, name: 'Facility Admin' },
];

export const roleIdOptions: SelectOption<TRoleId>[] = [
  { value: 1, label: 'Admin' },
  { value: 2, label: 'Sales Rep' },
  { value: 3, label: 'Client Admin' },
  { value: 4, label: 'Facility Admin' },
];

export interface Role {
  id: TRoleId;
  name: string;
  is_internal?: boolean;
}
