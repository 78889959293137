import { Select as GrommetSelect } from 'grommet';
import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { Box, BoxProps, Text, TextProps } from '/src/components';
import { useSearchStore, useUserStore } from '/src/context';
import { filterStringOptions } from '/src/utils';
import { SelectOption } from '/src/lib/types';

export const SearchEntitySelect: React.FC<SearchEntitySelectProps> = observer((props) => {
  const { labelSize, labelWeight, ...boxProps } = props;

  const searchStore = useSearchStore();
  const userStore = useUserStore();

  const entityOptions = useMemo(() => {
    const entities: string[] = ['Facilities', 'Equipment'];

    if (userStore.isInternalUser) entities.unshift('Clients');
    if (!userStore.isFacilityAdmin) entities.push('Users');

    return filterStringOptions(searchStore.entityFilter, entities);
  }, []);

  return (
    <Box direction="row" fill="horizontal" {...boxProps}>
      <Box align="center" justify="center" width="20%">
        <Text toUpperCase size={labelSize || 'small'} weight={labelWeight || 300}>
          Find
        </Text>
      </Box>
      <Box
        justify="center"
        fontFamily="Lato, sans-serif"
        width="80%"
        border={{ color: searchStore.isEntityFocused ? 'accent-1' : 'light-5' }}
        round="6px"
      >
        <GrommetSelect
          plain
          name="search_entity"
          dropAlign={{ top: 'bottom', right: 'right' }}
          value={searchStore.searchEntity}
          placeholder="Choose..."
          focusIndicator
          options={entityOptions}
          searchPlaceholder="Find entity by name..."
          onFocus={() => searchStore.setIsEntityFocused(true)}
          onBlur={() => searchStore.setIsEntityFocused(false)}
          onClose={() => searchStore.setEntityFilter('')}
          onSearch={(filter) => searchStore.setEntityFilter(filter)}
          onChange={({ value }) => searchStore.setSearchEntity(value)}
        />
      </Box>
    </Box>
  );
});

export type SearchEntitySelectProps = BoxProps & {
  labelSize?: TextProps['size'];
  labelWeight?: TextProps['weight'];
};
