import { Button, Layer } from 'grommet';
import { Close } from 'grommet-icons';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ApiError, FacilityService } from '/src/api';
import { EntityIcon, FormCard, Select } from '/src/components';
import { useClientStore, useGlobalStore } from '/src/context';
import { Facility, ModelName } from '/src/lib/models';
import { toastMessages } from '/src/lib/toast';
import { CountryId, DateString } from '/src/lib/types';
import { getFormErrors, getModelLabel, updateFormValue } from '/src/utils';

export type TRetireFacilityModalForm = {
  last_active_reporting_quarter: DateString;
};

export const RetireFacilityModal: React.FC<RetireFacilityModalProps> = observer((props) => {
  const { facility, setIsVisible, setShouldRefresh } = props;

  /** Stores **/
  const globalStore = useGlobalStore();
  const clientStore = useClientStore();

  /** State **/
  const [isRetiring, setIsRetiring] = useState(false);
  const [formValues, setFormValues] = useState<TRetireFacilityModalForm>({
    last_active_reporting_quarter: '',
  });
  const [formErrors, setFormErrors] = useState<Record<string, string>>({});

  /** Computed **/
  const isLoading = !clientStore.reportingPeriodOptions;

  /** Methods **/
  const updateValue = (key: string, value: any) =>
    updateFormValue(key, value, formValues, setFormValues, formErrors, setFormErrors);

  const retireFacility = async () => {
    if (isRetiring) return;
    try {
      const errors = getFormErrors(
        {
          last_active_reporting_quarter: { label: 'Retirement Period', required: true },
        },
        formValues
      );
      if (errors) {
        setFormErrors(errors);
        return;
      }

      setIsRetiring(true);
      await FacilityService.retire({
        id: facility.id,
        last_active_reporting_quarter: formValues.last_active_reporting_quarter,
      });
      setShouldRefresh(true);
      setIsVisible(false);
      toast.success(toastMessages.retireFacility.success);
    } catch (err) {
      globalStore.handleApiError(err as ApiError, toastMessages.retireFacility.error);
    } finally {
      setIsRetiring(false);
    }
  };

  /** Effects **/
  useEffect(() => {
    if (facility.client_id) {
      clientStore.fetchClientReportingPeriods(facility.client_id, facility.reporting_period_type_id);
    }
  }, [facility.client_id, facility.reporting_period_type_id]);

  /** Render **/
  return (
    <Layer onEsc={() => setIsVisible(false)} onClickOutside={() => setIsVisible(false)} background="transparent">
      <FormCard
        width="30rem"
        title={`Retire ${getModelLabel(facility, ModelName.Facility)}`}
        icon={<EntityIcon entityName={ModelName.Facility} />}
        onSubmit={() => retireFacility()}
        isLoading={isLoading || isRetiring}
        hideRequiredText
        saveButtonLabel="SAVE"
        saveButtonLoadingLabel="SAVING..."
        headerChildren={[
          <Button
            key="close"
            pad="none"
            onClick={() => setIsVisible(false)}
            icon={<Close size="20px" />}
            tip="Close"
          />,
        ]}
      >
        <Select
          width="50%"
          label="Retirement Period"
          placeholder="Select Period..."
          value={formValues.last_active_reporting_quarter}
          setValue={(value) => updateValue('last_active_reporting_quarter', value)}
          options={clientStore.reportingPeriodOptions ?? []}
          error={formErrors['last_active_reporting_quarter']}
          hideRequiredMarker
          required
          fill="horizontal"
        />
      </FormCard>
    </Layer>
  );
});

export type RetireFacilityModalProps = {
  facility: Facility;
  setIsVisible: (isVisible: boolean) => void;
  setShouldRefresh: (shouldRefresh: boolean) => void;
};
