import { FuelPathway, TProgramId, TRegionId } from '/src/lib/models';
import { DateString } from '/src/lib/types';

/**
 * List Fuel Pathways
 */
export type TListFuelPathwaysRequest = {
  program_id?: TProgramId;
  region_id?: TRegionId;
  start_reporting_quarter?: DateString;
  end_reporting_quarter?: DateString;
};

export type TListFuelPathwaysResponse = FuelPathway[];
