import { ResponsiveContext } from 'grommet';
import React, { PropsWithChildren, useContext, useMemo } from 'react';
import { Box, LoadingSpinner } from '/src/components/common';
import { PageHeader } from './PageHeader';
import { DesktopLayout } from '/src/layouts';

export const ListPage: React.FC<ListPageProps> = (props) => {
  const { children, isLoading, breadcrumbNav, pageHeader, userInfoBar, title, isUserPage } = props;

  const screenSize = useContext(ResponsiveContext);
  const isMobile = useMemo(() => screenSize === 'small' || screenSize === 'xsmall', [screenSize]);

  return (
    <DesktopLayout>
      {isLoading && <LoadingSpinner size="large" />}
      {!isLoading && (
        <>
          {breadcrumbNav}
          <Box
            flex="grow"
            pad={{
              horizontal: isMobile ? '3.75rem' : '8rem',
              vertical: isMobile ? '2.25rem' : '3rem',
            }}
          >
            {pageHeader ?? <PageHeader title={title} isUserPage={isUserPage} />}
            {userInfoBar}
            <Box direction={isMobile ? 'column' : 'row'} gap="medium">
              {children}
            </Box>
          </Box>
        </>
      )}
    </DesktopLayout>
  );
};

export type ListPageProps = PropsWithChildren & {
  isLoading?: boolean;
  breadcrumbNav?: React.ReactNode;
  pageHeader?: React.ReactNode;
  userInfoBar?: React.ReactNode;
  title: string;
  isUserPage?: boolean;
};
