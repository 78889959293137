import type { CancelablePromise, TListUtilitiesRequest, TListUtilitiesResponse } from '/src/api';
import { OpenAPI, request as __request } from '/src/api';

export class UtilityService {
  /**
   * List Utilities
   * @returns Utility[] Utility[]
   * @throws ApiError
   */
  public static listUtilities({ region_id }: TListUtilitiesRequest): CancelablePromise<TListUtilitiesResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/utilities',
      query: { region_id },
    });
  }
}
