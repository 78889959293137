import { Box, Main } from 'grommet';
import { ReactNode, useEffect } from 'react';
import { FooterNav, HeaderNav } from '/src/components';

export const DesktopLayout = ({ children }: { children: ReactNode; showSidebar?: boolean }) => {
  useEffect(() => {
    scrollTo({ top: 0 });
  }, []);

  return (
    <Box style={{ minHeight: '100vh' }}>
      <HeaderNav />
      <Main background="light">
        {children}
        <Box>
          <FooterNav />
        </Box>
      </Main>
    </Box>
  );
};
