import { DateString } from '../types';

export type TModelAuditLogStateId = number;

export interface ModelAuditLogState {
  id: TModelAuditLogStateId;
  name: string;
  created_at?: DateString;
  updated_at?: DateString;
}
