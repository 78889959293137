import { Button, Keyboard, Select, TextInput } from 'grommet';
import { Search } from 'grommet-icons';
import React from 'react';
import { Box, BoxProps } from '/src/components';

export const SearchInput: React.FC<SearchInputProps> = (props) => {
  const {
    searchQuery,
    setSearchQuery,
    onSearch,
    searchField,
    setSearchField,
    isSearching,
    showIcon = true,
    placeholder,
    ...boxProps
  } = props;

  return (
    <Box height="xxlarge" direction="row" border={{ color: 'light-5', size: '0.8px' }} round="6px" {...boxProps}>
      {searchField && setSearchField && (
        <Box border={{ side: 'right', color: 'light-5', size: '0.8px' }}>
          <Select
            id="search_field"
            name="search_field"
            plain
            value={searchField}
            onChange={(e) => setSearchField(e.target.value ?? '')}
            options={['Name', 'Address']}
          />
        </Box>
      )}
      <Box justify="center">
        <Keyboard onEnter={() => onSearch(searchQuery)}>
          <TextInput
            id="search_query"
            name="search_query"
            plain
            style={{ fontFamily: 'Lato, sans-serif' }}
            placeholder={placeholder || (searchField ? `Search by ${searchField.toLowerCase()}` : 'Enter name...')}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            onSubmit={() => onSearch(searchQuery)}
          />
        </Keyboard>
      </Box>
      {showIcon && (
        <Button id="search_button" type="submit" onClick={() => onSearch(searchQuery)} disabled={isSearching}>
          <Box
            height="100%"
            justify="center"
            background="light-6"
            alignSelf="center"
            border={{ side: 'left', color: 'light-5', size: '0.8px' }}
            pad={{ horizontal: '0.75rem' }}
          >
            <Search size="18px" />
          </Box>
        </Button>
      )}
    </Box>
  );
};

export type SearchInputProps = BoxProps & {
  searchQuery: string;
  setSearchQuery: (searchQuery: string) => void;
  onSearch: (searchQuery: string) => void;
  searchField?: string;
  setSearchField?: (searchField: string) => void;
  isSearching?: boolean;
  showIcon?: boolean;
  placeholder?: string;
};
