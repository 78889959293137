import type { CancelablePromise } from '/src/api';
import { OpenAPI, request as __request } from '/src/api';
import { BankAccountType } from '/src/lib/models';

export class BankAccountTypeService {
  /**
   * List BankAccountTypes
   * @returns BankAccountType[] BankAccountType[]
   * @throws ApiError
   */
  public static listBankAccountTypes(): CancelablePromise<BankAccountType[]> {
    return __request(OpenAPI, { method: 'GET', url: '/bankAccountTypes' });
  }
}
