import { Country } from '/src/lib/models';

export type TReferralPartnerId = number;

export interface ReferralPartner {
  id: TReferralPartnerId;
  name: string;
  address_line_1: string;
  address_line_2?: string;
  city: string;
  region: string;
  country: Country;
  post_code: string;
  phone: string;
}
