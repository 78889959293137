import { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { RequireAuth, UseActiveClient } from './components';
import { useUserStore } from './context';
import {
  clientRoutes,
  Dashboard,
  equipmentRoutes,
  facilityRoutes,
  Login,
  Logout,
  modelAuditLogRoutes,
  PageNotFound,
  RequestPasswordReset,
  ResetPassword,
  Search,
  statementRoutes,
  userRoutes,
  VerifyEmail,
} from '/src/pages';

export const AppRoutes = () => {
  const location = useLocation();
  const userStore = useUserStore();

  const hsq = ((window as any)._hsq = (window as any)._hsq || []);

  useEffect(() => {
    if (userStore.user) {
      hsq.push(['identify', { id: userStore.user.id, email: userStore.user.email }]);
    }
  }, [userStore.user]);

  useEffect(() => {
    hsq.push(['setPath', location.pathname]);
    hsq.push(['trackPageView']);
  }, [location]);

  return (
    <Routes>
      {/* 404 */}
      <Route path="*" element={<PageNotFound />} />

      {...userRoutes}
      {...clientRoutes}
      {...statementRoutes}
      {...equipmentRoutes}
      {...facilityRoutes}
      {...modelAuditLogRoutes}

      {/* Search Results */}
      <Route
        path="/search"
        element={
          <RequireAuth>
            <UseActiveClient>
              <Search />
            </UseActiveClient>
          </RequireAuth>
        }
      />

      {/* Login */}
      <Route path="/login" element={<Login />} />

      {/* Logout */}
      <Route path="/logout" element={<Logout />} />

      {/* Request Password Reset */}
      <Route path="/reset-password" element={<RequestPasswordReset />} />

      {/* Reset Password */}
      <Route path="/reset-password/:token" element={<ResetPassword />} />

      {/* Verify Email Address */}
      <Route path="/verify-email/:token" element={<VerifyEmail />} />

      {/* Dashboard */}
      <Route
        path="/"
        element={
          <RequireAuth>
            <UseActiveClient>
              <Dashboard />
            </UseActiveClient>
          </RequireAuth>
        }
      />
    </Routes>
  );
};
