import { ResponsiveContext } from 'grommet';
import { CircleInformation } from 'grommet-icons';
import { observer } from 'mobx-react-lite';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ApiError, ApiErrorItem, ClientService, TCreateClientRequest } from '/src/api';
import {
  Box,
  BreadcrumbNav,
  ClientCorporateInformation,
  ClientGeneralInformation,
  ClientRemittanceInformation,
  FormCard,
  FormPage,
  Line,
} from '/src/components';
import { useGlobalStore } from '/src/context';
import { toastMessages } from '/src/lib/toast';
import { ClientDataForm, FormMetadata } from '/src/lib/types';
import { capitalize, getFormErrors } from '/src/utils';

export const CreateClientPage = observer(() => {
  /* Context */
  const globalStore = useGlobalStore();
  const navigate = useNavigate();
  const screenSize = useContext(ResponsiveContext);

  /* Refs */
  const defaultFormData = useRef<ClientDataForm>({
    name: '',
    website: '',
    business_id: '',
    start_date: '',
    first_active_reporting_quarter: '',
    remittance_method_id: NaN,
    bank_name: '',
    bank_account_type_id: NaN,
    routing_number: '',
    account_number: '',
    jumpstart_amount: '',
    comments: '',
    hq_address_line1: '',
    hq_address_line2: '',
    hq_address_city: '',
    hq_address_region_id: NaN,
    hq_address_post_code: '',
    remittance_address_line1: '',
    remittance_address_line2: '',
    remittance_address_city: '',
    remittance_address_region_id: NaN,
    remittance_address_post_code: '',
  });

  /* State */
  const [isLoading] = useState(false);
  const [isCreating, setIsCreating] = useState(false);

  // Form Values
  const [formValues, setFormValues] = useState<ClientDataForm>(defaultFormData.current);
  const [formErrors, setFormErrors] = useState<Record<string, string>>({});

  /* Computed */
  const isMobile = screenSize === ('small' || 'xsmall');

  /* Methods */
  const updateFormValue = (key: string, value: any) => {
    setFormValues({
      ...formValues,
      [key]: value,
    });
    if (formErrors[key]) formErrors[key] = '';
  };

  const validateForm = (formValues: ClientDataForm, shouldSetErrors = true) => {
    if (!formValues) return false;

    const formFields: FormMetadata = {
      name: { label: 'Name', max: 100, required: true },
      website: { label: 'Website', max: 255 },
      business_id: { label: 'FEIN or Business Number', required: true, max: 20 },
      start_date: { label: 'Start date', required: true },
      first_active_reporting_quarter: { label: 'First Reporting Period', required: true },
      remittance_method_id: { label: 'Remittance method', required: true },
      comments: { label: 'Comments', max: 100 },
      hq_address_line1: { label: 'Address', max: 100, required: true },
      hq_address_line2: { label: 'Address', max: 100 },
      hq_address_city: { label: 'City', max: 50, required: true },
      hq_address_region_id: { label: 'Region', required: true },
      hq_address_post_code: { label: 'Postal code', max: 20, required: true },
      bank_account_type_id: { label: 'Bank account type', required: formValues.remittance_method_id === 1 },
      bank_name: { label: 'Bank name', max: 50, required: formValues.remittance_method_id === 1 },
      routing_number: { label: 'Routing number', max: 20, required: formValues.remittance_method_id === 1 },
      account_number: { label: 'Account number', max: 30, required: formValues.remittance_method_id === 1 },
      remittance_address_line1: { label: 'Address', max: 100, required: formValues.remittance_method_id === 2 },
      remittance_address_line2: { label: 'Address', max: 100 },
      remittance_address_city: { label: 'City', max: 50, required: formValues.remittance_method_id === 2 },
      remittance_address_region_id: { label: 'Region', max: 50, required: formValues.remittance_method_id === 2 },
      remittance_address_post_code: {
        label: 'Postal code',
        max: 20,
        required: formValues.remittance_method_id === 2,
      },
    };

    const errors = getFormErrors(formFields, formValues);
    if (errors && shouldSetErrors) setFormErrors(errors);
    return !errors;
  };

  const createClient = async () => {
    if (!validateForm(formValues)) return;

    const clientWebsite = formValues.website
      ? formValues.website.split('://').length === 1
        ? `https://${formValues.website}`
        : formValues.website
      : null;

    const newClient: TCreateClientRequest = {
      name: formValues.name,
      website: clientWebsite,
      business_id: formValues.business_id,
      start_date: formValues.start_date,
      first_active_reporting_quarter: formValues.first_active_reporting_quarter,
      remittance_method_id: formValues.remittance_method_id,
      bank_name: formValues.bank_name || null,
      bank_account_type_id: formValues.bank_account_type_id || null,
      routing_number: formValues.routing_number || null,
      account_number: formValues.account_number || null,
      jumpstart_amount: null,
      comments: formValues.comments || null,
      hq_address_line1: formValues.hq_address_line1,
      hq_address_line2: formValues.hq_address_line2 || null,
      hq_address_city: formValues.hq_address_city,
      hq_address_region_id: formValues.hq_address_region_id,
      hq_address_post_code: formValues.hq_address_post_code,
      remittance_address_line1: formValues.remittance_address_line1 || null,
      remittance_address_line2: formValues.remittance_address_line2 || null,
      remittance_address_city: formValues.remittance_address_city || null,
      remittance_address_region_id: formValues.remittance_address_region_id || null,
      remittance_address_post_code: formValues.remittance_address_post_code || null,
    };

    try {
      setIsCreating(true);
      const client = await ClientService.create(newClient);
      toast.success(toastMessages.createClient.success);
      navigate(`/clients/${client.id}`);
    } catch (err) {
      const apiError = err as ApiError;

      if (!!apiError.body?.errors) {
        const errorItems = apiError.body.errors as ApiErrorItem[];

        const errors = { ...formErrors };

        errorItems.forEach((error) => {
          errors[error.field] = capitalize(error.message);
        });

        setFormErrors(errors);
      } else {
        globalStore.handleApiError(apiError, toastMessages.createClient.error);
      }
    } finally {
      setIsCreating(false);
    }
  };

  useEffect(() => {
    if (formValues.hq_address_region_id) {
      formValues.first_active_reporting_quarter = defaultFormData.current.first_active_reporting_quarter;
    }
  }, [formValues.hq_address_region_id]);

  /* Render */
  return (
    <FormPage
      title="Add Client"
      breadcrumbNav={
        <BreadcrumbNav previousPages={[{ name: 'Clients', link: '/clients' }]} currentPageName={'Add Client'} />
      }
      isLoading={isLoading}
    >
      <FormCard
        title="Client Details"
        icon={<CircleInformation size="24px" color="brand" />}
        isLoading={isLoading || isCreating}
        onSubmit={createClient}
      >
        <ClientGeneralInformation
          updateFormValue={updateFormValue}
          formValues={formValues}
          formErrors={formErrors}
          onSubmit={createClient}
        />

        <Line margin="1rem" />

        <Box direction={isMobile ? 'column' : 'row'}>
          <Box width={isMobile ? '100%' : '50%'}>
            <ClientCorporateInformation
              updateFormValue={updateFormValue}
              formValues={formValues}
              formErrors={formErrors}
              onSubmit={createClient}
            />
          </Box>

          {isMobile ? <Line /> : <Line direction="vertical" margin="2rem" />}

          <Box width={isMobile ? '100%' : '50%'}>
            <ClientRemittanceInformation
              updateFormValue={updateFormValue}
              formValues={formValues}
              formErrors={formErrors}
              onSubmit={createClient}
            />
          </Box>
        </Box>
      </FormCard>
    </FormPage>
  );
});
