import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ClientService, FacilityService } from '/src/api';
import { AddEquipmentModal, Box, BreadcrumbNav, EquipmentList, ListPage, PageHeader } from '/src/components';
import { useClientStore, useGlobalStore, useUserStore } from '/src/context';
import { Facility } from '/src/lib/models';
import { toastMessages } from '/src/lib/toast';
import { CountryId } from '/src/lib/types';
import { getFacilityLabel } from '/src/utils';

export const ListEquipmentPage = observer(() => {
  /** Context */
  const globalStore = useGlobalStore();
  const userStore = useUserStore();
  const clientStore = useClientStore();
  const params = useParams();
  const clientId = parseInt(params.client_id ?? '');
  const facilityId = parseInt(params.facility_id ?? '');

  /** State */
  const [showEquipmentModal, setShowEquipmentModal] = useState(false);
  const [clientName, setClientName] = useState('');
  const [facility, setFacility] = useState<Facility>();

  /** Computed */
  const isLoading = !(clientName && facility);
  const facilityName = facility ? getFacilityLabel(facility) : '';
  const isCanada = facility?.address_region?.country_id === CountryId.Canada;

  useEffect(() => {
    if (!clientName && userStore.user) {
      if (userStore.isExternalUser) {
        setClientName(clientStore.activeClient?.name ?? '');
      } else {
        ClientService.get({ id: clientId })
          .then((client) => setClientName(client.name))
          .catch((err) => globalStore.handleApiError(err, toastMessages.listFacilities.error));
      }
    }
  }, [clientName, userStore.user, userStore.isExternalUser, clientStore.activeClient]);

  useEffect(() => {
    if (!facility) {
      FacilityService.get({ id: facilityId })
        .then((facility) => setFacility(facility))
        .catch((err) => globalStore.handleApiError(err, toastMessages.listFacilities.error));
    }
  }, [facility]);

  return (
    <ListPage
      title={facilityName}
      pageHeader={
        <PageHeader
          title={facilityName}
          showAddButton={userStore.isAdminUser}
          addButtonLabel="ADD EQUIPMENT"
          onAddButton={() => setShowEquipmentModal(true)}
        />
      }
      breadcrumbNav={
        <BreadcrumbNav
          previousPages={[
            { name: 'Clients', link: !userStore.isClientUser && !userStore.isFacilityUser ? '/clients' : undefined },
            { name: clientName, link: !userStore.isFacilityUser ? `/clients/${clientId}` : undefined },
            {
              name: 'Facilities',
              link: `/clients/${clientId}/facilities`,
            },
            {
              name: facilityName,
              link: `/clients/${clientId}/facilities/${facilityId}`,
            },
          ]}
          currentPageName="Equipment"
        />
      }
      isLoading={isLoading}
    >
      <Box flex="grow">
        <EquipmentList
          title="Fleet List"
          clientId={clientId}
          facilityId={facilityId}
          isCanada={isCanada}
          firstReportingQuarter={facility?.first_active_reporting_quarter}
          lastReportingQuarter={facility?.last_active_reporting_quarter}
          showBulkUpdateButton
        />
      </Box>
      {showEquipmentModal && !!clientId && !!facilityId && (
        <AddEquipmentModal clientId={clientId} facilityId={facilityId} setIsVisible={setShowEquipmentModal} />
      )}
    </ListPage>
  );
});
