import { Button } from 'grommet';
import { Next, Previous } from 'grommet-icons';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { Box, Select } from '/src/components';
import { ClientReportingPeriod, TClientId, TClientReportingPeriodId } from '/src/lib/models';
import { SelectOptions } from '/src/lib/types';
import { getPeriodFromDateString } from '/src/utils';

export const ReportingPeriodDropdown: React.FC<ReportingPeriodDropdownProps> = observer((props) => {
  const {
    reportingPeriods,
    selectedReportingPeriod,
    setSelectedReportingPeriod,
    nextPeriod,
    previousPeriod,
    clientId,
    isCanada,
    id,
    name,
    showNav,
    ...selectProps
  } = props;

  /** State **/
  const [options, setOptions] = useState<SelectOptions<TClientReportingPeriodId>>([]);

  /** Effects **/
  useEffect(() => {
    if (reportingPeriods) {
      const periodOptions = reportingPeriods.map((period) => ({
        label: getPeriodFromDateString(period.end_reporting_quarter, !!isCanada),
        value: period.id,
      }));

      setOptions(periodOptions);
    }
  }, [reportingPeriods, isCanada]);

  /** Render **/
  return (
    <Box direction="row" gap="small">
      {showNav && (
        <Button
          plain
          icon={<Previous />}
          onClick={() => {
            if (previousPeriod) setSelectedReportingPeriod(previousPeriod);
          }}
          disabled={!previousPeriod}
          tip="Previous Period"
        />
      )}
      <Select
        id={id}
        name={id ? undefined : name || 'reporting_period'}
        label={selectProps.label || 'Reporting Period'}
        placeholder={selectProps.placeholder || 'Choose...'}
        value={selectedReportingPeriod?.id}
        setValue={(value) => {
          const period = reportingPeriods?.find((p) => p.id === value);
          if (period) setSelectedReportingPeriod(period);
        }}
        options={options}
        required={selectProps.required}
      />
      {showNav && (
        <Button
          plain
          icon={<Next />}
          onClick={() => {
            if (nextPeriod) setSelectedReportingPeriod(nextPeriod);
          }}
          disabled={!nextPeriod}
          tip="Next Period"
        />
      )}
    </Box>
  );
});

export type ReportingPeriodDropdownProps = {
  reportingPeriods: ClientReportingPeriod[];
  setSelectedReportingPeriod: (selectedPeriod: ClientReportingPeriod) => void;
  selectedReportingPeriod?: ClientReportingPeriod;
  nextPeriod?: ClientReportingPeriod;
  previousPeriod?: ClientReportingPeriod;
  clientId?: TClientId;
  isCanada?: boolean;
  showNav?: boolean;
  label?: string;
  id?: string;
  name?: string;
  placeholder?: string;
  required?: boolean;
};
