import { Avatar } from 'grommet';
import { Box, BoxProps, Link, Text } from '/src/components/common';
import { useUserStore } from '/src/context';
import { TClientId, User } from '/src/lib/models';
import { getInitials } from '/src/utils';

export const UserInfoBox: React.FC<UserInfoBoxProps> = (props) => {
  const { user, clientId, hideAvatar, ...boxProps } = props;

  const userStore = useUserStore();

  const usersPath = clientId && userStore.isExternalUser ? `/clients/${clientId}/users` : '/users';

  return (
    <Box direction="row" gap="small" pad={{ vertical: '1rem' }} {...boxProps}>
      {!hideAvatar && (
        <Box alignSelf="center">
          <Avatar background="light-3" size="3rem" hoverIndicator={{ elevation: 'small' }}>
            <Text size="medium" color="white">
              {getInitials(user.name)}
            </Text>
          </Avatar>
        </Box>
      )}
      <Box alignSelf="center">
        <Link to={`${usersPath}/${user.id}`} textDecoration="none">
          <Text color="accent-1" size="medium" weight={700} fontFamily="Lato, sans-serif" lineHeight="1rem">
            {user.name}
          </Text>
        </Link>
        <Text size="small" fontFamily="Lato, sans-serif" lineHeight="1.25rem">
          {user.email}
        </Text>
        <Text size="small" fontFamily="Lato, sans-serif" lineHeight="1.25rem">
          {user.phone}
        </Text>
      </Box>
    </Box>
  );
};

export type UserInfoBoxProps = BoxProps & {
  user: User;
  clientId?: TClientId;
  hideAvatar?: boolean;
};
