import { useRef } from 'react';
import { Box, BoxProps, Text } from '/src/components';
import { pxToPt } from '/src/utils';

export const IncentiveStatementDescription: React.FC<IncentiveStatementDescriptionProps> = (props) => {
  const { ...boxProps } = props;

  const incentiveStatementNotice =
    useRef(`The incentive statement below reflects the credits generated by your organization's enrolled electric
  fleet/equipment in applicable state, provincial, or federal clean fuel standard program. For questions or
  additional information, please contact our team at support@usefuse.com, or through the chat feature in the
  app.`);

  return (
    <Box width="50%" pad={{right: pxToPt('6px')}} {...boxProps}>
      <Text size={pxToPt('11px')}>{incentiveStatementNotice.current}</Text>
    </Box>
  );
};

export type IncentiveStatementDescriptionProps = BoxProps;
