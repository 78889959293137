import type { CancelablePromise, TLoginRequest, TLoginResponse, TLogoutResponse } from '/src/api';
import { OpenAPI, request as __request } from '/src/api';

export class AuthService {
  /**
   * Login
   * @param loginRequest TLoginRequest
   * @returns object JSONWebToken
   * @throws ApiError
   */
  public static login(req: TLoginRequest): CancelablePromise<TLoginResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/login',
      body: req,
    });
  }

  /**
   * Logout
   * @returns object TLogoutResponse
   * @throws ApiError
   */
  public static logout(): CancelablePromise<TLogoutResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/logout',
    });
  }
}
