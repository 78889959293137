import React, { PropsWithChildren } from 'react';
import { Box, Text, TextProps } from '/src/components';

export const DataTableHeader: React.FC<DataTableHeaderProps> = ({ children, title, border, ...textProps }) => (
  <Box border={border ? { color: 'transparent', size: 'small' } : undefined} pad={{ vertical: '1.125rem' }}>
    <Text size="small" color="dark-1" toUpperCase {...textProps}>
      {children ?? title}
    </Text>
  </Box>
);

export type DataTableHeaderProps = PropsWithChildren &
  TextProps & {
    title?: string;
    border?: boolean;
  };
