import { CardBody as GrommetCardBody, ResponsiveContext } from 'grommet';
import { CircleInformation } from 'grommet-icons';
import { observer } from 'mobx-react-lite';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Box, Card, CardHeader, EntityIcon, InfoBox, SaveButton, Select, Text } from '/src/components';
import { useClientStore, useDashboardStore } from '/src/context';
import { ModelName, TClientId, User } from '/src/lib/models';
import { tooltipMessages } from '/src/lib/tooltips';
import { getIsMobile } from '/src/utils';

export const ActiveClientModal: React.FC<ActiveClientModalProps> = observer((props) => {
  const { user, setIsVisible } = props;

  /** Context **/
  const navigate = useNavigate();
  const dashboardStore = useDashboardStore();
  const clientStore = useClientStore();
  const screenSize = useContext(ResponsiveContext);

  /** State **/
  const [selectedClientId, setSelectedClientId] = useState<TClientId>();

  /** Computed **/
  const isMobile = getIsMobile(screenSize);
  const clientOptions = user?.clients?.map((client) => ({ label: client.name, value: client.id })) ?? [];
  const isLoading = !clientOptions;

  /** Methods **/
  const setActiveClient = () => {
    if (!selectedClientId || !user) return;

    const activeClient = user.clients.find((client) => client.id === selectedClientId);

    // TODO: figure out how to handle this situation
    if (!activeClient) {
      console.warn('Couldn\'t find Active Client with ID "%s" in User Clients list', selectedClientId);
      return;
    }

    clientStore.setActiveClientId(selectedClientId);
    toast.success(`Active company changed to ${activeClient.name}`);

    dashboardStore.clearDashboardData();
    navigate('/', { replace: true });
    setIsVisible(false);
  };

  return (
    <Box width={!isMobile ? '33%' : '50%'}>
      <Card flex="shrink">
        <CardHeader title="Set Active Company" icon={<EntityIcon entityName={ModelName.Client} />} />

        <GrommetCardBody gap="none">
          <Box elevation="small" pad="1.5rem" gap="1rem">
            <Select
              name="active_company"
              width="50%"
              label="Company"
              placeholder="Choose..."
              value={selectedClientId}
              setValue={setSelectedClientId}
              options={clientOptions}
              hideRequiredMarker
              required
              fill="horizontal"
            />
            <InfoBox>
              <CircleInformation size="16px" />
              <Text fontFamily="Lato, sans-serif">{tooltipMessages.setActiveClientInfo}</Text>
            </InfoBox>
          </Box>
          <Box row fill="horizontal" justify="between">
            <Box pad="1.5rem" width="33%">
              <SaveButton
                type="submit"
                label="SAVE"
                loadingLabel="SAVING..."
                isLoading={isLoading}
                onClick={setActiveClient}
                disabled={!selectedClientId}
              />
            </Box>
            <Box pad="1.5rem" width="33%">
              <SaveButton
                type="submit"
                label="LOGOUT"
                loadingLabel="LOGGING OUT..."
                isLoading={isLoading}
                onClick={() => navigate('/logout', { replace: true })}
                background="white"
                hoverBackground="light-4"
                color="text"
                hoverColor="text"
              />
            </Box>
          </Box>
        </GrommetCardBody>
      </Card>
    </Box>
  );
});

export type ActiveClientModalProps = {
  user: User;
  setIsVisible: (isVisible: boolean) => void;
  canBeClosed?: boolean;
};
