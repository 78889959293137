import { TClientId, TFacilityId, TRoleId, TUserId, User } from '/src/lib/models';
import { Nullable, TResponseMetadata } from '/src/lib/types';

/**
 * List Users
 */
export type TListUsersRequest = {
  limit?: number;
  page?: number;
  name?: string;
  role_id?: TRoleId;
  is_active?: boolean;
  is_internal?: boolean;
};

export type TListUsersResponse = {
  meta: TResponseMetadata;
  data: User[];
};

/**
 * Get User
 */
export type TGetUserRequest = {
  id: TUserId;
};

export type TGetUserResponse = User;

/**
 * Create User
 */
export type TCreateUserRequest = {
  name: string;
  email: string;
  phone: Nullable<string>;
  password?: string;
  role_id: TRoleId;
  is_email_verified: boolean;
  client_id?: TClientId;
  facility_ids?: TFacilityId[];
};

export type TCreateUserResponse = User;

/**
 * Update User
 */
export type TUpdateUserRequest = {
  name: string;
  phone: Nullable<string>;
  role_id?: number;
  is_active?: boolean;
  is_email_verified?: boolean;
  add_facility_ids?: TFacilityId[];
  remove_facility_ids?: TFacilityId[];
};

export type TUpdateUserResponse = User;

/**
 * Update User Email
 */
export type TUpdateUserEmailRequest = {
  email: string;
};

export type TUpdateUserEmailResponse = User;

/**
 * Update User Password
 */
export type TUpdatePasswordRequest = {
  old_password: string;
  new_password: string;
};

/**
 * Reset Password
 */
export type TResetPasswordRequest = {
  password: string;
};

/**
 * Send Password Reset Email
 */
export type TRequestPasswordResetRequest = {
  email: string;
};

/**
 * Generate Password Reset URL
 */
export type TGeneratePasswordResetResponse = {
  password_reset_url: string;
};
