import { DateString } from '/src/lib/types';

export type TModelAuditLogEventTypeId = number;

export interface ModelAuditLogEventType {
  id: TModelAuditLogEventTypeId;
  name: string;
  created_at?: DateString;
  updated_at?: DateString;
}
