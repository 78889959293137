import { FuelPathway } from '/src/lib/models';
import { DateString, FloatString } from '/src/lib/types';

export type TFuelPathwayCIScoreId = number;

export interface FuelPathwayCIScore {
  id: TFuelPathwayCIScoreId;
  fuel_pathway: FuelPathway;
  reporting_quarter: DateString;
  ci_score: FloatString;
  ei: number;
}
