import { ResponsiveContext } from 'grommet';
import { CircleAlert } from 'grommet-icons';
import React, { useContext } from 'react';
import { Box, Card, CardBody, CardHeader, Text } from '/src/components';
import { DesktopLayout } from '/src/layouts';
import { getIsMobile } from '/src/utils';

export const AuthErrorPage: React.FC = () => {
  const screenSize = useContext(ResponsiveContext);
  const isMobile = getIsMobile(screenSize);

  return (
    <DesktopLayout>
      <Box flex="grow" justify="center" align="center">
        <Card flex="shrink" width={!isMobile ? '35%' : '80%'} margin={{ bottom: 'xlarge' }}>
          <CardHeader title="Unauthorized" icon={<CircleAlert color="brand" />}></CardHeader>
          <CardBody>
            <Box pad={{ vertical: 'xsmall' }}>
              <Text fontFamily="Lato, sans-serif">You don't have access to this page.</Text>
            </Box>
          </CardBody>
        </Card>
      </Box>
    </DesktopLayout>
  );
};
