import { ProjectedValue, SalesCommission } from '.';
import { DatabaseEntity } from './base';

export type TProjectedValueSalesCommissionId = number;

export interface ProjectedValueSalesCommission {
  id: TProjectedValueSalesCommissionId;
  projected_value: ProjectedValue;
  sales_commission: SalesCommission;
}
