import { Button, ButtonProps } from 'grommet';
import React, { useState } from 'react';
import { Box, BoxProps, Text } from '/src/components';

export const SaveButton: React.FC<SaveButtonProps> = (props) => {
  const {
    onClick,
    label,
    loadingLabel,
    isLoading,
    disabled,
    type,
    background = 'accent-1',
    hoverBackground = 'button-hover',
    color = 'white',
    hoverColor = 'white',
    boxProps,
  } = props;

  const [isSelected, setIsSelected] = useState(false);
  const [isHover, setIsHover] = useState(false);

  return (
    <Button
      type={type}
      onClick={() => onClick()}
      onMouseOver={() => setIsHover(true)}
      onMouseOut={() => setIsHover(false)}
      onFocus={() => setIsSelected(true)}
      onBlur={() => setIsSelected(false)}
      disabled={disabled}
      fill="horizontal"
    >
      <Box
        pad="0.75rem"
        background={isLoading ? 'light-2' : isSelected || isHover ? hoverBackground : background}
        borderRadius="6px"
        style={{ boxShadow: isSelected || isHover ? 'rgba(0, 0, 0, 0.2) 0 10px 10px -10px' : '' }}
        transitionProperty="background, box-shadow"
        transitionDuration="0.15s, 0.15s"
        transitionTimingFunction="ease, ease"
        {...boxProps}
      >
        <Text alignSelf="center" size="medium" weight={500} color={isSelected || isHover ? hoverColor : color}>
          {!isLoading ? label || 'SUBMIT' : loadingLabel || 'SUBMITTING...'}
        </Text>
      </Box>
    </Button>
  );
};

export type SaveButtonProps = {
  onClick: () => void;
  label?: ButtonProps['label'];
  loadingLabel?: ButtonProps['label'];
  isLoading?: boolean;
  disabled?: boolean;
  type?: ButtonProps['type'];
  boxProps?: BoxProps;
  background?: BoxProps['background'];
  hoverBackground?: BoxProps['background'];
  color?: BoxProps['color'];
  hoverColor?: BoxProps['color'];
};
