import { Box } from 'grommet';
import React from 'react';

export const Line: React.FC<LineProps> = (props) => {
  const {
    direction = 'horizontal',
    size = '0.8px',
    color = 'light-5',
    // margin = '0.5rem',
    margin = '1.5rem',
  } = props;
  const side = direction === 'vertical' ? 'right' : 'top';
  const marginSide = direction === 'vertical' ? 'horizontal' : 'vertical';

  return <Box border={{ side, size, color }} margin={{ [marginSide]: margin }} />;
};

export type LineProps = {
  direction?: 'horizontal' | 'vertical';
  size?: string;
  color?: string;
  margin?: string;
};
