import { CardBody, CardHeader, Meter, ResponsiveContext, Stack, Text } from 'grommet';
import { Checkmark } from 'grommet-icons';
import leaflet from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import 'leaflet/dist/leaflet.css';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import './CurrentFacilityLocationsChart.css';
import { Box, Card, CardProps } from '/src/components';
import { useDashboardStore, useGlobalStore } from '/src/context';
import { DashboardData } from '/src/lib/models';
import { Coordinates, CurrentFacilityLocationsChartData } from '/src/lib/types';

const CURRENT_FACILITY_LOCATIONS_CHART_TITLE = 'Current Facility Locations';
const markerIcon = leaflet.icon({ iconUrl: icon, shadowUrl: iconShadow });

export const CurrentFacilityLocationsChart: React.FC<CurrentFacilityLocationsChartProps> = (props) => {
  /* Props */
  const { height, ...cardProps } = props;

  /* Context */
  const dashboardStore = useDashboardStore();
  const screenSize = useContext(ResponsiveContext);

  /* State */
  const [isLoading, setIsLoading] = useState(true);
  const [mapData, setMapData] = useState<CurrentFacilityLocationsChartData>([]);

  /* Memos */
  const isMobile = useMemo(() => screenSize === ('small' || 'xsmall'), []);
  const cardHeight = useRef('52.125rem');
  const defaultMapCoords = useRef<Coordinates>([40.103, -122.217]); // halfway between San Diego and Seattle

  /* Effects */
  useEffect(() => {
    if (!dashboardStore.dashboardData?.facilities) return;

    if (isLoading) {
      const chartData = dashboardStore.dashboardData.facilities
        .map((facility) => ({
          name: facility.name,
          address_1: `${facility.address.line1}${facility.address.line2 ? ` ${facility.address.line2}` : ''}`,
          address_2: `${facility.address.city}, ${facility.address.region_short_code} ${facility.address.post_code}`,
          coords:
            facility.address.latitude && facility.address.longitude
              ? ([facility.address.latitude, facility.address.longitude] as Coordinates)
              : undefined,
        }))
        .filter((d) => !!d);

      setMapData(chartData);
      setIsLoading(false);
    }
  }, [isLoading, dashboardStore.dashboardData]);

  /* Render */
  return (
    <Card height={height || cardHeight.current} {...cardProps}>
      <CardHeader>
        <Text size="xlarge">{CURRENT_FACILITY_LOCATIONS_CHART_TITLE}</Text>
      </CardHeader>
      <CardBody pad="1.5rem">
        {isLoading && dashboardStore.dashboardData?.facilities && (
          <Box height="100vh" fill="horizontal" justify="center" align="center">
            <Stack anchor="center">
              <Meter
                size={`${16 * 10}px`}
                value={dashboardStore.dashboardData.facilities.length * 10}
                type="circle"
                color="green"
              />
              <Checkmark size={`${16 * 5}px`} color="green" />
            </Stack>
          </Box>
        )}
        {!isLoading && !!mapData && (
          <MapContainer center={mapData[0]?.coords ?? defaultMapCoords.current} zoom={5}>
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {mapData.map((f, i) => {
              return f.coords ? (
                <Marker key={`${f.name}-${i}`} position={f.coords} icon={markerIcon}>
                  <Popup>
                    <Box>
                      {f.name && (
                        <Text margin={{ bottom: '0.5rem' }} size="1.25rem">
                          {f.name}
                        </Text>
                      )}
                      <Text size="1rem">{f.address_1}</Text>
                      <Text size="1rem">{f.address_2}</Text>
                    </Box>
                  </Popup>
                </Marker>
              ) : undefined;
            })}
          </MapContainer>
        )}
      </CardBody>
    </Card>
  );
};

export type CurrentFacilityLocationsChartProps = CardProps & {
  data: DashboardData;
};
