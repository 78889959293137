import { ApexOptions } from 'apexcharts';
import { Box, BoxProps, Card, CardBody, CardHeader, ResponsiveContext } from 'grommet';
import { useContext, useMemo, useRef } from 'react';
import Chart from 'react-apexcharts';
import { LoadingSpinner, Text } from '/src/components';
import { DashboardData, TQuarterProgramData } from '/src/lib/models';
import { Nullable } from '/src/lib/types';
import theme from '/src/theme';
import { formatNumber } from '/src/utils';
import { ColorType } from 'grommet/utils';

export const RevenueByReportingPeriodChart: React.FC<RevenueByReportingPeriodChartProps> = (props) => {
  const { data, height, ...cardProps } = props;

  /** Context **/
  const screenSize = useContext(ResponsiveContext);

  /** Settings **/
  const themeColors = useRef(theme.global?.colors);
  const cardHeight = useRef('40.875rem');
  const chartColors = useRef<ColorType[]>([
    themeColors.current?.['accent-3'],
    themeColors.current?.['accent-5'],
    themeColors.current?.['brand'],
    themeColors.current?.['accent-4'],
  ]);

  const chartOptions = useRef<ApexOptions>({
    chart: {
      type: 'line',
      fontFamily: 'Roboto Condensed, sans-serif',
      foreColor: themeColors.current?.['dark-3'] as string,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    stroke: {
      width: [0, 3],
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 4.5,
      colors: ['#fff'],
      strokeColors: chartColors.current.slice(0, 3) as string[],
      strokeWidth: 3,
      hover: {
        size: 4,
      },
    },
    colors: chartColors.current,
    xaxis: {
      type: 'category',
      title: {
        text: 'Reporting Period',
        style: {
          fontWeight: 400,
          fontSize: '20px',
          color: themeColors.current?.['dark-3'] as string,
        },
        offsetY: -10,
      },
      labels: {
        rotate: -90,
        rotateAlways: true,
        offsetX: -1,
        style: {
          fontWeight: 700,
        },
      },
    },
    plotOptions: {
      bar: {
        hideZeroBarsWhenGrouped: true,
      },
    },
    tooltip: {
      y: {
        formatter: (val) => (val ? formatNumber(val, true, true) : '—'),
      },
      style: {
        fontSize: '14px',
      },
    },
    legend: {
      showForSingleSeries: true,
      fontSize: '16px',
      formatter: (legendName) => (legendName === 'Incentive Revenue' ? legendName : `${legendName} Price / Credit ($)`),
    },
  });

  /** Computed **/
  const isMobile = screenSize === ('small' || 'xsmall');
  // const isCanada = data?.client_region?.country_id === CountryId.Canada;
  const isCanada = true;

  const dataSeries = useMemo(() => {
    const dataSeries: Record<string, Nullable<number>[]> = {};

    const programs: Record<string, TQuarterProgramData> = {};
    data.reporting_periods.forEach((period) => {
      period.programs.forEach((program) => {
        programs[program.name] ??= program;
      });
    });

    data.reporting_periods.forEach((period) => {
      if (!dataSeries['Incentive Revenue']) dataSeries['Incentive Revenue'] = [];
      const revenueFloat = parseFloat(period.incentive_revenue);

      dataSeries['Incentive Revenue'].push(revenueFloat);

      Object.keys(programs).forEach((programName) => {
        const program = period.programs.find((program) => program.name.toLowerCase() === programName.toLowerCase());

        dataSeries[programName] ??= [];
        dataSeries[programName].push(program ? parseFloat(program.price_per_credit) || null : null);
      });
    });

    return dataSeries;
  }, [data]);

  const chartData = dataSeries
    ? Object.keys(dataSeries).map((seriesName) => ({
        name: seriesName,
        type: seriesName === 'Incentive Revenue' ? 'column' : 'line',
        data: dataSeries[seriesName],
      }))
    : undefined;

  const categoryStrokes = dataSeries
    ? Object.keys(dataSeries)
        .filter((seriesName) => seriesName !== 'Incentive Revenue')
        .map(() => 3)
    : undefined;

  const chartLabels = data?.reporting_periods.map(({ reporting_period }) => reporting_period);

  const chartYAxes = useMemo(() => {
    if (!dataSeries) return;

    let minRevenue = 0;
    let maxRevenue = 0;

    let minCreditPrice = 0;
    let maxCreditPrice = 0;

    const dataSeriesKeys = Object.keys(dataSeries);

    dataSeriesKeys.forEach((programName) => {
      dataSeries[programName].forEach((amount) => {
        if (!amount) return;
        const isRevenue = programName.toLowerCase() === 'Incentive Revenue'.toLowerCase();
        if (isRevenue) {
          if (amount > maxRevenue) maxRevenue = amount;
          else if (amount < minRevenue) minRevenue = amount;
        } else {
          if (amount > maxCreditPrice) maxCreditPrice = amount;
          else if (amount < minCreditPrice) minCreditPrice = amount;
        }
      });
    });

    const revenueYAxis: ApexYAxis = {
      seriesName: 'Incentive Revenue',
      show: false,
      min: minRevenue ? -(maxRevenue * 1.25) : 0,
      max: maxRevenue * 1.25,
    };

    const programYAxes: ApexYAxis[] = dataSeriesKeys.map((programName) => ({
      seriesName: programName,
      show: false,
      max: maxCreditPrice * 1.25,
    }));

    return [revenueYAxis, ...programYAxes];
  }, [dataSeries]);

  /** Computed **/
  const isLoading = !(chartLabels && categoryStrokes && chartYAxes && chartData);
  const chartTitle = 'Period Incentive Revenue';

  /** Render **/
  return (
    <Card flex="grow" height={height || cardHeight.current} {...cardProps}>
      <CardHeader>
        <Text size="xlarge">{chartTitle}</Text>
      </CardHeader>
      <CardBody pad={{ horizontal: 'medium', bottom: '2rem' }}>
        {isLoading && <LoadingSpinner />}
        {!isLoading && (
          <Box width="100%" flex="grow">
            <Chart
              options={{
                ...chartOptions.current,
                labels: chartLabels,
                yaxis: chartYAxes,
                stroke: {
                  width: [0, ...categoryStrokes],
                },
              }}
              series={chartData}
              width="100%"
              height="100%"
            />
          </Box>
        )}
      </CardBody>
    </Card>
  );
};

export type RevenueByReportingPeriodChartProps = BoxProps & {
  data: DashboardData;
};
