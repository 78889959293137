import { BoxProps as GrommetBoxProps, CardBody as GrommetCardBody } from 'grommet';
import React, { PropsWithChildren } from 'react';
import { Box } from '/src/components';

export const CardBody: React.FC<CardBodyProps> = ({ children, ...props }) => {
  const { gap = '1rem', saveButton, pad = '1.5rem', saveButtonPad = '1.5rem', width = '33%', ...boxProps } = props;

  return (
    <GrommetCardBody gap="none" {...boxProps}>
      <Box elevation="small" pad={pad} gap={gap}>
        {children}
      </Box>
      {!!saveButton && (
        <Box pad={saveButtonPad} width={width}>
          {saveButton}
        </Box>
      )}
    </GrommetCardBody>
  );
};

export type CardBodyProps = PropsWithChildren &
  GrommetBoxProps & {
    saveButton?: React.ReactNode;
    saveButtonPad?: GrommetBoxProps['pad'];
  };
