import { action, makeObservable, observable } from 'mobx';
import { FacilityService } from '/src/api';
import BaseStore from '/src/context/stores/baseStore';
import RootStore from '/src/context/stores/rootStore';
import { EquipmentUsage, Facility, TClientId, TFacilityId } from '/src/lib/models';
import { TResponseMetadata } from '/src/lib/types';

export default class FacilityStore extends BaseStore {
  facility?: Facility;
  facilities: Facility[] = [];
  facilitiesMetadata?: TResponseMetadata;
  facilityEquipmentUsages?: EquipmentUsage[];
  facilityEquipmentUsagesMeta?: TResponseMetadata;
  limit = 20;
  currentPage = 1;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      facility: observable,
      facilities: observable,
      facilitiesMetadata: observable,
      facilityEquipmentUsages: observable,
      facilityEquipmentUsagesMeta: observable,
      limit: observable,
      currentPage: observable,
      setFacility: action,
      setFacilityEquipmentUsages: action,
      setFacilityEquipmentUsagesMeta: action,
      fetchFacility: action,
      fetchFacilities: action,
    });
  }

  setFacility = (facility?: Facility) => {
    this.facility = facility;
  };

  setFacilityEquipmentUsages = (equipmentUsages?: EquipmentUsage[]) => {
    this.facilityEquipmentUsages = equipmentUsages;
  };

  setFacilityEquipmentUsagesMeta = (equipmentUsagesMeta?: TResponseMetadata) => {
    this.facilityEquipmentUsagesMeta = equipmentUsagesMeta;
  };

  fetchFacility = async (facilityId: TFacilityId) => {
    if (!this.rootStore.userStore.user) return;

    const facility = await FacilityService.get({ id: facilityId });
    this.setFacility(facility);
    return facility;
  };

  fetchFacilities = async (clientId?: TClientId) => {
    if (!this.rootStore.userStore.user) return;

    const { meta, data } = clientId
      ? await FacilityService.list({ client_id: clientId, page: this.currentPage })
      : await FacilityService.listAll({});

    this.facilities = data;
    this.facilitiesMetadata = meta;
  };
}
