import type { CancelablePromise, TListReportingPeriodTypesResponse } from '/src/api';
import { OpenAPI, request as __request } from '/src/api';

export class ReportingPeriodTypeService {
  /**
   * List Reporting Period Types
   */
  public static list(): CancelablePromise<TListReportingPeriodTypesResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/reportingPeriodTypes',
    });
  }
}
