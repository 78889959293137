import type {
  CancelablePromise,
  TGetIncentiveStatementRequest,
  TGetIncentiveStatementResponse,
  TGetPublicIncentiveStatementRequest,
  TGetPublicIncentiveStatementResponse,
  TListIncentiveStatementsRequest,
  TListIncentiveStatementsResponse,
} from '/src/api';
import { OpenAPI, request as __request } from '/src/api';

export class IncentiveStatementService {
  /**
   * List Incentive Statement
   * @returns IncentiveStatement[] IncentiveStatement[]
   * @throws ApiError
   */
  public static listIncentiveStatements({
    client_id,
    ...req
  }: TListIncentiveStatementsRequest): CancelablePromise<TListIncentiveStatementsResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/clients/{client_id}/incentiveStatements',
      path: { client_id },
      query: req,
    });
  }

  /**
   * Get Public Incentive Statement
   * @returns IncentiveStatement
   * @throws ApiError
   */
  public static getPublicIncentiveStatement({
    slug,
  }: TGetPublicIncentiveStatementRequest): Promise<TGetPublicIncentiveStatementResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/publicIncentiveStatements/{slug}',
      path: { slug },
    });
  }

  /**
   * Get Incentive Statement
   * @returns IncentiveStatement
   * @throws ApiError
   */
  public static getIncentiveStatement({
    id,
  }: TGetIncentiveStatementRequest): CancelablePromise<TGetIncentiveStatementResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/incentiveStatements/{id}',
      path: { id },
    });
  }
}
