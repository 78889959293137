import * as Sentry from '@sentry/react';
import { action, computed, makeObservable, observable } from 'mobx';
import { AuthService, TLoginRequest, UserService } from '/src/api';
import { OpenAPI } from '/src/api/core/OpenAPI';
import BaseStore from '/src/context/stores/baseStore';
import RootStore from '/src/context/stores/rootStore';
import {
  AdminRoles,
  ClientRoles,
  ExternalRoles,
  FacilityRoles,
  InternalRoles,
  RoleTypes,
  SalesRoles,
  TClientId,
  TRoleId,
  User,
} from '/src/lib/models';
import { StorageKeys, TSessionToken } from '/src/lib/types';
import {
  getSessionToken,
  getSessionUser,
  removeSessionToken,
  removeSessionUser,
  setSessionToken,
  setSessionUser,
} from '/src/utils';
import storage from '/src/utils/storage';

export default class UserStore extends BaseStore {
  _user?: User;
  _token?: TSessionToken;
  selectedUser?: User;

  constructor(rootStore: RootStore) {
    super(rootStore);

    if (this.token) OpenAPI.TOKEN = this.token.token;
    makeObservable(this, {
      _user: observable,
      _token: observable,
      selectedUser: observable,
      isLoggedIn: computed,
      user: computed,
      token: computed,
      roleId: computed,
      isInternalUser: computed,
      isExternalUser: computed,
      isAdminUser: computed,
      isFuseAdmin: computed,
      isSalesUser: computed,
      isClientUser: computed,
      isClientAdmin: computed,
      isFacilityUser: computed,
      isFacilityAdmin: computed,
      selectedUserName: computed,
      selectedUserId: computed,
      setSelectedUser: action,
      restoreFromStorage: action,
      refreshUser: action,
      login: action,
      logout: action,
    });
  }

  get isLoggedIn() {
    return !!this._user && !!this._token;
  }

  get user(): User | undefined {
    if (!this._user) this.restoreFromStorage();

    return this._user;
  }

  get token(): TSessionToken | undefined {
    if (!this._token) this.restoreFromStorage();
    return this._token;
  }

  get roleId(): TRoleId | undefined {
    if (!this._user) this.restoreFromStorage();
    return this._user?.role.id;
  }

  get isFuseAdmin(): boolean {
    if (!this._user) this.restoreFromStorage();
    return this.roleId === RoleTypes.FuseAdmin;
  }

  get isInternalUser() {
    if (!this._user) this.restoreFromStorage();
    return this.roleId ? InternalRoles.includes(this.roleId) : false;
  }

  get isExternalUser() {
    if (!this._user) this.restoreFromStorage();
    return this.roleId ? ExternalRoles.includes(this.roleId) : false;
  }

  get isAdminUser() {
    if (!this._user) this.restoreFromStorage();
    return this.roleId ? AdminRoles.includes(this.roleId) : false;
  }

  get isSalesUser() {
    if (!this._user) this.restoreFromStorage();
    return this.roleId ? SalesRoles.includes(this.roleId) : false;
  }

  get isClientUser() {
    if (!this._user) this.restoreFromStorage();
    return this.roleId ? ClientRoles.includes(this.roleId) : false;
  }

  get isClientAdmin() {
    if (!this._user) this.restoreFromStorage();
    return this.roleId ? this.roleId === RoleTypes.ClientAdmin : false;
  }

  get isFacilityUser() {
    if (!this._user) this.restoreFromStorage();
    return this.roleId ? FacilityRoles.includes(this.roleId) : false;
  }

  get isFacilityAdmin() {
    if (!this._user) this.restoreFromStorage();
    return this.roleId ? this.roleId === RoleTypes.FacilityAdmin : false;
  }

  get selectedUserName() {
    return this.selectedUser?.name;
  }

  get selectedUserId() {
    return this.selectedUser?.id;
  }

  getUserClient = (clientId?: TClientId) => {
    return this.user?.clients?.find((client) => client.id === clientId);
  };

  setSelectedUser = (user?: User) => {
    this.selectedUser = user;
  };

  restoreFromStorage = () => {
    const token = getSessionToken();
    const user = getSessionUser();
    if (token && user) {
      this._user = user;
      this._token = token;
      OpenAPI.TOKEN = token.token;
    }
  };

  refreshUser = async () => {
    if (!this._user) return;

    const user = await UserService.get({ id: this._user.id });

    this._user = user;
    setSessionUser(user);
  };

  login = async (req: TLoginRequest) => {
    const { token, user: loggedInUser } = await AuthService.login(req);

    OpenAPI.TOKEN = token.token;

    const user = loggedInUser.role.is_internal ? loggedInUser : await UserService.get({ id: loggedInUser.id });

    this._user = user;
    this._token = token;
    setSessionUser(user);
    setSessionToken(token);
    Sentry.getCurrentScope().setUser({ id: user.id, email: user.email });
    storage.remove(StorageKeys.ActiveClientId);
    this.rootStore.clientStore?.setActiveClientId(undefined);

    return user;
  };

  logout = () => {
    this._user = undefined;
    this._token = undefined;
    OpenAPI.TOKEN = undefined;
    removeSessionUser();
    removeSessionToken();
    storage.remove(StorageKeys.SelectedUserID);
    storage.remove(StorageKeys.ActiveClientId);
    Sentry.getCurrentScope().setUser(null);
    this.rootStore.clientStore?.setActiveClientId(undefined);
    this.rootStore.clientStore?.setSelectedClientId(undefined);
    this.rootStore.dashboardStore?.clearDashboardData();
  };
}
