import { Button, Layer } from 'grommet';
import { Close } from 'grommet-icons';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { EquipmentCategoryService, FacilityService } from '/src/api';
import {
  Box,
  BoxProps,
  Card,
  CardBody,
  CardHeader,
  EquipmentUsageIcon,
  EVStation,
  LoadingSpinner,
  Text,
} from '/src/components';
import { useGlobalStore } from '/src/context';
import { EquipmentCategory, Facility, TClientId, TFacilityId, TProgramId } from '/src/lib/models';
import { toastMessages } from '/src/lib/toast';
import { pxToRem } from '/src/utils';

const maxItemsAtOnce = 6;
const headerHeight = 60;
const itemHeight = 57.6;
const gapSize = 16;

export const AddEquipmentModal: React.FC<AddEquipmentModalProps> = (props) => {
  /* Props */
  const { clientId, facilityId, isVisible = true, setIsVisible, ...boxProps } = props;

  /* Context */
  const { handleApiError } = useGlobalStore();
  const navigate = useNavigate();

  /* State */
  const [hoverCategory, setHoverCategory] = useState('');
  const [categories, setCategories] = useState<EquipmentCategory[]>();
  const [programId, setProgramId] = useState<TProgramId>();

  /* Computed */
  const isLoading = !categories || !programId;

  /* Effects */
  useEffect(() => {
    if (programId && !categories) {
      EquipmentCategoryService.listEquipmentCategories({ program_id: programId })
        .then((categories) => {
          setCategories(categories);
        })
        .catch((err) => handleApiError(err, toastMessages.generic.error));
    }
  }, [programId, categories]);

  useEffect(() => {
    if (isLoading && facilityId) {
      FacilityService.get({ id: facilityId })
        .then((facility) => {
          setProgramId(facility.program?.id);
        })
        .catch((err) => handleApiError(err, toastMessages.generic.error));
    }
  }, [isLoading]);

  /* Render */
  const renderCategoryButton = (category: string) => {
    const isHover = hoverCategory === category;
    return (
      <Button
        key={category}
        plain
        onClick={() => navigate(`/clients/${clientId}/equipment/create?category=${category}&facility_id=${facilityId}`)}
        onMouseOver={() => setHoverCategory(category)}
        onMouseOut={() => setHoverCategory('')}
        fill="horizontal"
      >
        <Box
          direction="row"
          justify="between"
          elevation="xsmall"
          pad="0.75rem"
          border={{ color: isHover ? 'white' : 'light-2', size: '0.8px' }}
          borderRadius="4px"
          background={isHover ? 'accent-1' : 'white'}
          transitionDuration="0.3s"
          transitionProperty="background"
          transitionTimingFunction="ease"
        >
          <Box gap="0.5rem" alignSelf="center">
            <Text
              color={isHover ? 'white' : undefined}
              transitionDuration="0.3s"
              transitionProperty="color"
              transitionTimingFunction="ease"
            >
              {category}
            </Text>
          </Box>
          <EVStation
            color={isHover ? 'white' : undefined}
            style={{ transitionDuration: '0.3s', transitionProperty: 'color', transitionTimingFunction: 'ease' }}
          />
        </Box>
      </Button>
    );
  };

  return isVisible ? (
    <Layer onEsc={() => setIsVisible(false)} onClickOutside={() => setIsVisible(false)} background="transparent">
      <Card
        style={{
          borderRadius: '8px',
          maxHeight: pxToRem(headerHeight + gapSize * (maxItemsAtOnce + 2) + itemHeight * maxItemsAtOnce),
        }}
        width="31.25rem"
        {...boxProps}
      >
        {isLoading && <LoadingSpinner />}
        {!isLoading && (
          <CardHeader
            pad="1rem"
            title="What are you charging or powering?"
            icon={<EquipmentUsageIcon />}
            border={{ side: 'bottom', size: '3px', color: 'brand' }}
          >
            <Button
              key="close"
              pad="none"
              onClick={() => setIsVisible(false)}
              icon={<Close size="18px" />}
              tip="Close"
            />
          </CardHeader>
        )}
        {!isLoading && categories && (
          <CardBody pad="none">
            <Box overflow="scroll" gap="1rem" pad="1rem">
              {categories.map((category) => renderCategoryButton(category.name))}
            </Box>
          </CardBody>
        )}
      </Card>
    </Layer>
  ) : (
    <></>
  );
};

export type AddEquipmentModalProps = BoxProps & {
  clientId: TClientId;
  facilityId: TFacilityId;
  facility?: Facility;
  isVisible?: boolean;
  setIsVisible: (isVisible: boolean) => void;
};
