import { Address, TAddressId, TClientId, TRegionId } from '/src/lib/models';
import { Nullable } from '/src/lib/types';

/**
 * Get Address
 */
export type TGetAddressRequest = {
  id: TAddressId;
};

export type TGetAddressResponse = Address;

/**
 * Create Address
 */
export type TCreateAddressRequest = {
  client_id: TClientId;
  name: Nullable<string>;
  line1: string;
  line2: Nullable<string>;
  city: string;
  region_id: TRegionId;
  post_code: string;
};

export type TCreateAddressResponse = Address;
