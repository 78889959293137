import { Button, Select as GrommetSelect, Keyboard, TextInput } from 'grommet';
import { InProgress, Search } from 'grommet-icons';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo, useState } from 'react';
import {
  ApiError,
  EquipmentCategoryService,
  FSERegistrationStatusService,
  FuelPathwayService,
  ProgramService,
  UtilityService,
} from '/src/api';
import { Box, BoxProps } from '/src/components';
import { useGlobalStore, useSearchStore, useUserStore } from '/src/context';
import { toastMessages } from '/src/lib/toast';
import { EntityStatus, MeteredStatus, SelectOption } from '/src/lib/types';
import { ExternalRoles, RoleTypes } from '/src/lib/models';

export const SearchQueryInput: React.FC<SearchQueryInputProps> = observer((props) => {
  const { ...boxProps } = props;

  const userStore = useUserStore();
  const searchStore = useSearchStore();
  const { handleApiError, roles } = useGlobalStore();

  const [isButtonSelected, setIsButtonSelected] = useState(false);
  const [isLoadingOptions, setIsLoadingOptions] = useState(false);
  const [defaultOptions, setDefaultOptions] = useState<SelectOption<string | number>[]>([]);
  const [options, setOptions] = useState<SelectOption<string | number>[]>([]);

  const searchFieldOption = useMemo(
    () =>
      searchStore.searchEntity
        ? searchStore.fields[searchStore.searchEntity]?.find((f) => f.label === searchStore.searchField)
        : undefined,
    [searchStore.searchEntity, searchStore.fields, searchStore.searchField]
  );

  const isUtility = useMemo(() => searchFieldOption?.value.toLowerCase() === 'utility_id', [searchFieldOption]);
  const isProgram = useMemo(() => searchFieldOption?.value.toLowerCase() === 'program_id', [searchFieldOption]);
  const isEquipmentType = useMemo(
    () => searchFieldOption?.value.toLowerCase() === 'equipment_type_id',
    [searchFieldOption]
  );
  const isFuelPathway = useMemo(
    () => searchFieldOption?.value.toLowerCase() === 'fuel_pathway_id',
    [searchFieldOption]
  );
  const isFSERegistrationStatus = useMemo(
    () => searchFieldOption?.value.toLowerCase() === 'fse_registration_status_id',
    [searchStore.searchField]
  );
  const isRole = useMemo(() => searchFieldOption?.value.toLowerCase() === 'role_id', [searchFieldOption]);
  const isActiveStatus = useMemo(() => searchFieldOption?.value.toLowerCase() === 'is_active', [searchFieldOption]);
  const isMeteredStatus = useMemo(() => searchFieldOption?.value.toLowerCase() === 'is_metered', [searchFieldOption]);
  const isSelectField = useMemo(
    () =>
      isUtility ||
      isProgram ||
      isEquipmentType ||
      isFuelPathway ||
      isFSERegistrationStatus ||
      isRole ||
      isActiveStatus ||
      isMeteredStatus,
    [
      isUtility,
      isProgram,
      isEquipmentType,
      isFuelPathway,
      isFSERegistrationStatus,
      isRole,
      isActiveStatus,
      isMeteredStatus,
    ]
  );

  const isDisabled = useMemo(
    () => !searchStore.searchQuery && !searchStore.searchOption?.value,
    [searchStore.searchQuery, searchStore.searchOption?.value]
  );

  useEffect(() => {
    if (searchStore.searchOption?.value) {
      searchStore.search();
    }
  }, [searchStore.searchOption?.value]);

  useEffect(() => {
    if (searchFieldOption) {
      fetchOptions();
    }
  }, [searchFieldOption]);

  const fetchOptions = async () => {
    setIsLoadingOptions(true);
    try {
      let options: SelectOption<string | number>[] = [];

      if (isUtility) {
        options = (await UtilityService.listUtilities({})).map((result) => ({
          label: result.name,
          value: result.id,
        }));
      } else if (isProgram) {
        options = (await ProgramService.list()).map((result) => ({
          label: result.name,
          value: result.id,
        }));
      } else if (isEquipmentType) {
        options = (await EquipmentCategoryService.listEquipmentCategories()).flatMap(
          (category) =>
            category.equipment_types?.map((type) => ({
              label: `${category.program?.name} - ${type.name} (${category.name})`,
              value: type.id,
            })) ?? []
        );
      } else if (isFuelPathway) {
        options = (await FuelPathwayService.listFuelPathways()).map((result) => ({
          label: result.code,
          value: result.id,
        }));
      } else if (isFSERegistrationStatus) {
        options = (await FSERegistrationStatusService.listFSERegistrationStatuses()).map((result) => ({
          label: result.name,
          value: result.id,
        }));
      } else if (isRole) {
        options =
          roles?.map((result) => ({
            label: result.name,
            value: result.id,
          })) ?? [];
        if (userStore.isExternalUser) {
          options = options.filter((role) => ExternalRoles.includes(role.value as RoleTypes));
        }
      } else if (isActiveStatus) {
        options = [
          { label: EntityStatus.Active, value: EntityStatus.Active },
          { label: EntityStatus.Inactive, value: EntityStatus.Inactive },
        ];
      } else if (isMeteredStatus) {
        options = [
          { label: MeteredStatus.Metered, value: MeteredStatus.Metered },
          { label: MeteredStatus.Unmetered, value: MeteredStatus.Unmetered },
        ];
      }

      setOptions(options);
      setDefaultOptions(options);
    } catch (err) {
      handleApiError(err as ApiError, toastMessages.listFacilities.error);
    } finally {
      setIsLoadingOptions(false);
    }
  };

  const filterOptions = (filter: string) => {
    if (filter === '') setOptions(defaultOptions);
    const exp = new RegExp(filter.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&'), 'i');
    const filteredOptions = defaultOptions.filter(({ label }) => exp.test(label));
    setOptions(filteredOptions);
  };

  return (
    <Box {...boxProps} direction="row" gap="xsmall">
      {isSelectField && (
        <Box
          direction="row"
          animation={{ type: 'slideDown', duration: 300, size: 'large' }}
          border={{ color: searchStore.isQueryFocused ? 'accent-1' : 'light-5' }}
          flex="grow"
          round="6px"
        >
          <Box fill="horizontal">
            <GrommetSelect
              plain
              name="search_option"
              dropAlign={{ top: 'bottom', right: 'right' }}
              value={searchStore.searchOption}
              options={options}
              placeholder={`Choose ${searchStore.searchField}...`}
              searchPlaceholder={
                !isActiveStatus && !isMeteredStatus ? `Find ${searchStore.searchEntity} by name...` : undefined
              }
              labelKey="label"
              valueKey={{ key: 'value' }}
              onFocus={() => searchStore.setIsQueryFocused(true)}
              onBlur={() => searchStore.setIsQueryFocused(false)}
              onClose={() => filterOptions('')}
              onSearch={!isActiveStatus && !isMeteredStatus ? filterOptions : undefined}
              onChange={({ value }) => searchStore.setSearchOption(value)}
            />
          </Box>
        </Box>
      )}

      {!isSelectField && (
        <Box
          direction="row"
          animation={{ type: 'slideDown', duration: 300, size: 'large' }}
          border={{ color: searchStore.isQueryFocused ? 'accent-1' : 'light-5' }}
          round="6px"
          flex="grow"
        >
          <Keyboard onEnter={() => searchStore.search()}>
            <TextInput
              plain
              name="search_input"
              style={{
                fontFamily: 'Lato, sans-serif',
                borderTopLeftRadius: '6px',
                borderBottomLeftRadius: '6px',
                borderTopRightRadius: '0',
                borderBottomRightRadius: '0',
              }}
              placeholder={`Enter ${searchStore.searchField?.toLowerCase()}...`}
              value={searchStore.searchQuery}
              reverse
              onFocus={() => searchStore.setIsQueryFocused(true)}
              onBlur={() => searchStore.setIsQueryFocused(false)}
              onChange={(e) => searchStore.setSearchQuery(e.target.value)}
            />
          </Keyboard>
        </Box>
      )}
      <Button
        plain
        disabled={isDisabled}
        onClick={() => searchStore.search()}
        onMouseEnter={() => setIsButtonSelected(true)}
        onMouseLeave={() => setIsButtonSelected(false)}
        onFocus={() => setIsButtonSelected(true)}
        onBlur={() => setIsButtonSelected(false)}
      >
        <Box
          pad="small"
          align="center"
          justify="center"
          round="6px"
          background={!searchStore.isSearching && !isDisabled && isButtonSelected ? 'accent-1' : 'white'}
          color={!searchStore.isSearching && !isDisabled && isButtonSelected ? 'white' : 'text'}
          transitionProperty="color background"
          transitionDuration="0.2s 0.2s"
          transitionTimingFunction="ease ease"
        >
          {!searchStore.isSearching ? <Search size="18px" /> : <InProgress size="18px" />}
        </Box>
      </Button>
    </Box>
  );
});

export type SearchQueryInputProps = BoxProps & {};
