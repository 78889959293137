import { IconProps } from 'grommet-icons';
import {
  AuditLogIcon,
  ClientIcon,
  EquipmentIcon,
  EquipmentUsageIcon,
  FacilityIcon,
  UserIcon,
} from '/src/components/icons';
import { ModelName } from '/src/lib/models';

export const EntityIcon: React.FC<EntityIconProps> = ({ entityName, ...iconProps }) => {
  switch (entityName) {
    case ModelName.Client:
      return <ClientIcon {...iconProps} />;
    case ModelName.Facility:
      return <FacilityIcon {...iconProps} />;
    case ModelName.Equipment:
      return <EquipmentIcon {...iconProps} />;
    case ModelName.EquipmentUsage:
      return <EquipmentUsageIcon {...iconProps} />;
    case ModelName.User:
      return <UserIcon {...iconProps} />;
    default:
      return <AuditLogIcon {...iconProps} />;
  }
};

export type EntityIconProps = IconProps & { entityName: string };
