import React, { PropsWithChildren } from 'react';
import { Box, Text } from '/src/components';

export const Tag: React.FC<TagProps> = (props) => {
  const { children } = props;

  return (
    <Box
      background="light-4"
      pad={{ horizontal: '0.5rem', vertical: '0.25rem' }}
      border={{ color: 'light-2', size: '0.8px' }}
      borderRadius="4px"
    >
      <Text fontFamily="Lato, sans-serif" size="0.75rem">
        {children}
      </Text>
    </Box>
  );
};

export type TagProps = PropsWithChildren;
