import {
  Client,
  Equipment,
  EquipmentByCategory,
  Program,
  Region,
  TClientId,
  TProgramId,
  TRegionId,
  TReportingPeriodTypeId,
  TUtilityId,
  User,
  Utility,
} from '/src/lib/models';
import { DateString, Nullable } from '/src/lib/types';

export type TFacilityId = number;

export interface Facility {
  id: TFacilityId;
  name: Nullable<string>;
  address_line1: string;
  address_line2: Nullable<string>;
  address_city: string;
  address_region?: Region;
  address_region_id: TRegionId;
  address_post_code: string;
  address_latitude: Nullable<string>;
  address_longitude: Nullable<string>;
  is_active: boolean;
  first_active_reporting_quarter: DateString;
  last_active_reporting_quarter: Nullable<DateString>;
  reporting_period_type_id?: TReportingPeriodTypeId;
  users: User[];
  client?: Client;
  client_id?: TClientId;
  program?: Program;
  program_id?: TProgramId;
  utility: Utility;
  utility_id: TUtilityId;
  equipment?: Equipment[];
  num_equipment_per_category: EquipmentByCategory[];
}

export interface FacilityAddress {
  name: string;
  hq_address_line1: string;
  hq_address_line2: Nullable<string>;
  hq_address_city: string;
  hq_address_region: string;
  hq_address_country: string;
  hq_address_post_code: string;
  hq_address_latitude: Nullable<string>;
  hq_address_longitude: Nullable<string>;
}
