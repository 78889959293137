import { Route } from 'react-router-dom';
import { ListModelAuditLogsPage } from './list-model-audit-logs';
import { ModelAuditLogDetailsPage } from './model-audit-log-details';
import { UseActiveClient, RequireAuth } from '/src/components';
import { RoleTypes } from '/src/lib/models';

export { ListModelAuditLogsPage, ModelAuditLogDetailsPage };

export const modelAuditLogRoutes: React.ReactElement[] = [
  // Model Audit Log Details
  <Route
    path="/change-logs/:model_audit_log_id"
    element={
      <RequireAuth allowedRoles={[RoleTypes.FuseAdmin]}>
        <UseActiveClient>
          <ModelAuditLogDetailsPage />
        </UseActiveClient>
      </RequireAuth>
    }
  />,

  // List Model Audit Logs
  <Route
    path="/change-logs"
    element={
      <RequireAuth allowedRoles={[RoleTypes.FuseAdmin]}>
        <UseActiveClient>
          <ListModelAuditLogsPage />
        </UseActiveClient>
      </RequireAuth>
    }
  />,
];
