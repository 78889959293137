import { Box, Text } from 'src/components/common';

export const CommissionIcon: React.FC<CommissionIconProps> = ({ size = '24px', color = 'brand' }) => (
  <Box width={size} align="center">
    <Text size={size} color={color} weight={700} fontFamily="Lato, sans-serif">
      %
    </Text>
  </Box>
);

export type CommissionIconProps = {
  size?: string;
  color?: string;
};
