import * as Sentry from '@sentry/react';
import { PropsWithChildren } from 'react';
import { ApiError } from '/src/api';
import { RootErrorPage } from '/src/components';
import { config } from '/src/config';

export const RootErrorBoundary: React.FC<PropsWithChildren> = ({ children }) => {
  const handleError = (error: ApiError, stack?: string) => {
    if (config.nodeEnv === 'development') {
      console.error(`Caught error at root error boundary: %O\nStack trace: %O`, error, stack);
    }
  };

  return (
    <Sentry.ErrorBoundary
      fallback={({ error, resetError }) => <RootErrorPage error={error} resetErrorBoundary={resetError} />}
      onError={(error, componentStack) => handleError(error as ApiError, componentStack)}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};
