import { observer } from 'mobx-react-lite';
import { useMemo, useState } from 'react';
import { useSearchStore } from '../context';
import { Client, Equipment, Facility, User } from '../lib/models';
import { ModelType } from '../lib/types';
import {
  SearchEntitySelect,
  SearchFieldSelect,
  SearchQueryInput,
  SearchResultsBar,
  Box,
  Card,
  CardBody,
  ClientList,
  EquipmentList,
  FacilityList,
  ListPage,
  PageHeader,
  Text,
  UserList,
} from '/src/components';

const SearchPage = observer(() => {
  const { searchEntity, searchField, results, resultsMetadata, currentPage, isSearching, search, setCurrentPage } =
    useSearchStore();

  const isLoading = useMemo(() => false, []);

  return (
    <ListPage title="Search Results" pageHeader={<PageHeader title="Search" />} isLoading={isLoading}>
      <Box fill="horizontal">
        <Card>
          <CardBody pad={{ horizontal: 'none', top: 'medium' }} gap="medium">
            <Box direction="row" pad={{ horizontal: 'medium' }} gap="small">
              <SearchEntitySelect labelSize="1rem" labelWeight={400} fill="horizontal" />
              <SearchFieldSelect labelSize="1rem" labelWeight={400} fill="horizontal" />
            </Box>
            {!!searchEntity && !!searchField && (
              <Box animation={{ type: 'slideDown', duration: 300 }} pad={{ horizontal: 'medium' }}>
                <SearchQueryInput fill="horizontal" />
              </Box>
            )}
            <SearchResultsBar pad={{ horizontal: 'medium' }} />
            {!!results && !resultsMetadata?.total && (
              <Box
                pad={{ vertical: 'medium' }}
                background="light-6"
                justify="center"
                border={{ side: 'top', color: 'brand', size: '3px' }}
              >
                <Text alignSelf="center" size="medium" fontFamily="Lato, sans-serif">
                  No results found.
                </Text>
              </Box>
            )}
            {!!results && !!resultsMetadata?.total && (
              <>
                {searchEntity === ModelType.Clients && (
                  <ClientList
                    clients={results as Client[]}
                    fetchClients={search}
                    metadata={resultsMetadata}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    isLoading={isSearching}
                    hideHeader
                    border={{ side: 'top', color: 'brand', size: '3px' }}
                    round="0"
                    elevation="none"
                  />
                )}
                {searchEntity === ModelType.Facilities && (
                  <FacilityList
                    clientId={NaN}
                    facilities={results as Facility[]}
                    fetchFacilities={search}
                    metadata={resultsMetadata}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    isLoading={isSearching}
                    hideHeader
                    border={{ side: 'top', color: 'brand', size: '3px' }}
                    round="0"
                    elevation="none"
                  />
                )}
                {searchEntity === ModelType.Equipment && (
                  <EquipmentList
                    clientId={NaN}
                    facilityId={NaN}
                    equipment={results as Equipment[]}
                    fetchEquipment={search}
                    metadata={resultsMetadata}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    isLoading={isSearching}
                    hideHeader
                    border={{ side: 'top', color: 'brand', size: '3px' }}
                    round="0"
                    elevation="none"
                  />
                )}
                {searchEntity === ModelType.Users && (
                  <UserList
                    users={results as User[]}
                    fetchUsers={search}
                    metadata={resultsMetadata}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    isLoading={isSearching}
                    hideHeader
                    border={{ side: 'top', color: 'brand', size: '3px' }}
                    round="0"
                    elevation="none"
                  />
                )}
              </>
            )}
          </CardBody>
        </Card>
      </Box>
    </ListPage>
  );
});

export default SearchPage;
