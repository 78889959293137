import { TextInput, Tip } from 'grommet';
import { SettingsOption } from 'grommet-icons';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ApiError } from '/src/api';
import {
  AddButton,
  Box,
  BoxProps,
  Card,
  CardBody,
  CardHeader,
  EquipmentUsageIcon,
  Line,
  Link,
  LoadingSpinner,
  Text,
} from '/src/components';
import { useEquipmentStore, useEquipmentUsageStore, useGlobalStore, useUserStore } from '/src/context';
import { Equipment, EquipmentUsage } from '/src/lib/models';
import { toastMessages } from '/src/lib/toast';
import { CountryId } from '/src/lib/types';
import { getPeriodFromDateString, getPeriodRange } from '/src/utils';

export const EquipmentUsagesList: React.FC<EquipmentUsagesListProps> = observer((props) => {
  const { equipment, equipmentUsagesList, ...boxProps } = props;

  /** Context **/
  const userStore = useUserStore();
  const globalStore = useGlobalStore();
  const equipmentStore = useEquipmentStore();
  const equipmentUsageStore = useEquipmentUsageStore();

  /** State **/
  const [isLoading, setIsLoading] = useState(true);
  const [isUpdatingCurrent, setIsUpdatingCurrent] = useState(false);
  const [isUpdatingPrevious, setIsUpdatingPrevious] = useState(false);

  const [currentValues, setCurrentValues] = useState<Record<string, string>>({
    charge_cycles_per_shift: '',
    shifts_per_day: '',
    work_days_per_quarter: '',
    percent_charger_efficiency_rating: '',
    percent_charge_return_factor: '',
    percent_depth_of_discharge: '',
  });
  const [currentErrors, setCurrentErrors] = useState<Record<string, string>>({});

  const [previousValues, setPreviousValues] = useState<Record<string, string>>({
    charge_cycles_per_shift: '',
    shifts_per_day: '',
    work_days_per_quarter: '',
    percent_charger_efficiency_rating: '',
    percent_charge_return_factor: '',
    percent_depth_of_discharge: '',
  });
  const [previousErrors, setPreviousErrors] = useState<Record<string, string>>({});

  /** Computed **/
  const currentUsage = equipmentUsagesList?.[0];
  const previousUsage = equipmentUsagesList?.[1];
  const historicalUsage = equipmentUsagesList?.slice(2);
  const isCanada = equipment?.facility?.address_region?.country_id === CountryId.Canada;

  /** Methods **/
  const getIsEditable = (usage: EquipmentUsage) =>
    userStore.isExternalUser
      ? !usage.is_finalized && !usage.client_reporting_period.is_client_locked
      : !usage.is_finalized;

  const updateCurrentFormValue = (key: string, value: any) => {
    setCurrentValues({
      ...currentValues,
      [key]: value,
    });
    if (currentErrors[key]) currentErrors[key] = '';
  };

  const updatePreviousFormValue = (key: string, value: any) => {
    setPreviousValues({
      ...previousValues,
      [key]: value,
    });
    if (previousErrors[key]) previousErrors[key] = '';
  };

  const updateCurrentUsage = async () => {
    if (!currentUsage) return;

    try {
      setIsUpdatingCurrent(true);

      const newUsages = {
        equipment_usage_id: currentUsage.id,
        charge_cycles_per_shift: currentValues.charge_cycles_per_shift
          ? parseFloat(parseFloat(currentValues.charge_cycles_per_shift).toFixed(2))
          : 0,
        shifts_per_day: currentValues.shifts_per_day
          ? parseFloat(parseFloat(currentValues.shifts_per_day).toFixed(2))
          : 0,
        work_days_per_quarter: currentValues.work_days_per_quarter
          ? parseFloat(parseFloat(currentValues.work_days_per_quarter).toFixed(2))
          : 0,
        percent_charger_efficiency_rating: currentValues.percent_charger_efficiency_rating
          ? parseInt(currentValues.percent_charger_efficiency_rating)
          : 0,
        percent_charge_return_factor: currentValues.percent_charge_return_factor
          ? parseInt(currentValues.percent_charge_return_factor)
          : 0,
        percent_depth_of_discharge: currentValues.percent_depth_of_discharge
          ? parseInt(currentValues.percent_depth_of_discharge)
          : 0,
      };
      await equipmentUsageStore.updateUnmeteredEquipmentUsage(newUsages);
      toast.success(toastMessages.updateEquipmentUsage.success);
    } catch (err) {
      globalStore.handleApiError(err as ApiError, toastMessages.updateEquipmentUsage.error);
    } finally {
      setIsUpdatingCurrent(false);
    }
  };

  const updatePreviousUsage = async () => {
    if (!previousUsage) return;

    try {
      setIsUpdatingPrevious(true);

      const newUsages = {
        equipment_usage_id: previousUsage.id,
        charge_cycles_per_shift: previousValues.charge_cycles_per_shift
          ? parseFloat(parseFloat(previousValues.charge_cycles_per_shift).toFixed(2))
          : 0,
        shifts_per_day: previousValues.shifts_per_day
          ? parseFloat(parseFloat(previousValues.shifts_per_day).toFixed(2))
          : 0,
        work_days_per_quarter: previousValues.work_days_per_quarter
          ? parseFloat(parseFloat(previousValues.work_days_per_quarter).toFixed(2))
          : 0,
        percent_charger_efficiency_rating: previousValues.percent_charger_efficiency_rating
          ? parseInt(previousValues.percent_charger_efficiency_rating)
          : 0,
        percent_charge_return_factor: previousValues.percent_charge_return_factor
          ? parseInt(previousValues.percent_charge_return_factor)
          : 0,
        percent_depth_of_discharge: previousValues.percent_depth_of_discharge
          ? parseInt(previousValues.percent_depth_of_discharge)
          : 0,
      };
      await equipmentUsageStore.updateUnmeteredEquipmentUsage(newUsages);
      toast.success(toastMessages.updateEquipmentUsage.success);
    } catch (err) {
      globalStore.handleApiError(err as ApiError, toastMessages.updateEquipmentUsage.error);
    } finally {
      setIsUpdatingPrevious(false);
    }
  };

  /** Effects **/
  useEffect(() => {
    if (isLoading && !!equipmentUsagesList?.length) {
      setCurrentValues({
        ...currentValues,
        charge_cycles_per_shift: equipmentUsagesList[0].charge_cycles_per_shift ?? '',
        shifts_per_day: equipmentUsagesList[0].shifts_per_day ?? '',
        work_days_per_quarter: equipmentUsagesList[0].work_days_per_quarter ?? '',
        percent_charger_efficiency_rating: equipmentUsagesList[0].percent_charger_efficiency_rating?.toString() ?? '',
        percent_charge_return_factor: equipmentUsagesList[0].percent_charge_return_factor?.toString() ?? '',
        percent_depth_of_discharge: equipmentUsagesList[0].percent_depth_of_discharge?.toString() ?? '',
      });
      setPreviousValues({
        ...previousValues,
        charge_cycles_per_shift: equipmentUsagesList[1]?.charge_cycles_per_shift ?? '',
        shifts_per_day: equipmentUsagesList[1]?.shifts_per_day ?? '',
        work_days_per_quarter: equipmentUsagesList[1]?.work_days_per_quarter ?? '',
        percent_charger_efficiency_rating: equipmentUsagesList[1]?.percent_charger_efficiency_rating?.toString() ?? '',
        percent_charge_return_factor: equipmentUsagesList[1]?.percent_charge_return_factor?.toString() ?? '',
        percent_depth_of_discharge: equipmentUsagesList[1]?.percent_depth_of_discharge?.toString() ?? '',
      });
      setIsLoading(false);
    }
  }, [isLoading, currentValues, previousValues, equipmentUsagesList]);

  /** Render **/
  const renderDataTable = (
    formValues: Record<string, string>,
    updateFormValue: (key: string, value: string) => void,
    equipmentUsages: EquipmentUsage[],
    showQuarterColumn?: boolean,
    isEditable?: boolean
  ) => {
    const quarterColumnWidth = 8;
    const leftoverWidth = 100 - quarterColumnWidth;
    const width = showQuarterColumn ? `${leftoverWidth / 6}%` : `${100 / 6}%`;

    const rowLabels = [
      'Charge Cycles / Shift',
      'Shifts / Day',
      'Work Days / Quarter',
      'Charger Efficiency Rating',
      'Charge Return Factor',
      'Depth of Discharge',
    ];

    const renderHeader = (rowLabels: string[], showQuarterColumn?: boolean) => (
      <Box row>
        <Box width="3rem" align="center" border={{ side: 'all', size: '0.4px', color: 'light-2' }} pad="small" />
        {showQuarterColumn && (
          <Box
            width={`${quarterColumnWidth}%`}
            align="center"
            border={{ side: 'all', size: '0.4px', color: 'light-2' }}
            pad="small"
          >
            <Text size="small" weight={300} toUpperCase>
              Period
            </Text>
          </Box>
        )}
        {rowLabels.map((label) => (
          <Box
            key={label}
            width={width}
            align="center"
            border={{ side: 'all', size: '0.4px', color: 'light-2' }}
            pad="small"
          >
            <Text size="small" weight={300} toUpperCase>
              {label}
            </Text>
          </Box>
        ))}
      </Box>
    );

    const renderEditRow = (equipmentUsage: EquipmentUsage) => (
      <Box row background="light-6">
        <Box
          width="3rem"
          justify="center"
          align="center"
          border={{ side: 'all', size: '0.4px', color: 'light-2' }}
          pad="small"
        >
          <Link to={`/clients/${equipment?.client_id}/equipment/${equipment?.id}/usages/${equipmentUsage.id}`}>
            <Box align="center" justify="center">
              <Tip
                content={`View ${getPeriodFromDateString(
                  equipmentUsage.start_reporting_quarter,
                  isCanada
                )} Usage Details`}
              >
                <SettingsOption size="20px" color="accent-1" />
              </Tip>
            </Box>
          </Link>
        </Box>
        {showQuarterColumn && (
          <Box
            width={`${quarterColumnWidth}%`}
            align="center"
            border={{ side: 'all', size: '0.4px', color: 'light-2' }}
            pad="small"
          >
            <Text toUpperCase weight={500}>
              {getPeriodFromDateString(equipmentUsage.start_reporting_quarter, isCanada)}
            </Text>
          </Box>
        )}
        {['charge_cycles_per_shift', 'shifts_per_day', 'work_days_per_quarter'].map((valueKey, index) => (
          <Box
            key={index}
            width={width}
            align="center"
            border={{ side: 'all', size: '0.4px', color: 'light-2' }}
            pad="small"
            gap="xsmall"
          >
            <TextInput
              name={valueKey}
              value={formValues[valueKey]}
              type="number"
              textAlign="center"
              onChange={(e) => (valueKey ? updateFormValue(valueKey, e.target.value) : undefined)}
              style={{ background: 'white', paddingTop: '8px', paddingBottom: '8px' }}
            />
          </Box>
        ))}
        {['percent_charger_efficiency_rating', 'percent_charge_return_factor', 'percent_depth_of_discharge'].map(
          (valueKey, index) => (
            <Box
              key={index}
              width={width}
              align="center"
              border={{ side: 'all', size: '0.4px', color: 'light-2' }}
              pad={{ vertical: 'small', horizontal: 'medium' }}
              gap="xsmall"
              row
            >
              <TextInput
                name={valueKey}
                value={formValues[valueKey]}
                type="number"
                inputMode="numeric"
                textAlign="center"
                onChange={(e) => (valueKey ? updateFormValue(valueKey, e.target.value) : undefined)}
                style={{ background: 'white', paddingTop: '8px', paddingBottom: '8px' }}
              />
              <Text>%</Text>
            </Box>
          )
        )}
      </Box>
    );

    const renderRow = (equipmentUsage: EquipmentUsage, rowIndex: number) => {
      const renderCell = (value: string | number, index: number, isPercent?: boolean, cellWidth?: string) => (
        <Box
          key={index}
          width={cellWidth || width}
          align="center"
          border={{ side: 'all', size: '0.4px', color: 'light-2' }}
          pad="small"
        >
          <Text toUpperCase>
            {value}
            {isPercent && '%'}
          </Text>
        </Box>
      );
      const row = [
        renderCell(equipmentUsage.charge_cycles_per_shift ?? '-', 0, false),
        renderCell(equipmentUsage.shifts_per_day ?? '-', 1, false),
        renderCell(equipmentUsage.work_days_per_quarter ?? '-', 2, false),
        renderCell(equipmentUsage.percent_charger_efficiency_rating ?? '-', 3, true),
        renderCell(equipmentUsage.percent_charge_return_factor ?? '-', 4, true),
        renderCell(equipmentUsage.percent_depth_of_discharge ?? '-', 5, true),
      ];

      return (
        <Box key={rowIndex} row background={rowIndex % 2 ? 'white' : 'light-6'}>
          <Box
            width="3rem"
            justify="center"
            align="center"
            border={{ side: 'all', size: '0.4px', color: 'light-2' }}
            pad="small"
          >
            <Link to={`/clients/${equipment?.client_id}/equipment/${equipment?.id}/usages/${equipmentUsage.id}`}>
              <Box align="center" justify="center">
                <Tip
                  content={`View ${getPeriodFromDateString(
                    equipmentUsage.start_reporting_quarter,
                    isCanada
                  )} Usage Details`}
                >
                  <SettingsOption size="20px" color="accent-1" />
                </Tip>
              </Box>
            </Link>
          </Box>
          {showQuarterColumn && (
            <Box
              width={`${quarterColumnWidth}%`}
              align="center"
              border={{ side: 'all', size: '0.4px', color: 'light-2' }}
              pad="small"
            >
              <Text toUpperCase weight={500}>
                {getPeriodFromDateString(equipmentUsage.start_reporting_quarter, isCanada)}
              </Text>
            </Box>
          )}
          {row}
        </Box>
      );
    };

    return (
      <Box border={{ side: 'all', size: '0.4px', color: 'light-2' }}>
        {/* Header */}
        {renderHeader(rowLabels, showQuarterColumn)}

        {/* Current Usage */}
        {isEditable && renderEditRow(equipmentUsages[0])}

        {/* Historical Usages */}
        {!isEditable && equipmentUsages.map(renderRow)}
      </Box>
    );
  };

  return (
    <Card {...boxProps}>
      <CardHeader title="Usage" icon={<EquipmentUsageIcon />}></CardHeader>
      <CardBody pad={!isLoading && !currentUsage && !previousUsage && !historicalUsage?.length ? 'none' : undefined}>
        {!isLoading ? (
          <Box gap="xsmall">
            {currentUsage && (
              <Box gap="medium">
                <Box row justify="between" align="end">
                  <Box gap="xsmall" justify="center">
                    <Box row gap="xsmall">
                      <Text size="xlarge" weight={500}>
                        {equipment?.last_active_reporting_quarter === currentUsage.start_reporting_quarter
                          ? 'Retirement'
                          : 'Latest'}{' '}
                        Period:
                      </Text>
                      <Text size="xlarge" weight={500} color="accent-1">
                        {getPeriodFromDateString(currentUsage.start_reporting_quarter, isCanada)}
                      </Text>
                    </Box>
                    <Box>
                      <Text size="medium" weight={300}>
                        {getPeriodRange(currentUsage.start_reporting_quarter, isCanada)}
                      </Text>
                    </Box>
                  </Box>
                  {getIsEditable(currentUsage) && (
                    <AddButton
                      background="accent-1"
                      color="white"
                      label={`SAVE ${getPeriodFromDateString(currentUsage.start_reporting_quarter, isCanada)} USAGE`}
                      tip={`Save changes to ${getPeriodFromDateString(currentUsage.start_reporting_quarter, isCanada)}`}
                      disabled={isUpdatingCurrent}
                      onClick={() => updateCurrentUsage()}
                    />
                  )}
                </Box>
                {renderDataTable(
                  currentValues,
                  updateCurrentFormValue,
                  [currentUsage],
                  false,
                  getIsEditable(currentUsage)
                )}
              </Box>
            )}

            {currentUsage && previousUsage && <Line />}

            {previousUsage && (
              <Box gap="medium">
                <Box row justify="between" align="end">
                  <Box gap="xsmall" justify="center">
                    <Box row gap="xsmall">
                      <Text size="xlarge" weight={500}>
                        Previous Period:
                      </Text>
                      <Text size="xlarge" weight={500} color="accent-1">
                        {getPeriodFromDateString(previousUsage.start_reporting_quarter, isCanada)}
                      </Text>
                    </Box>
                    <Box>
                      <Text size="medium" weight={300}>
                        {getPeriodRange(previousUsage.start_reporting_quarter, isCanada)}
                      </Text>
                    </Box>
                  </Box>
                  {getIsEditable(previousUsage) && (
                    <AddButton
                      background="accent-1"
                      color="white"
                      label={`SAVE ${getPeriodFromDateString(previousUsage.start_reporting_quarter, isCanada)} USAGE`}
                      tip={`Save changes to ${getPeriodFromDateString(
                        previousUsage.start_reporting_quarter,
                        isCanada
                      )}`}
                      disabled={isUpdatingPrevious}
                      onClick={() => updatePreviousUsage()}
                    />
                  )}
                </Box>
                {renderDataTable(
                  previousValues,
                  updatePreviousFormValue,
                  [previousUsage],
                  false,
                  getIsEditable(previousUsage)
                )}
              </Box>
            )}

            {previousUsage && !!historicalUsage?.length && <Line />}

            {!!historicalUsage?.length && (
              <Box gap="medium">
                <Box gap="xsmall" justify="end">
                  <Text size="xlarge" weight={500}>
                    Historical Data
                  </Text>
                  <Text weight={300}>This data has already been submitted.</Text>
                </Box>
                {renderDataTable({}, () => {}, historicalUsage, true)}
              </Box>
            )}

            {!currentUsage && !previousUsage && !historicalUsage?.length && (
              <Box pad={{ horizontal: '1.5rem', vertical: '2rem' }} background="light-6" justify="center">
                <Text alignSelf="center" size="medium" fontFamily="Lato, sans-serif">
                  No usage data found.
                </Text>
              </Box>
            )}
          </Box>
        ) : (
          <LoadingSpinner />
        )}
      </CardBody>
    </Card>
  );
});

export type EquipmentUsagesListProps = BoxProps & {
  equipment?: Equipment;
  equipmentUsagesList?: EquipmentUsage[];
};
