import { Box, Keyboard, Select, Text } from 'grommet';
import { CircleAlert, StatusGood } from 'grommet-icons';
import React, { useState } from 'react';
import { UserEmailVerificationStatus } from '/src/lib/models';

export const EmailVerificationSelect: React.FC<EmailVerificationSelectProps> = (props) => {
  const { isEmailVerified, userCanEdit, setIsEmailVerified, required, onSubmit } = props;

  const [isFocused, setIsFocused] = useState(false);

  return (
    <Box border={{ side: 'bottom', size: 'small', color: isFocused ? 'accent-1' : 'transparent' }}>
      <Keyboard onEnter={onSubmit}>
        <Select
          id="email-verification-input"
          required={required}
          plain
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          value={isEmailVerified ? UserEmailVerificationStatus.Verified : UserEmailVerificationStatus.Unverified}
          options={[UserEmailVerificationStatus.Verified, UserEmailVerificationStatus.Unverified]}
          style={{ fontSize: '1rem', fontFamily: 'Lato, sans-serif', fontWeight: 400 }}
          disabled={!userCanEdit}
          onChange={({ value: nextStatus }) =>
            setIsEmailVerified(nextStatus === UserEmailVerificationStatus.Verified ? true : false)
          }
          valueLabel={
            <Box direction="row">
              <Box alignSelf="center">
                {isEmailVerified ? <StatusGood color="green" size="16px" /> : <CircleAlert size="16px" />}
              </Box>
              <Text
                alignSelf="center"
                margin={{ left: '0.25rem' }}
                size="1rem"
                style={{ fontFamily: 'Lato, sans-serif' }}
              >
                {isEmailVerified ? UserEmailVerificationStatus.Verified : UserEmailVerificationStatus.Unverified}
              </Text>
            </Box>
          }
        />
      </Keyboard>
    </Box>
  );
};

export type EmailVerificationSelectProps = {
  isEmailVerified: boolean;
  userCanEdit: boolean;
  setIsEmailVerified: (isEmailVerified: boolean) => void;
  required?: boolean;
  onSubmit?: (event: React.KeyboardEvent<HTMLElement>) => void;
};
