import { Client } from '.';
import { DatabaseEntity } from './base';

export type TClientFileId = number;

export enum ClientFileType {
  PDF = 'pdf',
  DOC = 'doc',
  DOCX = 'docx',
  CSV = 'csv',
  TXT = 'txt',
  XLS = 'xls',
  XLSX = 'xlsx',
}

export interface ClientFile {
  id: TClientFileId;
  client: Client;
  name: string;
  type: ClientFileType;
  file: Record<any, any>;
}
