import { Route } from 'react-router-dom';
import { CreateUserPage } from './create-user';
import { ListUsersPage } from './list-users';
import { UserDetailsPage } from './user-details';
import { UseActiveClient, RequireAuth } from '/src/components';
import { AdminRoles, InternalRoles, RoleTypes } from '/src/lib/models';

export { CreateUserPage, ListUsersPage, UserDetailsPage };

export const userRoutes: React.ReactElement[] = [
  // List Users
  <Route
    path="/users"
    element={
      <RequireAuth allowedRoles={InternalRoles}>
        <UseActiveClient>
          <ListUsersPage />
        </UseActiveClient>
      </RequireAuth>
    }
  />,

  // User Details
  <Route
    path="/users/:user_id"
    element={
      <RequireAuth isUserRoute>
        <UseActiveClient>
          <UserDetailsPage />
        </UseActiveClient>
      </RequireAuth>
    }
  />,

  // Create User
  <Route
    path="/users/create"
    element={
      <RequireAuth allowedRoles={[...InternalRoles, RoleTypes.ClientAdmin]}>
        <UseActiveClient>
          <CreateUserPage />
        </UseActiveClient>
      </RequireAuth>
    }
  />,

  // Create Facility User
  <Route
    path="/clients/:client_id/facilities/:facility_id/users/create"
    element={
      <RequireAuth allowedRoles={[...InternalRoles, RoleTypes.ClientAdmin]}>
        <UseActiveClient>
          <CreateUserPage />
        </UseActiveClient>
      </RequireAuth>
    }
  />,

  // Client User Details
  <Route
    path="/clients/:client_id/users/:user_id"
    element={
      <RequireAuth isUserRoute allowedRoles={AdminRoles}>
        <UseActiveClient>
          <UserDetailsPage />
        </UseActiveClient>
      </RequireAuth>
    }
  />,

  // Create Client User
  <Route
    path="/clients/:client_id/users/create"
    element={
      <RequireAuth allowedRoles={[...InternalRoles, RoleTypes.ClientAdmin]}>
        <UseActiveClient>
          <CreateUserPage />
        </UseActiveClient>
      </RequireAuth>
    }
  />,

  // List Client Users
  <Route
    path="/clients/:client_id/users"
    element={
      <RequireAuth allowedRoles={[...InternalRoles, RoleTypes.ClientAdmin]}>
        <UseActiveClient>
          <ListUsersPage />
        </UseActiveClient>
      </RequireAuth>
    }
  />,
];
