import { makeObservable, observable, runInAction } from 'mobx';
import {
  ApiError,
  ClientService,
  EquipmentService,
  FacilityService,
  TListAllEquipmentRequest,
  TListAllFacilitiesRequest,
  TListClientsRequest,
  TListUsersRequest,
  UserService,
} from '/src/api';
import BaseStore from '/src/context/stores/baseStore';
import RootStore from '/src/context/stores/rootStore';
import { Client, Equipment, Facility, User } from '/src/lib/models';
import { toastMessages } from '/src/lib/toast';
import { EntityStatus, MeteredStatus, ModelType, SelectOption, TResponseMetadata } from '/src/lib/types';

export default class SearchStore extends BaseStore {
  isSearching = false;
  error?: ApiError;

  results?: (Client | Facility | Equipment | User)[];
  resultsMetadata?: TResponseMetadata;
  currentPage = 1;
  limit = 20;

  /** Select Entity **/
  entityFilter = '';
  isEntityFocused = false;
  searchEntity?: ModelType;

  /** Select Field **/
  fieldFilter = '';
  isFieldFocused = false;
  searchField?: string;

  /** Select Query **/
  isQueryFocused = false;
  searchOption?: SelectOption<string | number>;
  searchQuery = '';

  fields: Record<string, SelectOption<string>[]> = {
    Clients: [{ label: 'Name', value: 'name' }],
    Facilities: [
      { label: 'Name', value: 'name' },
      { label: 'Street Address', value: 'address_line1' },
      { label: 'City', value: 'address_city' },
      { label: 'Postal Code', value: 'address_post_code' },
      { label: 'Program', value: 'program_id' },
      { label: 'Utility', value: 'utility_id' },
      { label: 'Active Status', value: 'is_active' },
    ],
    Equipment: [
      { label: 'Unit Number', value: 'unit_number' },
      { label: 'Serial Number', value: 'serial_number' },
      { label: 'Equipment Type', value: 'equipment_type_id' },
      { label: 'Model Year', value: 'model_year' },
      { label: 'Manufacturer', value: 'manufacturer' },
      { label: 'Model Number', value: 'model_number' },
      { label: 'Active Status', value: 'is_active' },
      { label: 'Metered Status', value: 'is_metered' },
      { label: 'Fuel Pathway', value: 'fuel_pathway_id' },
      { label: 'FSE Registration Status', value: 'fse_registration_status_id' },
      { label: 'FSE ID', value: 'fse_id' },
      { label: 'FSE RU', value: 'fse_ru' },
    ],
    Users: [
      { label: 'Name', value: 'name' },
      { label: 'Role', value: 'role_id' },
      { label: 'Active Status', value: 'is_active' },
    ],
  };

  selectFields = [
    'program_id',
    'utility_id',
    'is_active',
    'equipment_type_id',
    'is_active',
    'is_metered',
    'fuel_pathway_id',
    'fse_registration_status_id',
    'role_id',
    'is_active',
  ];

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      isSearching: observable,
      error: observable,
      results: observable,
      resultsMetadata: observable,
      currentPage: observable,
      limit: observable,
      searchEntity: observable,
      searchField: observable,
      searchOption: observable,
      entityFilter: observable,
      fieldFilter: observable,
      searchQuery: observable,
      isEntityFocused: observable,
      isFieldFocused: observable,
      isQueryFocused: observable,
    });
  }

  setIsSearching = (isSearching: boolean) => {
    runInAction(() => {
      this.isSearching = isSearching;
    });
  };

  setError = (error: ApiError) => {
    runInAction(() => {
      this.error = error;
    });
  };

  setResults = (results?: (Client | Facility | Equipment | User)[]) => {
    runInAction(() => {
      this.results = results;
    });
  };

  setResultsMetadata = (resultsMetadata?: TResponseMetadata) => {
    runInAction(() => {
      this.resultsMetadata = resultsMetadata;
    });
  };

  /** Select Entity **/
  setSearchEntity = (searchEntity?: ModelType) => {
    runInAction(() => {
      this.searchEntity = searchEntity;
      this.setSearchField('');
      this.clear();
    });
  };

  setSearchField = (searchField?: string) => {
    runInAction(() => {
      this.searchField = searchField;
      this.setSearchQuery('');
      this.clear();
      this.setSearchOption({ label: '', value: NaN });
    });
  };

  setSearchOption = (searchOption?: SelectOption<string | number>) => {
    runInAction(() => {
      this.searchOption = searchOption;
    });
  };

  setEntityFilter = (entityFilter: string) => {
    runInAction(() => {
      this.entityFilter = entityFilter;
    });
  };

  setFieldFilter = (fieldFilter: string) => {
    runInAction(() => {
      this.fieldFilter = fieldFilter;
    });
  };

  setSearchQuery = (searchQuery: string) => {
    runInAction(() => {
      this.searchQuery = searchQuery;
    });
  };

  setIsEntityFocused = (isEntityFocused: boolean) => {
    runInAction(() => {
      this.isEntityFocused = isEntityFocused;
    });
  };

  setIsFieldFocused = (isFieldFocused: boolean) => {
    runInAction(() => {
      this.isFieldFocused = isFieldFocused;
    });
  };

  setIsQueryFocused = (isQueryFocused: boolean) => {
    runInAction(() => {
      this.isQueryFocused = isQueryFocused;
    });
  };

  setCurrentPage = async (currentPage: number) => {
    runInAction(() => {
      this.currentPage = currentPage;
    });
  };

  setLimit = async (limit: number) => {
    runInAction(() => {
      this.limit = limit;
    });
  };

  search = async (currentPage?: number) => {
    const userStore = this.rootStore.userStore;
    const clientStore = this.rootStore.clientStore;

    if (!userStore.user || (userStore.isExternalUser && !clientStore.activeClientId)) {
      if (!userStore.user) console.warn('No User found');
      if (userStore.isExternalUser && !clientStore.activeClientId)
        console.warn('User is external but has no `activeClientId`');
      return;
    }

    if (this.isSearching || !this.searchEntity || !this.searchField || (!this.searchQuery && !this.searchOption?.value))
      return;

    const field = this.fields[this.searchEntity]?.find((f) => f.label === this.searchField);
    if (!field) return;

    const isSelectField = this.selectFields.includes(field.value.toLowerCase());

    if (!isSelectField && !this.searchQuery) return;
    else if (isSelectField && !this.searchOption?.value) return;

    const page = currentPage ?? this.currentPage;

    this.setResults(undefined);
    this.setResultsMetadata(undefined);
    this.setIsSearching(true);

    let resultsMetadata: TResponseMetadata;
    let results: any[];

    try {
      // Client Search
      if (this.searchEntity === ModelType.Clients) {
        const req: TListClientsRequest = { page };
        if (field.value === 'name') req.name = this.searchQuery;
        const { meta, data } = await ClientService.list(req);
        resultsMetadata = meta;
        results = [...(this.results ?? []), ...data];
      }

      // Facility Search
      else if (this.searchEntity === ModelType.Facilities) {
        const req: TListAllFacilitiesRequest = { page };
        if (field.value === 'name') req.name = this.searchQuery;
        else if (field.value === 'address_line1') req.address_line1 = this.searchQuery;
        else if (field.value === 'address_city') req.address_city = this.searchQuery;
        else if (field.value === 'address_post_code') req.address_post_code = this.searchQuery;
        if (this.searchOption?.value) {
          if (field.value === 'program_id')
            req.program_id =
              typeof this.searchOption?.value === 'string'
                ? parseInt(this.searchOption.value)
                : this.searchOption?.value;
          else if (field.value === 'utility_id')
            req.utility_id =
              typeof this.searchOption?.value === 'string'
                ? parseInt(this.searchOption.value)
                : this.searchOption?.value;
          else if (field.value === 'is_active') req.is_active = this.searchOption?.value === EntityStatus.Active;
        }

        const { meta, data } = userStore.isInternalUser
          ? await FacilityService.listAll(req)
          : await FacilityService.list({ client_id: clientStore.activeClientId!, ...req });

        resultsMetadata = meta;
        results = [...(this.results ?? []), ...data];
      }

      // Equipment Search
      else if (this.searchEntity === ModelType.Equipment) {
        const req: TListAllEquipmentRequest = { page };
        if (field.value === 'equipment_type_id')
          req.equipment_type_id =
            typeof this.searchOption?.value === 'string' ? parseInt(this.searchOption.value) : this.searchOption?.value;
        if (field.value === 'fuel_pathway_id')
          req.fuel_pathway_id =
            typeof this.searchOption?.value === 'string' ? parseInt(this.searchOption.value) : this.searchOption?.value;
        if (field.value === 'unit_number') req.unit_number = this.searchQuery;
        if (field.value === 'serial_number') req.serial_number = this.searchQuery;
        if (field.value === 'manufacturer') req.manufacturer = this.searchQuery;
        if (field.value === 'model_number') req.model_number = this.searchQuery;
        if (field.value === 'model_year') req.model_year = parseInt(this.searchQuery);
        if (field.value === 'fse_registration_status_id')
          req.fse_registration_status_id =
            typeof this.searchOption?.value === 'string' ? parseInt(this.searchOption.value) : this.searchOption?.value;
        if (field.value === 'fse_id') req.fse_id = this.searchQuery;
        if (field.value === 'fse_ru') req.fse_ru = this.searchQuery;
        else if (field.value === 'is_active') req.is_active = this.searchOption?.value === EntityStatus.Active;
        else if (field.value === 'is_metered') req.is_metered = this.searchOption?.value === MeteredStatus.Metered;

        const { meta, data } = userStore.isInternalUser
          ? await EquipmentService.listAll(req)
          : await EquipmentService.listByClient({ client_id: clientStore.activeClientId!, ...req });

        resultsMetadata = meta;
        results = [...(this.results ?? []), ...data];
      }

      // User Search
      else if (this.searchEntity === ModelType.Users) {
        const req: TListUsersRequest = { page };
        if (field.value === 'name') req.name = this.searchQuery;
        if (field.value === 'role_id')
          req.role_id =
            typeof this.searchOption?.value === 'string' ? parseInt(this.searchOption.value) : this.searchOption?.value;

        const { meta, data } = userStore.isInternalUser
          ? await UserService.list(req)
          : await ClientService.listClientUsers({ id: clientStore.activeClientId!, ...req });

        resultsMetadata = meta;
        results = [...(this.results ?? []), ...data];
      }

      runInAction(() => {
        this.setResultsMetadata(resultsMetadata);
        this.setCurrentPage(resultsMetadata.current_page);
        this.setResults(results);
      });
    } catch (err) {
      this.setError(err as ApiError);
      this.rootStore.globalStore.handleApiError(err as ApiError, toastMessages.search.error);
    } finally {
      this.setIsSearching(false);
    }
  };

  clear = () => {
    // Clear results
    this.setCurrentPage(1);
    this.setResults(undefined);
    this.setResultsMetadata(undefined);

    // Clear query
    this.setSearchOption({ label: '', value: NaN });
    this.setSearchQuery('');
  };

  reset = () => {
    // Unfocus
    this.setIsEntityFocused(false);
    this.setIsFieldFocused(false);
    this.setIsQueryFocused(false);

    // Clear results & query
    this.clear();

    // Clear fields
    this.setFieldFilter('');
    this.setSearchField('');

    // Clear entity
    this.setEntityFilter('');
    this.setSearchEntity('' as ModelType);
  };
}
