import { BaselineFuelType, EquipmentType, Program, TBaselineFuelTypeId, TProgramId } from '/src/lib/models';

export type TEquipmentCategoryId = number;

export interface EquipmentCategory {
  id: TEquipmentCategoryId;
  name: string;
  program_id: TProgramId;
  baseline_fuel_type_id: TBaselineFuelTypeId;
  program?: Program;
  baseline_fuel_type?: BaselineFuelType;
  equipment_types?: EquipmentType[];
}
