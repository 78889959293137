import {
  BankAccountType,
  ClientReportingPeriod,
  Program,
  Region,
  RemittanceMethod,
  TBankAccountTypeId,
  TClientId,
  TClientReportingPeriodId,
  TFacilityId,
  TProgramId,
  TRegionId,
  TRemittanceMethodId,
} from '/src/lib/models';
import { DateString, FloatString, Nullable } from '/src/lib/types';

export type TIncentiveStatementId = number;

export interface IncentiveStatement {
  id: TIncentiveStatementId;
  client_id: TClientId;
  client: {
    name: string;
  };
  bank_name: string;
  bank_account_type_id: TBankAccountTypeId;
  routing_number: string;
  account_number: string;
  remittance_address_line1: Nullable<string>;
  remittance_address_line2: Nullable<string>;
  remittance_address_city: Nullable<string>;
  remittance_address_region_id: Nullable<TRegionId>;
  remittance_address_post_code: Nullable<string>;
  client_reporting_period_id: TClientReportingPeriodId;
  clientReportingPeriod: ClientReportingPeriod;
  statement_date: DateString;
  remittance_method_id: TRemittanceMethodId;
  created_at: Date;
  updated_at: Date;
  remittance_method?: RemittanceMethod;
  bank_account_type?: BankAccountType;
  remittance_address_region?: Nullable<Region>;
  incentive_statement_facility_values?: IncentiveStatementFacilityValue[];
  program_totals: IncentiveStatementProgramTotal[];
  total_credits_generated: string;
  total_total_value: FloatString;
  total_rec_cost: FloatString;
  total_broker_fee: FloatString;
  total_commission_earned: FloatString;
  total_client_revenue: FloatString;
  cost_recoup?: FloatString;
  grand_total: FloatString;
}

export type TIncentiveStatementFacilityValueId = number;

export interface IncentiveStatementFacilityValue {
  id: TIncentiveStatementFacilityValueId;
  incentive_statement_id: TIncentiveStatementId;
  program_id: TProgramId;
  program: Program;
  facility_id: TFacilityId;
  facility_address_line1: string;
  facility_address_line2: Nullable<string>;
  facility_address_city: string;
  facility_address_region_id: TRegionId;
  facility_address_region: Region;
  facility_address_post_code: string;
  credits_generated: string;
  final_sale_price: FloatString;
  total_value: FloatString;
  rec_cost: FloatString;
  broker_fee: FloatString;
  commission_earned: FloatString;
  client_revenue: FloatString;
  created_at: Date;
  updated_at: Date;
}

export interface IncentiveStatementProgramTotal {
  program_id: TProgramId;
  program: Program;
  total_credits_generated: string;
  final_sale_price: FloatString;
  total_total_value: FloatString;
  total_rec_cost: FloatString;
  total_broker_fee: FloatString;
  total_commission_earned: FloatString;
  total_client_revenue: FloatString;
}
