import { Nullable } from 'src/lib/types';
import { Program, Region, TProgramId, TRegionId } from '/src/lib/models';

export type TProgramRegionId = number;

export interface ProgramRegion {
  id: TProgramRegionId;
  program_id: TProgramId;
  program: Program;
  region_id: TRegionId;
  region: Region;
  created_at: Nullable<Date>;
  updated_at: Nullable<Date>;
}
