import EVStationIcon from 'jsx:/public/images/ev-station.svg';
import React, { ComponentProps } from 'react';
import { Box } from '/src/components';
import theme from '/src/theme';

export const EVStation: React.FC<EVStationProps> = ({
  color = theme.global?.colors?.['light-1']?.toString() || 'rgb(170, 178, 188)',
  width = '30px',
  height = '32px',
  ...props
}) => (
  <Box {...props}>
    <EVStationIcon fill={color} width={width} height={height} />
  </Box>
);

export type EVStationProps = ComponentProps<typeof Box> & {
  color?: string;
  width?: string;
  height?: string;
};
