import { Route } from 'react-router-dom';
import { ClientDetailsPage } from './client-details';
import { CreateClientPage } from './create-client';
import { ListClientsPage } from './list-clients';
import { UseActiveClient, RequireAuth } from '/src/components';
import { InternalRoles, RoleTypes } from '/src/lib/models';

export * from './statements';

export { ClientDetailsPage, CreateClientPage, ListClientsPage };

export const clientRoutes: React.ReactElement[] = [
  // Client Details
  <Route
    path="/clients/:client_id"
    element={
      <RequireAuth allowedRoles={[...InternalRoles, RoleTypes.ClientAdmin]}>
        <UseActiveClient>
          <ClientDetailsPage />
        </UseActiveClient>
      </RequireAuth>
    }
  />,

  // Create Client
  <Route
    path="/clients/create"
    element={
      <RequireAuth allowedRoles={[RoleTypes.FuseAdmin]}>
        <UseActiveClient>
          <CreateClientPage />
        </UseActiveClient>
      </RequireAuth>
    }
  />,

  // List Clients
  <Route
    path="/clients"
    element={
      <RequireAuth>
        <UseActiveClient>
          <ListClientsPage />
        </UseActiveClient>
      </RequireAuth>
    }
  />,
];
