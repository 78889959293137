import { observer } from 'mobx-react-lite';
import { PropsWithChildren, useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useGlobalStore, useUserStore } from 'src/context';
import { TRoleId } from 'src/lib/models';
import { AuthErrorPage } from './errors';

export const RequireAuth: React.FC<RequireAuthProps> = observer(({ children, ...props }) => {
  const { allowedRoles, redirectUrl, isUserRoute } = props;

  const userStore = useUserStore();
  const location = useLocation();
  const globalStore = useGlobalStore();
  const params = useParams();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [isValid, setIsValid] = useState(true);
  const [isUnauthorized, setIsUnauthorized] = useState(false);

  const handler = (() => {
    if ((isUnauthorized || !isValid) && redirectUrl) return <Navigate to={redirectUrl} replace />;
    if (isUnauthorized) {
      return <AuthErrorPage />;
    } else if (!isValid) {
      return <Navigate to="/login" state={{ from: location }} replace />;
    }
  })();

  useEffect(() => {
    setIsLoading(true);
    setIsValid(false);
    setIsUnauthorized(false);
    const expiration = userStore.token?.expires_at;
    const valid = expiration ? new Date(expiration) > new Date() : false;
    if (!userStore.token || !valid) {
      setIsValid(false);
      userStore.logout();
      toast.error('Your session has expired. Please login again.');
      globalStore.setPreviousPath(location.pathname);
      navigate('/login');
    } else {
      const isSameUser = userStore.user && params.id && userStore.user.id === parseInt(params.id);
      const isAllowed = userStore.user && allowedRoles?.includes(userStore.user.role.id);

      if (isUserRoute && (isSameUser || isAllowed)) {
        setIsValid(true);
      } else if (!!allowedRoles?.length && !isAllowed) {
        setIsUnauthorized(true);
      } else {
        setIsValid(true);
      }

      setIsLoading(false);
    }
  }, [userStore.token, userStore.user, location.pathname, params.id]);

  return handler ?? <>{!isLoading && isValid && children}</>;
});

export type RequireAuthProps = PropsWithChildren & {
  allowedRoles?: TRoleId[];
  redirectUrl?: string;
  isUserRoute?: boolean;
};
