import { Client } from '/src/lib/models';
import { DateString } from '/src/lib/types';

export type TEMCCommissionId = number;

export interface EMCCommission {
  id: TEMCCommissionId;
  client: Client;
  reporting_quarter: DateString;
  percent_rate: number;
}
