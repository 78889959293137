import { useRef } from 'react';
import { Box, BoxProps, FuseLogo, Text } from '/src/components';
import { pxToPt } from '/src/utils';

export const IncentiveStatementFuseInfo: React.FC<IncentiveStatementFuseInfoProps> = (props) => {
  const { ...boxProps } = props;

  const fuseInfo = useRef<Record<string, string>>({
    address_line1: '801 K Street',
    address_line2: 'Suite 2700',
    address_city: 'Sacramento',
    address_region_short_code: 'CA',
    address_post_code: '95814',
    phone: '(833) 937-6262',
    websiteLabel: 'usefuse.com',
  });

  const textProps = useRef({ size: pxToPt('11px'), lineHeight: pxToPt('16px') });

  return (
    <Box gap={pxToPt('4px')} {...boxProps}>
      <Box margin={{ bottom: pxToPt('4px') }}>
        <FuseLogo width={pxToPt('120px')} />
      </Box>
      <Box margin={{ bottom: '0' }}>
        <Text {...textProps.current}>
          {fuseInfo.current.address_line1}, {fuseInfo.current.address_line2}
        </Text>
        <Text {...textProps.current}>
          {fuseInfo.current.address_city}, {fuseInfo.current.address_region_short_code}{' '}
          {fuseInfo.current.address_post_code}
        </Text>
        <Text {...textProps.current}>{fuseInfo.current.phone}</Text>
      </Box>
      <Text {...textProps.current} weight={700} style={{ textDecoration: 'underline' }}>
        {fuseInfo.current.websiteLabel}
      </Text>
    </Box>
  );
};

export type IncentiveStatementFuseInfoProps = BoxProps;
