import { SelectExtendedProps } from 'grommet';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { UserService } from '/src/api';
import { Select } from '/src/components';
import { useGlobalStore, useUserStore } from '/src/context';
import { TUserId, User } from '/src/lib/models';
import { toastMessages } from '/src/lib/toast';
import { SelectOption, TResponseMetadata } from '/src/lib/types';

export const UsersSelect: React.FC<UsersSelectProps> = observer((props) => {
  const { user } = useUserStore();
  const { handleApiError } = useGlobalStore();
  const { users, value, setValue, required, hideOptionalText, disabled, onSubmit } = props;

  const [isLoadingUsers, setIsLoadingUsers] = useState(true);
  const [usersList, setUsersList] = useState<User[]>([]);
  const [selectOptions, setSelectOptions] = useState<SelectOption<TUserId | undefined>[]>([]);
  const [usersMetadata, setUsersMetadata] = useState<TResponseMetadata | undefined>();
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (isLoadingUsers) {
      if (!usersList.length) {
        if (users) {
          setUsersList(users);
          setSelectOptions(
            users.map((user) => ({
              label: user.name,
              value: user.id,
            }))
          );
          setIsLoadingUsers(false);
        } else {
          fetchUsersList()
            .catch((err) => handleApiError(err, toastMessages.listUsers.error))
            .finally(() => setIsLoadingUsers(false));
        }
      } else {
        setIsLoadingUsers(false);
      }
    }
  }, [usersList, isLoadingUsers, value]);

  const fetchUsersList = async (page?: number) => {
    if (user) {
      const { data, meta } = await UserService.list({ page });
      const options = data.map((user) => ({
        label: user.name,
        value: user.id,
      }));
      setUsersList([...(usersList ?? []), ...data]);
      setSelectOptions([...selectOptions, ...options]);
      setUsersMetadata(meta);
      setCurrentPage(meta.current_page);
    }
  };

  const handleOnMore = () => {
    if (usersMetadata && currentPage + 1 > usersMetadata.last_page) return;
    fetchUsersList(currentPage + 1);
  };

  return (
    <Select
      label="Users"
      value={value}
      setValue={(id) => setValue(id)}
      onMore={handleOnMore}
      options={selectOptions}
      required={required}
      disabled={disabled}
      hideOptionalText={hideOptionalText}
    />
  );
});

export type UsersSelectProps = {
  value: TUserId | undefined;
  setValue: (value: TUserId) => void;
  users?: User[];
  error?: string;
  required?: boolean;
  options?: SelectOption<TUserId | undefined>[];
  hideOptionalText?: boolean;
  style?: SelectExtendedProps['style'];
  label?: string;
  placeholder?: string;
  id?: string;
  disabled?: boolean;
  onSubmit?: (event: React.KeyboardEvent<HTMLElement>) => void;
};
