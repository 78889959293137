import { Address, Client, EquipmentType, FSERegistrationStatus, Facility, FuelPathway, OwnershipType } from '.';
import { DateString } from '../types';

export type TTransactedValueId = number;

export interface TransactedValue {
  id: TTransactedValueId;
  client: Client;
  reporting_quarter: DateString;
  ownership_type: OwnershipType;
  is_metered: boolean;
  battery_capacity_rating_ah: number;
  voltage: number;
  charge_cycles_per_shift: number;
  work_days_per_quarter: number;
  shifts_per_day: number;
  percent_charger_efficiency_rating: number;
  percent_charge_return_factor: number;
  percent_depth_of_discharge: number;
  kwh_per_quarter_for_other: number;
  is_book_and_claim_applied: boolean;
  ci_score: number;
  ei: number;
  eeer: number;
  cixd_standard: number;
  final_sale_price: number;
  battery_capacity_rating_kwh: number;
  kwh_per_charge_cycle: number;
  kwh_per_charger_per_quarter: number;
  total_kwh: number;
  ci_xd_reported: number;
  exd_displaced: number;
  credits_generated: number;
  recs_required: number;
  program_rec_cost: number;
  rec_cost: number;
  total_value: number;
  total_value_after_rec_cost: number;
  percent_total_sales_commission_rates: number;
  total_sales_commission_rates: number;
  percent_total_referral_commission_rates: number;
  total_referral_commission_rates: number;
  total_commissions: number;
  percent_emc_commission_rate: number;
  emc_commission: number;
  emc_commission_after_all_commissions: number;
  client_revenue: number;
  facility: Facility;
  address?: Address;
  equipment_type: EquipmentType;
  fuel_pathway: FuelPathway;
  name?: string;
  unit_number?: string;
  serial_number?: string;
  manufacturer?: string;
  model_number?: string;
  model_year?: number;
  used_for_charging: boolean;
  charging_level: string;
  charging_usage_type: string;
  connector_type: string;
  rated_capacity: string;
  latitude?: number;
  longitude?: number;
  fse_id?: string;
  fse_registration_status: FSERegistrationStatus;
  fse_ru?: string;
  comments?: string;
}
