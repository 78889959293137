import { TextProps } from 'grommet';
import React, { PropsWithChildren } from 'react';
import { Box, BoxProps, Text } from '/src/components';
import { pxToRem } from '/src/utils';

export const DataTableItem: React.FC<DataTableItemProps> = ({
  children,
  value,
  unitName,
  icon,
  boxProps,
  ...textProps
}) => (
  <Box direction="row" gap="xsmall" align="center" {...boxProps}>
    {icon}
    <Text size="medium" fontFamily="Lato, sans-serif" {...textProps}>
      {children ?? value}
    </Text>
    {unitName && (
      <Text size="small" fontFamily="Lato, sans-serif">
        {unitName}
      </Text>
    )}
  </Box>
);

export type DataTableItemProps = PropsWithChildren &
  TextProps & {
    value?: string | number;
    unitName?: string;
    icon?: React.ReactNode;
    boxProps?: BoxProps;
  };
