import { TSession } from '/src/lib/types';

/**
 * Login
 */
export type TLoginRequest = {
  email: string;
  password: string;
};

export type TLoginResponse = TSession;

/**
 * Logout
 */
export type TLogoutResponse = {
  message: string;
};
