import '/src/lib/sentry/instrument';

import { Grommet } from 'grommet';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { RootErrorBoundary } from '/src/components';
import { RootContextProvider } from '/src/context';
import '/src/index.css';
import { AppRoutes } from '/src/routes';
import theme from '/src/theme';

ReactDOM.createRoot(document.getElementById('app')!).render(
  <React.StrictMode>
    <RootContextProvider>
      <BrowserRouter>
        <Grommet theme={theme}>
          <RootErrorBoundary>
            <AppRoutes />
            <ToastContainer />
          </RootErrorBoundary>
        </Grommet>
      </BrowserRouter>
    </RootContextProvider>
  </React.StrictMode>
);
