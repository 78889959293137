import { Button, CardBody, DataTable, Layer, Pagination } from 'grommet';
import { SettingsOption } from 'grommet-icons';
import React, { useEffect, useState } from 'react';
import { ClientService, TListSalesCommissionsRequest } from '/src/api';
import {
  AddButton,
  Box,
  Card,
  CardHeader,
  CommissionIcon,
  DataTableHeader,
  DataTableItem,
  Filters,
  Link,
  LoadingSpinner,
  SalesCommissionModal,
  SearchInput,
  Text,
} from '/src/components';
import { useGlobalStore, useUserStore } from '/src/context';
import { SalesCommission, TClientId } from '/src/lib/models';
import { toastMessages } from '/src/lib/toast';
import { TResponseMetadata } from '/src/lib/types';
import { getQuarterFromDateString } from '/src/utils';

export const CommissionList: React.FC<CommissionListProps> = (props) => {
  const { id, title, salesCommissions: commissionsProp, clientId, showAddButton, showFilters, showSearchInput } = props;
  const { isLoggedIn } = useUserStore();
  const { handleApiError, roles } = useGlobalStore();

  const [isLoadingCommissions, setIsLoadingCommissions] = useState(true);
  const [isFiltering, setIsFiltering] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState<number>(1);

  const [commissions, setCommissions] = useState<SalesCommission[]>();
  const [metadata, setCommissionsMetadata] = useState<TResponseMetadata>();
  const [request, setRequest] = useState<TListSalesCommissionsRequest>({});
  const [filters, setFilters] = useState<Record<string, any>>({});

  const [selectedCommission, setSelectedCommission] = useState<SalesCommission | undefined>();
  const [showDetails, setShowDetails] = useState(false);

  const isLoading = !(commissions && !isLoadingCommissions);
  const hasCommissions = !!commissions?.length;

  const fetchCommissions = (page?: number) => {
    if (!isLoggedIn) return;

    if (commissionsProp) {
      setCommissions(commissionsProp);
      setIsLoadingCommissions(false);
      setIsFiltering(false);
      setIsSearching(false);
    } else if (clientId) {
      ClientService.get({ id: clientId })
        .then((client) => {
          setCommissions(client.sales_commission_rates);
        })
        .catch((err) => handleApiError(err, toastMessages.generic.error))
        .finally(() => {
          setIsLoadingCommissions(false);
          setIsFiltering(false);
          setIsSearching(false);
        });
    }
  };

  const search = (query?: string) => {
    setIsSearching(true);
    setRequest({
      ...request,
      page: 1,
    });
  };

  const applyFilters = () => {
    const req = { ...request, ...filters };
    setIsFiltering(true);
    setRequest(req);
  };

  const clearFilters = () => {
    setFilters({});
    setRequest({
      page: 1,
    });
  };

  useEffect(() => {
    if (isSearching) {
      fetchCommissions();
    } else if (!isLoadingCommissions) {
      setIsLoadingCommissions(true);
      fetchCommissions();
    }
  }, [request]);

  useEffect(() => {
    if (isLoadingCommissions && !commissions) {
      fetchCommissions(currentPage);
    }
  }, [isLoadingCommissions, commissions]);

  useEffect(() => {
    if (!isLoadingCommissions && metadata && currentPage !== metadata.current_page) {
      setIsLoadingCommissions(true);
      fetchCommissions(currentPage);
    }
  }, [isLoadingCommissions, metadata, currentPage]);

  const renderEditButton = (salesCommission: SalesCommission) => (
    <Button
      onClick={() => {
        setSelectedCommission(salesCommission);
        setShowDetails(true);
      }}
    >
      <Box borderRadius="6px" direction="row" justify="center" pad={{ vertical: '1rem' }}>
        <SettingsOption size="14px" color="accent-1" />
      </Box>
    </Button>
  );

  const renderModal = (selectedCommission: SalesCommission) => (
    <Layer
      onEsc={() => {
        setShowDetails(false);
        setSelectedCommission(undefined);
      }}
      onClickOutside={() => {
        setShowDetails(false);
        setSelectedCommission(undefined);
      }}
      background="transparent"
    >
      <SalesCommissionModal salesCommission={selectedCommission} setIsVisible={setShowDetails} />
    </Layer>
  );

  return (
    <Box id={id} direction="row" gap="medium" fill="horizontal">
      <Card>
        <CardHeader title={title || 'Commission List'} icon={<CommissionIcon />}>
          {showSearchInput && searchQuery !== undefined && setSearchQuery !== undefined && (
            <SearchInput
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              onSearch={search}
              isSearching={isSearching}
            />
          )}
          {showAddButton && <AddButton label="Add Commission" />}
        </CardHeader>
        <CardBody pad="none" gap="none">
          <Box elevation="small">
            {(isLoading || isSearching || isFiltering) && <LoadingSpinner />}
            {!isLoading && !isSearching && !isFiltering && (
              <DataTable
                columns={[
                  {
                    property: '',
                    header: <Box height="1rem" margin={{ vertical: '1rem' }} />,
                    render: renderEditButton,
                  },
                  {
                    property: 'name',
                    header: <DataTableHeader>NAME</DataTableHeader>,
                    primary: true,
                    render: (salesCommission) => (
                      <Link to={`/users/${salesCommission.user.id}`}>
                        <Text size="medium" weight={500} color="accent-1">
                          {salesCommission.user.name}
                        </Text>
                      </Link>
                    ),
                  },
                  {
                    property: 'percent_rate',
                    header: <DataTableHeader>COMMISSION</DataTableHeader>,
                    render: (salesCommission) => <DataTableItem>{`${salesCommission.percent_rate}%`}</DataTableItem>,
                  },
                  {
                    property: 'start_reporting_quarter',
                    header: <DataTableHeader>EFFECTIVE START DATE</DataTableHeader>,
                    render: (salesCommission) => (
                      <DataTableItem>{getQuarterFromDateString(salesCommission.start_reporting_quarter)}</DataTableItem>
                    ),
                  },
                  {
                    property: 'comments',
                    header: <DataTableHeader>COMMENTS</DataTableHeader>,
                    render: (salesCommission) => <DataTableItem>{salesCommission.comments || '—'}</DataTableItem>,
                  },
                ]}
                data={commissionsProp}
                background={['light-6', 'white']}
                border={{ color: 'light-2', side: 'bottom', size: 'small' }}
              />
            )}
            {!hasCommissions && !isLoadingCommissions && !isSearching && (
              <Box pad={{ horizontal: '1.5rem', vertical: '2rem' }} background="light-6" justify="center">
                <Text alignSelf="center" size="medium" fontFamily="Lato, sans-serif">
                  No sales commissions found.
                </Text>
              </Box>
            )}
          </Box>
          {hasCommissions && !!metadata && !!currentPage && !!setCurrentPage && (
            <Box pad="1.5rem" flex="grow">
              <Pagination
                alignSelf="center"
                size="small"
                page={currentPage}
                step={metadata?.per_page}
                numberItems={metadata?.total}
                onChange={(e) => setCurrentPage(e.page)}
              />
            </Box>
          )}
        </CardBody>
      </Card>
      {showFilters && (
        <Filters
          isFiltering={isFiltering}
          onSubmit={() => applyFilters()}
          onClear={() => clearFilters()}
          filters={[]}
        />
      )}
      {showDetails && !!selectedCommission && renderModal(selectedCommission)}
    </Box>
  );
};

export type CommissionListProps = {
  id?: string;
  title?: string;
  salesCommissions?: SalesCommission[];
  clientId?: TClientId;
  showAddButton?: boolean;
  showFilters?: boolean;
  showSearchInput?: boolean;
};
