import { Nullable } from '../types';
import { Country, ProgramRegion, TCountryId } from '/src/lib/models';

export type TRegionId = number;

export interface Region {
  id: TRegionId;
  name: Nullable<string>;
  short_code: Nullable<string>;
  country_id: Nullable<TCountryId>;
  country?: Nullable<Country>;
  program_regions?: ProgramRegion[];
}
