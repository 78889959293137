import { Client, Facility, Role, TRoleId } from '/src/lib/models';
import { DateString, Nullable } from '/src/lib/types';

export type TUserId = number;

export enum UserEmailVerificationStatus {
  Verified = 'Verified',
  Unverified = 'Not Verified',
}

export interface User {
  id: TUserId;
  email: string;
  name: string;
  phone?: string;
  is_internal: boolean;
  is_active: boolean;
  is_email_verified: boolean;
  new_unverified_email: Nullable<string>;
  last_login_at: Nullable<string>;
  created_at: DateString;
  updated_at: Nullable<DateString>;
  clients: Client[];
  facilities: Facility[];
  role: Role;
  role_id: TRoleId;
}
