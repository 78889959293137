import { observer } from 'mobx-react-lite';
import { ClientList, ListPage, PageHeader } from '/src/components';
import { useUserStore } from '/src/context';

export const ListClientsPage = observer(() => {
  const { isInternalUser, isAdminUser, isClientUser } = useUserStore();

  const isLoading = false;

  return (
    <ListPage
      title="Clients"
      pageHeader={
        <PageHeader
          title="Clients"
          showAddButton={isInternalUser || (isAdminUser && isClientUser)}
          addButtonLabel="ADD CLIENT"
          addButtonUrl="/clients/create"
        />
      }
      isLoading={isLoading}
    >
      <ClientList showSearchInput showFilters />
    </ListPage>
  );
});
