import { Program, Region, TProgramId, TRegionId, TReportingPeriodTypeId, TUtilityId, Utility } from '/src/lib/models';
import { DateString, FloatString, Nullable } from '/src/lib/types';

export type TFuelPathwayId = number;

export interface FuelPathway {
  id: TFuelPathwayId;
  code: string;
  reporting_period_type_id: TReportingPeriodTypeId;
  start_reporting_quarter: DateString;
  end_reporting_quarter: DateString;
  ci_score: FloatString;
  default_utility_id: Nullable<TUtilityId>;
  default_utility: Nullable<Utility>;
  program_id: TProgramId;
  program: Program;
  region_id: TRegionId;
  region: Region;
}
