import { TListRolesRequest, TListRolesResponse } from '../models/Roles';
import type { CancelablePromise } from '/src/api';
import { OpenAPI, request as __request } from '/src/api';

export class RoleService {
  /**
   * List Roles
   * @returns Role[]
   * @throws ApiError
   */
  public static list(req?: TListRolesRequest): CancelablePromise<TListRolesResponse> {
    return __request(OpenAPI, { method: 'GET', url: '/roles', query: req });
  }
}
