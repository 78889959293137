import { Program } from '/src/lib/models';
import { DateString } from '/src/lib/types';

export type TFinalSalePriceId = number;

export interface FinalSalePrice {
  id: TFinalSalePriceId;
  program: Program;
  reporting_quarter: DateString;
  price: number;
}
