import dayjs from 'dayjs';
import { DataTable, Pagination } from 'grommet';
import { CircleAlert, Group, StatusGood } from 'grommet-icons';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ApiError, ClientService, TListUsersRequest, UserService } from '/src/api';
import {
  AddButton,
  Box,
  BoxProps,
  Card,
  CardBody,
  CardHeader,
  DataTableHeader,
  DataTableItem,
  Filters,
  LoadingSpinner,
  SearchInput,
  Text,
  UserInfoBox,
} from '/src/components';
import { useClientStore, useGlobalStore, useUserStore } from '/src/context';
import { ExternalRoles, TClientId, User } from '/src/lib/models';
import { toastMessages } from '/src/lib/toast';
import { EntityStatus, TResponseMetadata } from '/src/lib/types';
import { pxToRem } from '/src/utils';

export const UserList: React.FC<UserListProps> = (props) => {
  /* Props */
  const {
    id,
    title,
    clientId,
    isClientPage,
    showAddButton,
    showFilters,
    showSearchInput,
    isLoading: propIsLoading,
    setIsLoading: propSetIsLoading,
    users: propUsers,
    fetchUsers: propFetchUsers,
    metadata: propMetadata,
    currentPage: propCurrentPage,
    setCurrentPage: propSetCurrentPage,
    hideHeader,
    boxProps,
  } = props;

  /* Context */
  const userStore = useUserStore();
  const globalStore = useGlobalStore();
  const clientStore = useClientStore();

  /* State */
  const [isLoading, setIsLoading] = useState(true);
  const [isFiltering, setIsFiltering] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState<number>(1);

  const [users, setUsers] = useState<User[]>();
  const [metadata, setUsersMetadata] = useState<TResponseMetadata>();
  const [request, setRequest] = useState<TListUsersRequest>({});
  const [filters, setFilters] = useState<Record<string, any>>({
    role_id: 0,
    is_active: 2,
  });

  const _currentPage = useMemo(() => propCurrentPage ?? currentPage, [propCurrentPage, currentPage]);
  const _isLoading = useMemo(() => propIsLoading ?? isLoading, [propIsLoading, isLoading]);
  const _users = useMemo(() => propUsers ?? users, [propUsers, users]);
  const _metadata = useMemo(() => propMetadata ?? metadata, [propMetadata, metadata]);

  /* Memos */
  const hasUsers = useMemo(() => !!_users?.length, [_users]);
  const filteredRoleOptions = useMemo(
    () =>
      (!userStore.isInternalUser
        ? globalStore.roles.filter((role) => ExternalRoles.includes(role.id))
        : globalStore.roles
      ).map((role) => ({
        label: role.name,
        value: role.id,
      })),
    [globalStore.roles, userStore.isInternalUser]
  );

  /* Methods */
  const fetchUsers = useCallback(
    async (page?: number) => {
      if (!userStore.user) return;

      try {
        const { meta, data } = clientId
          ? await ClientService.listClientUsers({ ...request, page: page || request.page, id: clientId })
          : await UserService.list({ ...request, page: page || request.page });
        setUsers(data);
        setUsersMetadata(meta);
        propSetCurrentPage ? propSetCurrentPage(meta.current_page) : setCurrentPage(meta.current_page);
      } catch (err) {
        globalStore.handleApiError(err as ApiError, toastMessages.listUsers.error);
      } finally {
        propSetIsLoading ? propSetIsLoading(false) : setIsLoading(false);
        setIsFiltering(false);
        setIsSearching(false);
      }
    },
    [userStore.user, propUsers, request, clientId]
  );

  const search = useCallback(
    (query?: string) => {
      setRequest({
        ...request,
        page: 1,
        name: query,
      });
      setIsSearching(true);
    },
    [request]
  );

  const applyFilters = useCallback(() => {
    const req = { ...request };
    req.role_id = filters.role_id || undefined;
    req.is_active = filters.is_active === 1 ? true : filters.is_active === 0 ? false : undefined;
    setRequest(req);
    setIsFiltering(true);
  }, [request, filters]);

  const clearFilters = useCallback(() => {
    setRequest({ page: 1 });
    setFilters({ role_id: 0, is_active: 2 });
    setIsFiltering(true);
  }, []);

  /* Effects */
  useEffect(() => {
    if (isSearching || isFiltering) {
      propFetchUsers ? propFetchUsers() : fetchUsers();
    }
  }, [isSearching, isFiltering]);

  useEffect(() => {
    if (!_users) {
      if (!!Object.keys(filters).length) applyFilters();
      else propFetchUsers ? propFetchUsers(_currentPage) : fetchUsers(_currentPage);
    } else if (_isLoading) {
      propSetIsLoading ? propSetIsLoading(false) : setIsLoading(false);
    }
  }, [_users, _currentPage, filters]);

  useEffect(() => {
    if (!_isLoading && _metadata && _currentPage !== _metadata.current_page) {
      propSetIsLoading ? propSetIsLoading(true) : setIsLoading(true);
      propFetchUsers ? propFetchUsers(_currentPage) : fetchUsers(_currentPage);
    }
  }, [_isLoading, _metadata, _currentPage]);

  /* Render */
  return (
    <Box id={id} direction="row" gap="medium" fill="horizontal">
      <Card {...boxProps}>
        {!hideHeader && (
          <CardHeader title={title || 'User List'} icon={<Group size="24px" color="brand" />}>
            {showSearchInput && searchQuery !== undefined && setSearchQuery !== undefined && (
              <SearchInput
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                onSearch={search}
                isSearching={isSearching}
              />
            )}
            {showAddButton && (
              <AddButton label="Add User" targetUrl={`/users/create${clientId ? `?client_id=${clientId}` : ''}`} />
            )}
          </CardHeader>
        )}
        <CardBody pad="none" gap="none">
          <Box elevation="small">
            {(_isLoading || isSearching || isFiltering) && <LoadingSpinner />}
            {!_isLoading && !isSearching && !isFiltering && (
              <DataTable
                pad={{ horizontal: '1.5rem' }}
                columns={[
                  {
                    property: 'id',
                    primary: true,
                    header: <DataTableHeader>USER</DataTableHeader>,
                    render: (user: User) => <UserInfoBox user={user} clientId={clientId} />,
                  },
                  {
                    property: isClientPage ? 'last_login_at' : 'clients',
                    header: <DataTableHeader>{isClientPage ? 'LAST LOGIN' : 'COMPANY'}</DataTableHeader>,
                    render: (user: User) => (
                      <DataTableItem boxProps={{ align: 'center', height: pxToRem(92) }}>
                        {(isClientPage
                          ? user.last_login_at
                            ? dayjs(user.last_login_at).format('MM/DD/YYYY @ hh:mm A')
                            : 'Never'
                          : clientStore.activeClient?.name) || '-'}
                      </DataTableItem>
                    ),
                  },
                  {
                    property: 'role.name',
                    header: <DataTableHeader>USER ROLE</DataTableHeader>,
                    align: 'start',
                    render: (user: User) =>
                      user.role ? (
                        <Box
                          background="light-4"
                          pad={{ horizontal: '0.5rem', vertical: '0.25rem' }}
                          borderRadius="6px"
                          border={{ color: 'light-2', size: 'small' }}
                        >
                          <Text size="xsmall" weight={700} fontFamily="Lato, sans-serif">
                            {user.role?.name.split(' ').join(' - ')}
                          </Text>
                        </Box>
                      ) : undefined,
                  },
                  {
                    property: 'active',
                    header: <DataTableHeader>STATUS</DataTableHeader>,
                    render: (user: User) => (
                      <DataTableItem>
                        <Box direction="row" gap="xsmall">
                          <Box alignSelf="center">
                            {user.is_active ? <StatusGood color="green" size="16px" /> : <CircleAlert size="16px" />}
                          </Box>
                          <Text alignSelf="center" size="medium" fontFamily="Lato, sans-serif">
                            {user.is_active ? EntityStatus.Active : EntityStatus.Inactive}
                          </Text>
                        </Box>
                      </DataTableItem>
                    ),
                  },
                ]}
                data={_users}
                background={['light-6', 'white']}
                border={{ color: 'light-2', side: 'bottom', size: 'small' }}
              />
            )}
            {!hasUsers && !_isLoading && !isSearching && (
              <Box pad={{ horizontal: '1.5rem', vertical: '2rem' }} background="light-6" justify="center">
                <Text alignSelf="center" size="medium" fontFamily="Lato, sans-serif">
                  No users found.
                </Text>
              </Box>
            )}
          </Box>
          {!!_metadata && !!_currentPage && (!!propSetCurrentPage || !!setCurrentPage) && (
            <Box pad="1.5rem" flex="grow">
              <Pagination
                alignSelf="center"
                size="small"
                page={_currentPage}
                step={_metadata?.per_page}
                numberItems={_metadata?.total}
                onChange={(e) => (propSetCurrentPage ? propSetCurrentPage(e.page) : setCurrentPage(e.page))}
              />
            </Box>
          )}
        </CardBody>
      </Card>
      {showFilters && filteredRoleOptions && (
        <Filters
          isFiltering={isFiltering}
          onSubmit={() => applyFilters()}
          onClear={() => clearFilters()}
          filters={[
            {
              label: 'User Role',
              value: filters.role_id,
              setValue: (value) => setFilters({ ...filters, role_id: value }),
              options: [{ label: 'All', value: 0 }, ...filteredRoleOptions],
            },
            {
              label: 'User Status',
              value: filters.is_active,
              setValue: (value) => setFilters({ ...filters, is_active: value }),
              options: [
                { label: 'All', value: 2 },
                { label: 'Active', value: 1 },
                { label: 'Inactive', value: 0 },
              ],
            },
          ]}
        />
      )}
    </Box>
  );
};

export type UserListProps = {
  id?: string;
  clientId?: TClientId;
  title?: string;
  users?: User[];
  fetchUsers?: (page?: number) => void;
  metadata?: TResponseMetadata;
  isLoading?: boolean;
  setIsLoading?: (isLoading: boolean) => void;
  showAddButton?: boolean;
  showFilters?: boolean;
  showSearchInput?: boolean;
  isClientPage?: boolean;
  hideHeader?: boolean;
  currentPage?: number;
  setCurrentPage?: (page: number) => void;
  boxProps?: BoxProps;
};
