import { Client } from '/src/lib/models';
import { DateString } from '/src/lib/types';

export type TBrokerageFeeId = number;

export interface BrokerageFee {
  id: TBrokerageFeeId;
  client: Client;
  reporting_quarter: DateString;
  percent_rate: number;
}
