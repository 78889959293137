export type TooltipMessages = {
  adminSearch: string;
  clearSearch: string;
  resetSearch: string;
  viewPrintableStatement: string;
  companyPageLink: string;
  equipmentPageLink: string;
  equipmentUsagePageLink: string;
  facilityPageLink: string;
  userPageLink: string;
  logNotifications: string;
  setActiveClientInfo: string;
};

export const tooltipMessages: TooltipMessages = {
  // Search
  adminSearch: 'Quickly find Clients, Facilities, Equipment, or Users',
  clearSearch: 'Clear the current results',
  resetSearch: 'Reset the search box',

  // Statements
  viewPrintableStatement: 'View printable version',

  // Links
  companyPageLink: 'Go to Company page',
  equipmentPageLink: 'Go to Equipment page',
  equipmentUsagePageLink: 'Go to Equipment Usage page',
  facilityPageLink: 'Go to Facility page',
  userPageLink: 'Go to User page',

  // Logs
  logNotifications: 'Audit recent change logs',

  // Clients
  setActiveClientInfo:
    'The active company can be changed at any time by clicking the "Set Active Company" link in the User menu.',
};
