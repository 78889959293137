import { Box, BoxProps } from 'grommet';
import React, { PropsWithChildren } from 'react';

export const RowBox: React.FC<RowBoxProps> = ({ children, ...boxProps }) => {
  return (
    <Box direction="row" {...boxProps}>
      {children}
    </Box>
  );
};

export type RowBoxProps = PropsWithChildren & BoxProps;
