import { Box, Spinner, SpinnerProps } from 'grommet';
import React from 'react';

export const LoadingSpinner: React.FC<LoadingSpinnerProps> = (props) => (
  <Box height="100vh" fill="horizontal" justify="center" align="center">
    <Spinner size={props.size || 'large'} {...props} />
  </Box>
);

export type LoadingSpinnerProps = SpinnerProps;
