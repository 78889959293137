import { Avatar, Button, DataTable } from 'grommet';
import { useEffect, useState } from 'react';
import { Box, DataTableHeader, Input, Link, Select, Text, UserInfoBox } from '/src/components';
import { TUserId, User } from '/src/lib/models';
import { getInitials } from '/src/utils';

export const ContactsList: React.FC<ContactsListProps> = (props) => {
  const { contacts = [], isUpdatePage, isCorporate, isRemittance, isFacility } = props;

  /** State **/
  const [selectedContactId, setSelectedContactId] = useState<TUserId>();

  /** Computed **/
  const isClient = isCorporate || isRemittance;

  const entityName = isClient ? 'Client' : isFacility ? 'Facility' : undefined;
  const selectedContact = contacts.find((contact) => contact.id === selectedContactId) ?? contacts[0];

  useEffect(() => {
    if (!!contacts?.length && !selectedContactId) {
      setSelectedContactId(contacts[0]?.id);
    }
  }, [contacts, selectedContactId]);

  return (
    <Box gap="1rem" fill="horizontal">
      {!isUpdatePage && (
        <Box
          pad="1rem"
          gap="0.5rem"
          background="light-6"
          border={{ size: 'small', color: 'light-5' }}
          borderRadius="6px"
          fontFamily="Lato, sans-serif"
        >
          <Text size="large">Points of Contact</Text>
          <Text size="medium">All Admin users for this {entityName} will be considered points of contact.</Text>
        </Box>
      )}
      {isUpdatePage && (
        <Box gap="1rem">
          <Box gap="small">
            <Text color="dark-1" size="large" weight={300}>
              Points of Contact
            </Text>
            <Box>
              <Text fontFamily="Lato, sans-serif">
                All Admin users for this Client and {entityName} are considered points of contact.
              </Text>
            </Box>
          </Box>
          <Select
            id="contact_name"
            label="Contact"
            placeholder="Choose..."
            value={selectedContactId}
            setValue={(value) => setSelectedContactId(value)}
            options={contacts.map((user) => ({ label: user.name, value: user.id }))}
            hideOptionalText
          />
          <Box height="8rem">
            {selectedContact && (
              <Box gap="1rem">
                <Button plain>
                  <Input
                    id="contact_role_name"
                    label="Contact Role"
                    value={selectedContact.role.name}
                    placeholder=""
                    setValue={() => {}}
                    hideOptionalText
                    disabled
                  />
                </Button>
                <Button plain>
                  <Input
                    id="contact_email"
                    label="Contact Email"
                    value={selectedContact.email}
                    placeholder=""
                    setValue={() => {}}
                    hideOptionalText
                    disabled
                  />
                </Button>
                <Button plain>
                  <Input
                    id="contact_phone"
                    label="Contact Phone"
                    value={selectedContact.phone ?? ''}
                    placeholder=""
                    setValue={() => {}}
                    hideOptionalText
                    width="1/3"
                    disabled
                  />
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export type ContactsListProps = {
  contacts?: User[];
  isUpdatePage?: boolean;
  // isClient?: boolean;
  isCorporate?: boolean;
  isRemittance?: boolean;
  isFacility?: boolean;
};
