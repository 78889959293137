import { TListResponse } from '.';
import { ClientReportingPeriod, TClientId, TClientReportingPeriodId, TReportingPeriodTypeId } from '/src/lib/models';
import { DateString, ReportingPeriodFinalizeType } from '/src/lib/types';

/**
 * List Client Reporting Periods
 */
export type TListClientReportingPeriodsRequest = {
  client_id: TClientId;
  page?: number;
  limit?: number;
  reporting_period_type_id?: TReportingPeriodTypeId;
  start_reporting_quarter?: DateString;
  end_reporting_quarter?: DateString;
  is_finalized?: ReportingPeriodFinalizeType;
};

export type TListClientReportingPeriodsResponse = TListResponse<ClientReportingPeriod[]>;

/**
 * Finalize Client Reporting Period
 */
export type TFinalizeClientReportingPeriodRequest = {
  id: TClientReportingPeriodId;
};

export type TFinalizeClientReportingPeriodResponse = void;

/**
 * Preview Finalized Reporting Period Results
 */
export type TPreviewFinalizedReportingPeriodRequest = {
  id: TClientReportingPeriodId;
};

export type TPreviewFinalizedReportingPeriodResponse = unknown[];
