import { SalesCommission, TQuarterId, TSalesCommissionId, TUserId } from '/src/lib/models';
import { TResponseMetadata } from '/src/lib/types';

/**
 * List Sales Commissions
 */
export type TListSalesCommissionsRequest = {
  limit?: number;
  page?: number;
};

export type TListSalesCommissionsResponse = {
  meta: TResponseMetadata;
  data: SalesCommission[];
};

/**
 * Get Sales Commission
 */
export type TGetSalesCommissionRequest = {
  id: TSalesCommissionId;
};

export type TGetSalesCommissionResponse = SalesCommission;

/**
 * Create Sales Commission
 */
export type TCreateSalesCommissionRequest = {
  user_id: TUserId;
  reporting_quarter_id: TQuarterId;
  percent_rate: number;
  payment_structure: string;
  start_date: string;
  end_date?: string;
};

export type TCreateSalesCommissionResponse = SalesCommission;

/**
 * Update Sales Commission
 */
export type TUpdateSalesCommissionRequest = {
  id: TSalesCommissionId;
  user_id: TUserId;
  reporting_quarter_id: TQuarterId;
  percent_rate: number;
  payment_structure: string;
  start_date: string;
  end_date?: string;
};

export type TUpdateSalesCommissionResponse = SalesCommission;
