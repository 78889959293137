import { TClientId, TReportingPeriodTypeId } from '/src/lib/models';
import { DateString, FloatString } from '/src/lib/types';

export type TClientCommissionRateId = number;

export interface ClientCommissionRate {
  id: TClientCommissionRateId;
  created_at: DateString;
  updated_at: DateString;
  client_id: TClientId;
  reporting_period_type_id: TReportingPeriodTypeId;
  start_reporting_quarter: DateString;
  end_reporting_quarter: DateString;
  percent_rate: FloatString;
}
