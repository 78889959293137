import { EquipmentType } from '/src/lib/models';
import { DateString } from '/src/lib/types';

export type TEquipmentTypeEERId = number;

export interface EquipmentTypeEER {
  id: TEquipmentTypeEERId;
  equipment_type: EquipmentType;
  reporting_quarter: DateString;
  eer: number;
}
