import { Avatar, DropButton } from 'grommet';
import { CaretDownFill, CaretUpFill, Logout, Organization, SettingsOption } from 'grommet-icons';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, BoxProps, Text } from '/src/components';
import { useClientStore, useUserStore } from '/src/context';
import { User } from '/src/lib/models';
import { getInitials } from '/src/utils';

export const UserProfileMenu: React.FC<UserProfileMenuProps> = observer((props) => {
  const userStore = useUserStore();
  const clientStore = useClientStore();
  const navigate = useNavigate();

  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const isMultiClientUser = userStore.user && userStore.isExternalUser && (userStore.user.clients.length ?? 0) > 1;

  const handleEditProfile = () => {
    if (userStore.user) {
      setMenuIsOpen(false);
      navigate(`/users/${userStore.user.id}`);
    }
  };

  const handleChangeCompany = () => {
    clientStore.setActiveClientId(undefined);
    setMenuIsOpen(false);
  };

  const renderSignedInAs = (userName: string) => (
    <Box pad="small" border={{ side: 'bottom', size: '0.05rem', color: 'light-2' }} fontFamily="Lato, sans-serif">
      <Text size="0.875rem" color="light-3">
        Signed in as:
      </Text>
      <Text size="1rem" color="dark-2" weight={700} lineHeight="1.5rem">
        {userName}
      </Text>
    </Box>
  );

  const renderMenuButton = (label: string, onClick: (e: any) => void, icon: React.ReactNode) => (
    <Box
      hoverIndicator={{
        background: 'light-4',
      }}
      gap="xsmall"
      pad={{ horizontal: 'small', vertical: 'small' }}
      flex="grow"
      direction="row"
      color="dark-2"
      onClick={onClick}
    >
      <Box alignSelf="center">{icon}</Box>
      <Text alignSelf="center" size="1rem" toUpperCase>
        {label}
      </Text>
    </Box>
  );

  const renderMenu = (user: User) => (
    <Box justify="center" {...props}>
      <DropButton
        label={
          <Box direction="row" justify="center" pad="xsmall" gap="xsmall">
            <Avatar background="accent-1" size="40px" hoverIndicator={{ elevation: 'small' }}>
              <Text size="1.275rem" color="white">
                {getInitials(user.name)}
              </Text>
            </Avatar>
            <Box justify="center">{menuIsOpen ? <CaretUpFill /> : <CaretDownFill />}</Box>
          </Box>
        }
        open={menuIsOpen}
        onOpen={() => setMenuIsOpen(true)}
        onClose={() => setMenuIsOpen(false)}
        plain
        dropProps={{ align: { top: 'bottom', right: 'right' } }}
        dropContent={
          <Box width="15rem" flex="grow" border={{ size: '0.05rem', color: 'light-2' }}>
            {renderSignedInAs(user.name)}
            {isMultiClientUser &&
              renderMenuButton('Set Active Company', handleChangeCompany, <Organization size="16px" />)}
            {renderMenuButton('Edit Profile', handleEditProfile, <SettingsOption size="16px" />)}
            {renderMenuButton('Log Out', () => navigate('/logout'), <Logout size="16px" />)}
          </Box>
        }
      />
    </Box>
  );

  return userStore.user ? renderMenu(userStore.user) : <></>;
});

export type UserProfileMenuProps = BoxProps;
