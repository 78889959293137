import { ApexOptions } from 'apexcharts';
import { Box, CardBody, CardHeader, Text } from 'grommet';
import { ColorType } from 'grommet/utils';
import { useEffect, useRef, useState } from 'react';
import Chart from 'react-apexcharts';
import { Card, CardProps, LoadingSpinner } from '/src/components';
import { DashboardData } from '/src/lib/models';
import theme from '/src/theme';
import { standardizeFacilityAddress } from '/src/utils';

export type TFacilitiesData = Record<string, Record<string, number>>;

export const EquipmentByFacilityChart: React.FC<EquipmentByFacilityChartProps> = (props) => {
  /**
   * Props
   */
  const { data, height, ...boxProps } = props;

  const [facilitiesData, setFacilitiesData] = useState<TFacilitiesData>({});
  const [seriesData, setSeriesData] = useState<ApexAxisChartSeries>([]);

  const chartLabels = Object.keys(facilitiesData);

  /* Refs */
  const themeColors = useRef(theme.global?.colors ?? {});
  const chartTitle = useRef('Equipment Type By Facility');
  const cardHeight = useRef('31.5rem');
  const chartColors = useRef<ColorType[]>([
    themeColors.current['accent-3'],
    themeColors.current['accent-5'],
    themeColors.current['brand'],
  ]);

  const chartOptions = useRef<ApexOptions>({
    chart: {
      type: 'bar',
      stacked: true,
      fontFamily: 'Roboto Condensed, sans-serif',
      foreColor: themeColors.current['dark-3'] as string,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: true,
    },
    stroke: {
      show: false,
    },
    colors: chartColors.current,
    xaxis: {
      position: 'top',
      labels: {
        style: {
          fontWeight: 700,
        },
      },
    },
    yaxis: {
      title: {
        text: undefined,
      },
      labels: {
        style: {
          fontWeight: 700,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '14px',
      },
    },
    legend: {
      showForSingleSeries: true,
      showForZeroSeries: false,
      fontSize: '16px',
      position: 'bottom',
      markers: {
        radius: 10,
      },
    },
  });

  /* Effects */
  useEffect(() => {
    if (!data?.facilities) return;

    const facilities: TFacilitiesData = {};

    const dataFacilities = data.facilities
      .sort(
        (a, b) =>
          b.equipment.map((e) => e.amount).reduce((total, amount) => total + amount, 0) -
          a.equipment.map((e) => e.amount).reduce((total, amount) => total + amount, 0)
      )
      .filter((_, i) => i < 10);

    for (const facility of Object.values(dataFacilities)) {
      const facilityName =
        facility.name ||
        `${standardizeFacilityAddress(facility.address.line1)}, ${facility.address.city}, ${
          facility.address.region_short_code
        } ${facility.address.post_code}`;

      facilities[facilityName] ??= {};

      for (const equipment of facility.equipment) {
        facilities[facilityName][equipment.category] = equipment.amount;
      }
    }

    setFacilitiesData(facilities);

    const seriesData: ApexAxisChartSeries = [];

    for (const facilityName in facilities) {
      for (const categoryName in facilities[facilityName]) {
        const existingIndex = seriesData.findIndex((s) => s.name === categoryName);

        if (existingIndex > -1) {
          const existingData = seriesData[existingIndex].data as number[];
          existingData.push(facilities[facilityName][categoryName]);
          seriesData[existingIndex].data = existingData;
        } else {
          const newData = {
            name: categoryName,
            data: [facilities[facilityName][categoryName]],
          };
          seriesData.push(newData);
        }
      }
    }

    setSeriesData(
      seriesData.filter((datum) => !!datum.data.reduce((total, amount) => ((total as number) += amount as number)))
    );
  }, [data]);

  /* Render */
  return (
    <Card flex="grow" height={height || cardHeight.current} {...boxProps}>
      <CardHeader>
        <Text size="xlarge">{chartTitle.current}</Text>
      </CardHeader>
      <CardBody pad={{ horizontal: 'medium', bottom: '2rem' }}>
        {!seriesData || !chartLabels ? (
          <LoadingSpinner />
        ) : (
          <Box width="100%" flex="grow">
            <Chart
              options={{
                ...chartOptions.current,
                xaxis: {
                  ...chartOptions.current.xaxis,
                  categories: chartLabels,
                },
              }}
              series={seriesData}
              type="bar"
              width="100%"
              height="100%"
            />
          </Box>
        )}
      </CardBody>
    </Card>
  );
};

export type EquipmentByFacilityChartProps = CardProps & {
  data: DashboardData;
};
