import React, { PropsWithChildren } from 'react';
import { Box } from './Box';

export const InfoBox: React.FC<InfoBoxProps> = ({ children }) => {
  return (
    <Box
      pad="1rem"
      gap="0.5rem"
      background="light-6"
      border={{ size: 'small', color: 'light-5' }}
      borderRadius="6px"
      direction="row"
      align="center"
      fontFamily="Lato, sans-serif"
    >
      {children}
    </Box>
  );
};

export type InfoBoxProps = PropsWithChildren;
