import { DashboardData, TClientId } from '/src/lib/models';
import { CountryName } from '/src/lib/types';

/**
 * Get Admin Dashboard Data
 */
export type TGetAdminDashboardDataRequest = {
  country: CountryName;
};

export type TGetAdminDashboardDataResponse = DashboardData;

/**
 * Get Client Dashboard Data
 */
export type TGetClientDashboardDataRequest = {
  client_id: TClientId;
};

export type TGetClientDashboardDataResponse = DashboardData;
