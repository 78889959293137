import type {
  CancelablePromise,
  TCreateSalesCommissionRequest,
  TCreateSalesCommissionResponse,
  TGetSalesCommissionRequest,
  TGetSalesCommissionResponse,
  TListSalesCommissionsRequest,
  TListSalesCommissionsResponse,
  TUpdateSalesCommissionRequest,
  TUpdateSalesCommissionResponse,
} from '/src/api';
import { OpenAPI, request as __request } from '/src/api';

export class SalesCommissionService {
  /**
   * List Sales Commissions
   * @returns SalesCommission[] SalesCommission[]
   * @throws ApiError
   */
  public static listSalesCommissions(
    req?: TListSalesCommissionsRequest
  ): CancelablePromise<TListSalesCommissionsResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/sales-commissions',
      query: req?.page ? { page: req.page } : undefined,
    });
  }

  /**
   * Get Sales Commission
   * @param email string
   * @param password string
   * @returns SalesCommission SalesCommission
   * @throws ApiError
   */
  public static getSalesCommission({ id }: TGetSalesCommissionRequest): CancelablePromise<TGetSalesCommissionResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/sales-commissions/{id}',
      path: { id },
    });
  }

  /**
   * Create Sales Commission
   * @param salesCommission SalesCommission
   * @returns SalesCommission SalesCommission
   * @throws ApiError
   */
  public static createSalesCommission(
    req: TCreateSalesCommissionRequest
  ): CancelablePromise<TCreateSalesCommissionResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/sales-commissions',
      body: req,
    });
  }

  /**
   * Update Sales Commission
   * @param salesCommission SalesCommission
   * @returns SalesCommission SalesCommission
   * @throws ApiError
   */
  public static updateSalesCommission(
    req: TUpdateSalesCommissionRequest
  ): CancelablePromise<TUpdateSalesCommissionResponse> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/sales-commissions/{id}',
      path: { id: req.id },
      body: req,
    });
  }
}
