import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useGlobalStore, useUserStore } from '/src/context';

const Logout = observer(() => {
  const userStore = useUserStore();
  const globalStore = useGlobalStore();

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    globalStore.setPreviousPath('');
    userStore.logout();
    setIsLoading(false);
  }, []);

  return isLoading ? <></> : <Navigate to="/login" replace />;
});

export default Logout;
