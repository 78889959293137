import type {
  CancelablePromise,
  TGetModelAuditLogRequest,
  TGetModelAuditLogResponse,
  TListModelAuditLogsRequest,
  TListModelAuditLogsResponse,
  TListModelAuditLogsStatesRequest,
  TListModelAuditLogsStatesResponse,
  TUpdateModelAuditLogStateRequest,
  TUpdateModelAuditLogStateResponse,
} from '/src/api';
import { OpenAPI, request as __request } from '/src/api';

export class ModelAuditLogService {
  /**
   * List Model Audit Logs
   * @throws ApiError
   */
  public static list(req?: TListModelAuditLogsRequest): CancelablePromise<TListModelAuditLogsResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/modelAuditLogs',
      query: req,
    });
  }

  /**
   * List Model Audit Log States
   * @throws ApiError
   */
  public static listStates(
    req?: TListModelAuditLogsStatesRequest
  ): CancelablePromise<TListModelAuditLogsStatesResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/modelAuditLogStates',
      query: req,
    });
  }
  /**
   * Get Model Audit Log
   * @throws ApiError
   */
  public static get({ id }: TGetModelAuditLogRequest): CancelablePromise<TGetModelAuditLogResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/modelAuditLogs/{id}',
      path: { id },
    });
  }

  /**
   * Update Model Audit Log State
   * @throws ApiError
   */
  public static updateState({
    model_audit_log_id,
    ...req
  }: TUpdateModelAuditLogStateRequest): CancelablePromise<TUpdateModelAuditLogStateResponse> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/modelAuditLogs/{model_audit_log_id}/updateState',
      path: { model_audit_log_id },
      body: req,
    });
  }
}
