import type {
  CancelablePromise,
  TFinalizeClientReportingPeriodRequest,
  TFinalizeClientReportingPeriodResponse,
  TListClientReportingPeriodsRequest,
  TListClientReportingPeriodsResponse,
  TPreviewFinalizedReportingPeriodRequest,
  TPreviewFinalizedReportingPeriodResponse,
} from '/src/api';
import { OpenAPI, request as __request } from '/src/api';

export class ClientReportingPeriodService {
  /**
   * List Client Reporting Periods
   */
  public static list({
    client_id,
    ...req
  }: TListClientReportingPeriodsRequest): CancelablePromise<TListClientReportingPeriodsResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/clients/{client_id}/clientReportingPeriods',
      path: { client_id },
      query: req,
    });
  }

  /**
   * Finalize Client Reporting Period
   */
  public static finalize({
    id,
  }: TFinalizeClientReportingPeriodRequest): CancelablePromise<TFinalizeClientReportingPeriodResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/clientReportingPeriods/{id}/finalize',
      path: { id },
    });
  }

  /**
   * Preview Finalized Reporting Period Results
   */
  public static preview({
    id,
  }: TPreviewFinalizedReportingPeriodRequest): CancelablePromise<TPreviewFinalizedReportingPeriodResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/clientReportingPeriods/{id}/previewResults',
      path: { id },
    });
  }
}
