import type {
  CancelablePromise,
  OpenAPIConfig,
  TCreateUserRequest,
  TCreateUserResponse,
  TGeneratePasswordResetResponse,
  TGetUserRequest,
  TGetUserResponse,
  TListUsersRequest,
  TListUsersResponse,
  TRequestPasswordResetRequest,
  TResetPasswordRequest,
  TUpdatePasswordRequest,
  TUpdateUserEmailRequest,
  TUpdateUserEmailResponse,
  TUpdateUserRequest,
  TUpdateUserResponse,
} from '/src/api';
import { OpenAPI, request as __request } from '/src/api';
import { TUserId } from '/src/lib/models';

export class UserService {
  /**
   * List Users
   * @returns User[] User[]
   * @throws ApiError
   */
  public static list(req?: TListUsersRequest): CancelablePromise<TListUsersResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/users',
      query: req,
    });
  }

  /**
   * Get User
   * @param email string
   * @param password string
   * @returns User User
   * @throws ApiError
   */
  public static get({ id }: TGetUserRequest, options?: OpenAPIConfig): CancelablePromise<TGetUserResponse> {
    return __request(options ?? OpenAPI, {
      method: 'GET',
      url: '/users/{id}',
      path: { id },
    });
  }

  /**
   * Create User
   * @param user User
   * @returns User User
   * @throws ApiError
   */
  public static create(req: TCreateUserRequest): CancelablePromise<TCreateUserResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/users',
      body: req,
    });
  }

  /**
   * Update User
   * @param user User
   * @returns User User
   * @throws ApiError
   */
  public static update(id: TUserId, req: TUpdateUserRequest): CancelablePromise<TUpdateUserResponse> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/users/{id}',
      path: { id },
      body: req,
    });
  }

  /**
   * Update User
   * @param user User
   * @returns User User
   * @throws ApiError
   */
  public static updateEmail(id: TUserId, req: TUpdateUserEmailRequest): CancelablePromise<TUpdateUserEmailResponse> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/users/{id}/updateEmail',
      path: { id },
      body: req,
    });
  }

  /**
   * Update Password
   * @param request TUpdatePasswordRequest
   * @returns object JSONWebToken
   * @throws ApiError
   */
  public static updatePassword(id: TUserId, req: TUpdatePasswordRequest): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/users/{id}/updatePassword',
      path: { id },
      body: req,
    });
  }

  /**
   * Reset Password
   * @param request TResetPasswordRequest
   * @returns object JSONWebToken
   * @throws ApiError
   */
  public static resetPassword(token: string, req: TResetPasswordRequest): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/users/updateForgottenPassword/{token}',
      path: { token },
      body: req,
    });
  }

  /**
   * Send Password Reset Email
   * @param request TRequestPasswordResetRequest
   * @returns object JSONWebToken
   * @throws ApiError
   */
  public static requestPasswordReset(req: TRequestPasswordResetRequest): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/users/forgotPassword',
      body: req,
    });
  }

  /**
   * Generate Password Reset URL
   * @param request TRequestPasswordResetRequest
   * @returns object JSONWebToken
   * @throws ApiError
   */
  public static generatePasswordResetUrl(user_id: TUserId): CancelablePromise<TGeneratePasswordResetResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/users/{user_id}/generatePasswordResetUrl',
      path: { user_id },
    });
  }

  /**
   * Verify Email Address
   * @throws ApiError
   */
  public static verifyEmail(token: string): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/users/verifyEmail/{token}',
      path: { token },
    });
  }
}
