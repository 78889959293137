import { CaretDownFill, CaretUpFill, Hide, StatusGood, View } from 'grommet-icons';
import { ThemeType, grommet } from 'grommet/themes';
import { deepMerge } from 'grommet/utils';

const customTheme: ThemeType = {
  global: {
    colors: {
      brand: 'rgb(234, 173, 71)', // #EAAD47
      'brand-dark': 'rgb(224, 158, 71)',
      control: 'accent-1',
      'accent-1': 'rgb(49, 99, 153)', // #316399
      'accent-2': 'rgb(52, 54, 72)', // #343648
      'accent-3': 'rgb(77, 152, 235)', // #4D98EB
      'accent-4': 'rgb(24, 100, 158)', // #18649E
      'accent-5': 'rgb(170, 34, 92)', // #AA225C
      'accent-6': 'rgb(65, 110, 144)',
      text: 'rgb(33, 37, 41)', // #212529
      'light-1': 'rgb(170, 178, 188)', // #AAB2BC
      'light-2': 'rgb(201, 208, 216)', // #C9D0D8
      'light-3': 'rgb(108, 117, 125)', // #6C757D
      'light-4': 'rgb(234, 236, 239)', // #EAECEf
      'light-5': 'rgb(210, 216, 223)', // #D2D8DF
      'light-6': 'rgb(244, 246, 248)', // #F4F6F8
      'dark-1': 'rgb(81, 94, 107)',
      'dark-2': 'rgb(41, 38, 56)',
      'dark-3': 'rgb(33, 37, 41)',
      'dark-4': 'rgb(66, 73, 90)',
      'button-hover': 'rgb(42, 86, 134)', // #2A5686
    },
    focus: {
      outline: {
        size: '0',
        // size: '1px',
        color: 'accent-1',
      },
    },
    animation: {
      duration: '500',
    },
    font: {
      family: 'Roboto Condensed, sans-serif',
    },
    backgrounds: {
      light: 'linear-gradient(rgb(253, 253, 254) 0%, rgb(241, 243, 246) 300px, rgb(241, 243, 246) 100%)',
    },
    breakpoints: {
      small: {
        value: 1000,
      },
    },
    input: {
      font: {
        weight: 400,
        size: '16px',
      },
    },
    borderSize: {
      small: '0.8px',
    },
    size: {
      xxsmall: '0.5rem',
      xsmall: '0.75rem',
      small: '0.875rem',
      medium: '1rem',
      large: '1.5rem',
      xlarge: '2rem',
      xxlarge: '2.5rem',
    },
    control: {
      border: {
        color: 'light-2',
        radius: '6px',
        width: '0.8px',
      },
    },
  },
  accordion: {
    border: undefined,
    // icons: {
    //   expand: View,
    //   collapse: Hide,
    // },
  },
  tip: {
    content: {
      background: 'white',
    },
  },
  text: {
    xsmall: {
      size: '0.75rem', // 12px
      height: '1.5rem',
    },
    small: {
      size: '0.875rem', // 14px
      height: '1.5rem',
    },
    medium: {
      size: '1rem', // 16px
      height: '1.5rem',
    },
    large: {
      size: '1.25rem', // 20px
      height: '1.5rem',
    },
    xlarge: {
      size: '1.5rem', // 24px
      height: '1.5rem',
    },
    xxlarge: {
      size: '2.5rem', // 40px
      height: '3rem',
    },
  },
  formField: {
    border: {
      color: 'light-5',
      size: 'small',
      side: 'all',
    },
    round: '6px',
    content: {
      pad: {
        horizontal: '0.75rem',
        vertical: '0.5rem',
      },
    },
    label: {
      size: 'small',
      color: 'accent-1',
    },
    focus: {
      border: {
        color: 'accent-1',
      },
    },
    disabled: {
      background: 'light-6',
      border: {
        color: 'light-6',
      },
      label: {
        color: 'light-3',
      },
    },
    error: {
      border: {
        color: 'red',
        size: 'small',
        side: 'all',
      },
    },
  },
  textArea: {
    disabled: 1,
  },
  textInput: {
    disabled: {
      opacity: 1,
    },
  },
  maskedInput: {
    disabled: {
      opacity: 1,
    },
  },
  spinner: {
    container: {
      animation: { type: 'rotateRight', duration: 600 },
    },
  },
  button: {
    border: {
      radius: '6px',
      color: 'light-2',
      width: '0.05rem',
    },
    padding: {
      horizontal: '0.75rem',
      vertical: '0.75rem',
    },
    primary: {
      background: 'accent-1',
      color: 'white',
    },
  },
  card: {
    container: {
      flex: 'grow',
      background: 'white',
      border: {
        color: 'light-2',
        size: 'small',
      },
      round: '6px',
      elevation: 'xsmall',
    },
    header: {
      border: { color: 'light-2', side: 'bottom', size: 'small' },
      pad: '1.5rem',
      gap: '0.5rem',
      direction: 'row',
      justify: 'start',
    },
    body: {
      gap: '1rem',
    },
  },
  checkBox: {
    border: {
      color: 'light-5',
      width: '1px',
    },
    hover: {
      border: {
        color: 'dark-1',
      },
    },
    check: {
      radius: '6px',
    },
    color: 'dark-1',
  },
  select: {
    options: {
      container: {
        style: { lineHeight: '24px' },
        pad: '0.75rem',
      },
    },
    icons: {
      down: <CaretDownFill />,
      up: <CaretUpFill />,
    },
  },
  radioButton: {
    size: '1rem',
    icons: {
      circle: StatusGood,
    },
  },
};

export default deepMerge(grommet, customTheme);
