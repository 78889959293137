import {
  CancelablePromise,
  OpenAPI,
  TListEquipmentCategoriesRequest,
  TListEquipmentCategoriesResponse,
  request as __request,
} from '/src/api';

export class EquipmentCategoryService {
  /**
   * List Equipment Categories
   * @returns EquipmentCategory[] EquipmentCategory[]
   * @throws ApiError
   */
  public static listEquipmentCategories(
    req?: TListEquipmentCategoriesRequest
  ): CancelablePromise<TListEquipmentCategoriesResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/equipmentCategories',
      query: req,
    });
  }
}
