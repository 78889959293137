import { DateString, FloatString, Nullable } from '../types';
import {
  Client,
  EquipmentType,
  EquipmentUsage,
  FSERegistrationStatus,
  Facility,
  FuelPathway,
  TClientId,
  TEquipmentTypeId,
  TFSERegistrationStatusId,
  TFacilityId,
  TFuelPathwayId,
  TReportingPeriodTypeId,
} from '/src/lib/models';

export type TEquipmentId = number;

export interface Equipment {
  id: TEquipmentId;
  client?: Client;
  client_id: TClientId;
  facility?: Facility;
  facility_id: TFacilityId;
  name: Nullable<string>;
  equipment_type: EquipmentType;
  equipment_type_id: TEquipmentTypeId;
  fuel_pathway: FuelPathway;
  fuel_pathway_id: TFuelPathwayId;
  unit_number: Nullable<string>;
  serial_number: string;
  manufacturer: string;
  model_number: Nullable<string>;
  model_year: number;
  is_active: boolean;
  first_active_reporting_quarter: DateString;
  first_day_in_service: Nullable<DateString>;
  last_active_reporting_quarter: Nullable<DateString>;
  reporting_period_type_id?: TReportingPeriodTypeId;
  is_metered: boolean;
  battery_capacity_rating_ah?: number;
  voltage?: number;
  is_book_and_claim_applied: boolean;
  used_for_charging: string;
  charging_level: string;
  charging_usage_type: string;
  connector_type: string;
  rated_capacity: string;
  latitude: FloatString;
  longitude: FloatString;
  fse_registration_status: FSERegistrationStatus;
  fse_registration_status_id: TFSERegistrationStatusId;
  fse_id: Nullable<string>;
  fse_ru: Nullable<string>;
  comments: Nullable<string>;
  duplicate_of_equipment: Nullable<string>;
  equipment_usages: EquipmentUsage[];
  is_finalized: boolean;
}
