import { observer } from 'mobx-react-lite';
import React from 'react';
import { Box, Text } from '/src/components';

export const UsagesDataTableHeader: React.FC<UsagesDataTableHeaderProps> = observer((props) => {
  const { rowLabels, width, quarterColumnWidth, showQuarterColumn } = props;

  return (
    <Box row>
      <Box width="3rem" align="center" border={{ side: 'all', size: '0.4px', color: 'light-2' }} pad="small" />
      {showQuarterColumn && (
        <Box
          width={`${quarterColumnWidth}%`}
          align="center"
          border={{ side: 'all', size: '0.4px', color: 'light-2' }}
          pad="small"
        >
          <Text size="small" weight={300} toUpperCase>
            Quarter
          </Text>
        </Box>
      )}
      {rowLabels.map((label) => (
        <Box
          key={label}
          width={width}
          align="center"
          border={{ side: 'all', size: '0.4px', color: 'light-2' }}
          pad="small"
        >
          <Text size="small" weight={300} toUpperCase>
            {label}
          </Text>
        </Box>
      ))}
    </Box>
  );
});

export type UsagesDataTableHeaderProps = {
  rowLabels: string[];
  width: string;
  quarterColumnWidth: number;
  showQuarterColumn?: boolean;
};
