import { Text as GrommetText, TextProps as GrommetTextProps } from 'grommet';
import React, { CSSProperties, PropsWithChildren } from 'react';
import { pxToRem } from '/src/utils';

export const Text: React.FC<TextProps> = (props) => {
  const {
    children,
    lineHeight,
    fontFamily,
    letterSpacing,
    toUpperCase,
    convertToRems,
    size,
    style,
    transitionDuration,
    transitionProperty,
    transitionTimingFunction,
    ...textProps
  } = props;

  const remLineHeight = convertToRems && lineHeight ? pxToRem(lineHeight) : undefined;
  const remLetterSpacing = convertToRems && letterSpacing ? pxToRem(letterSpacing) : undefined;
  const remSize = convertToRems && size ? pxToRem(size) : undefined;

  const customStyles: Record<string, string> = {};
  if (lineHeight) customStyles.lineHeight = remLineHeight ?? lineHeight;
  if (letterSpacing) customStyles.letterSpacing = remLetterSpacing ?? letterSpacing;
  if (fontFamily) customStyles.fontFamily = fontFamily;
  if (toUpperCase) customStyles.textTransform = 'uppercase';
  if (transitionDuration) customStyles.transitionDuration = transitionDuration;
  if (transitionProperty) customStyles.transitionProperty = transitionProperty;
  if (transitionTimingFunction) customStyles.transitionTimingFunction = transitionTimingFunction;

  return (
    <GrommetText style={{ ...customStyles, ...style }} size={remSize ?? size} {...textProps}>
      {children}
    </GrommetText>
  );
};

export type TextProps = PropsWithChildren &
  GrommetTextProps & {
    lineHeight?: string;
    fontFamily?: string;
    letterSpacing?: string;
    toUpperCase?: boolean;
    convertToRems?: boolean;
    size?: string;
    style?: CSSProperties;
    transitionDuration?: string;
    transitionProperty?: string;
    transitionTimingFunction?: string;
  };
