import { Button, ButtonProps, ResponsiveContext } from 'grommet';
import { useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, BoxProps, Text } from '/src/components';

export const AddButton: React.FC<AddButtonProps> = (props) => {
  const { label, targetUrl, onClick, color, icon, disabled, reverse, tip, toUpperCase = true, ...boxProps } = props;
  const navigate = useNavigate();
  const screenSize = useContext(ResponsiveContext);

  const [isHover, setIsHover] = useState(false);

  const isMobile = useMemo(() => screenSize === ('small' || 'xsmall'), [screenSize]);

  return (
    <Box
      height={isMobile ? undefined : 'xxlarge'}
      alignSelf="center"
      onMouseOver={() => setIsHover(true)}
      onMouseOut={() => setIsHover(false)}
      style={{ boxShadow: isHover ? 'rgba(0, 0, 0, 0.2) 0 10px 10px -10px' : '' }}
      transitionProperty="background, color, box-shadow, padding-bottom"
      transitionDuration="0.15s, 0.15s, 0.15s, 0.15s"
      transitionTimingFunction="ease, ease, ease, ease"
      background="white"
      direction="row"
      pad={{ horizontal: '0.75rem', vertical: isMobile ? '0.5rem' : undefined, bottom: isHover ? '1px' : undefined }}
      gap="xsmall"
      border={{ color: 'light-5', size: '0.8px' }}
      borderRadius="6px"
      {...boxProps}
    >
      <Button plain disabled={disabled} tip={tip} onClick={() => (onClick ? onClick() : navigate(targetUrl ?? ''))}>
        <Box direction="row" gap="xsmall" align="center">
          {icon && !reverse && <Box justify="center">{icon}</Box>}
          {label && (
            <Text
              textAlign="center"
              alignSelf="center"
              size={isMobile ? 'small' : 'medium'}
              lineHeight={isMobile ? '1rem' : undefined}
              color={color}
              toUpperCase={toUpperCase}
            >
              {label}
            </Text>
          )}
          {icon && reverse && <Box justify="center">{icon}</Box>}
        </Box>
      </Button>
    </Box>
  );
};

export type AddButtonProps = BoxProps & {
  label?: string;
  targetUrl?: string;
  onClick?: () => void;
  color?: string;
  icon?: React.ReactNode;
  disabled?: boolean;
  reverse?: boolean;
  toUpperCase?: boolean;
  tip?: ButtonProps['tip'];
};
