import { TRegionId, Utility } from '/src/lib/models';

/**
 * List Utilities
 */
export type TListUtilitiesRequest = {
  region_id?: TRegionId;
};

export type TListUtilitiesResponse = Utility[];
