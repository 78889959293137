import { Client, ReferralPartner } from '.';
import { DateString } from '../types';

export type TReferralCommissionId = number;

export interface ReferralCommission {
  id: TReferralCommissionId;
  client: Client;
  referral_partner: ReferralPartner;
  reporting_quarter: DateString;
  percent_rate: number;
  payment_structure: string;
  start_date: DateString;
  end_date?: DateString;
}
