/**
 * via https://mui.com/material-ui/material-icons/?query=vehicle&selected=ElectricCar
 */
import { IconProps } from 'grommet-icons';
import React from 'react';

export const EVIcon: React.FC<IconProps> = ({ size = '24', color = '#EAAD47' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    enableBackground="new 0 0 24 24"
    height={size}
    viewBox="0 0 24 24"
    width={size}
  >
    <g>
      <rect fill="none" height={size} width={size} />
    </g>
    <g>
      <path
        fill={color}
        d="M18.92,2.01C18.72,1.42,18.16,1,17.5,1h-11C5.84,1,5.29,1.42,5.08,2.01L3,8v8c0,0.55,0.45,1,1,1h1c0.55,0,1-0.45,1-1v-1h12 v1c0,0.55,0.45,1,1,1h1c0.55,0,1-0.45,1-1V8L18.92,2.01z M6.5,12C5.67,12,5,11.33,5,10.5S5.67,9,6.5,9S8,9.67,8,10.5 S7.33,12,6.5,12z M17.5,12c-0.83,0-1.5-0.67-1.5-1.5S16.67,9,17.5,9S19,9.67,19,10.5S18.33,12,17.5,12z M5,7l1.5-4.5h11L19,7H5z"
      />
      <polygon fill={color} points="7,20 11,20 11,18 17,21 13,21 13,23" />
    </g>
  </svg>
);
