import dayjs from 'dayjs';
import { DateInput as DateInputField, Keyboard, MaskedInput } from 'grommet';
import { Calendar } from 'grommet-icons';
import React from 'react';
import { Box, BoxProps, Text } from '/src/components';

export const DateInput: React.FC<DateInputProps> = (props) => {
  const {
    label,
    value,
    setValue,
    dateFormat,
    error,
    placeholder,
    required,
    hideOptionalText,
    hideRequiredMarker,
    disabled,
    onSubmit,
    ...boxProps
  } = props;

  return (
    <Box {...boxProps}>
      <Box
        border={disabled ? undefined : { color: error ? 'red' : 'light-5', size: '0.8px' }}
        background={disabled ? 'light-6' : undefined}
        borderRadius="6px"
        pad={{ horizontal: '0.75rem', vertical: '0.5rem' }}
        direction="row"
        justify="between"
        height="58px"
      >
        <Box alignSelf="center">
          <Text size="0.875rem" color={disabled ? 'light-3' : 'accent-1'}>
            {label}
            {required && !hideRequiredMarker && '*'}
          </Text>
          <Keyboard onEnter={onSubmit}>
            <MaskedInput
              value={value}
              onChange={(e) => setValue(e.target.value)}
              mask={[
                {
                  length: 4,
                  regexp: /^[1-2]$|^19$|^20$|^19[0-9]$|^20[0-9]$|^19[0-9][0-9]$|^20[0-9][0-9]$/,
                  placeholder: 'yyyy',
                },
                { fixed: '-' },
                {
                  length: [1, 2],
                  regexp: /^1[0,1-2]$|^0?[1-9]$|^0$/,
                  placeholder: 'mm',
                },
                { fixed: '-' },
                {
                  length: [1, 2],
                  regexp: /^[1-2][0-9]$|^3[0-1]$|^0?[1-9]$|^0$/,
                  placeholder: 'dd',
                },
              ]}
              plain
              style={{
                fontSize: '0.875rem',
                fontFamily: 'Lato, sans-serif',
                fontWeight: 400,
                padding: 0,
              }}
              required={required}
              disabled={disabled}
            />
          </Keyboard>
        </Box>

        <Box alignSelf="center">
          <DateInputField
            icon={<Calendar size="20px" color="text" />}
            value={value}
            onChange={({ value }) => setValue(dayjs(typeof value === 'string' ? value : value[0]).format('YYYY-MM-DD'))}
            calendarProps={{
              style: { fontFamily: 'Lato, sans-serif', borderRadius: '6px' },
              margin: { bottom: '0.5rem' },
              fill: true,
            }}
          />
        </Box>
      </Box>
      {error && (
        <Box row gap="small" margin={{ top: '0.5rem', left: '0.75rem' }}>
          <Text color="red" size="0.75rem">
            {error}
          </Text>
        </Box>
      )}
    </Box>
  );
};

export type DateInputProps = BoxProps & {
  label: string;
  value: string | undefined;
  setValue: (value: string) => void;
  dateFormat?: string;
  error?: string;
  type?: string;
  placeholder?: string;
  required?: boolean;
  hideOptionalText?: boolean;
  hideRequiredMarker?: boolean;
  disabled?: boolean;
  onSubmit?: (event: React.KeyboardEvent<HTMLElement>) => void;
};
