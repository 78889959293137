import { TProgramId, TReportingPeriodTypeId } from '/src/lib/models';
import { DateString, FloatString } from '/src/lib/types';

export type TProgramBrokerFeeRateId = number;

export interface ProgramBrokerFeeRate {
  id: TProgramBrokerFeeRateId;
  created_at: DateString;
  updated_at: DateString;
  program_id: TProgramId;
  reporting_period_type_id: TReportingPeriodTypeId;
  start_reporting_quarter: DateString;
  end_reporting_quarter: DateString;
  percent_rate: FloatString;
}
