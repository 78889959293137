import dayjs from 'dayjs';
import { Box, BoxProps, ResponsiveContext } from 'grommet';
import { CircleAlert, StatusGood } from 'grommet-icons';
import { useContext } from 'react';
import { Text, UserStatusSelect } from '/src/components';
import { useUserStore } from '/src/context';
import { User } from '/src/lib/models';
import { EntityStatus } from '/src/lib/types';

export const UserInfoBar: React.FC<UserInfoBarProps> = (props) => {
  const { isFuseAdmin } = useUserStore();

  const { user, isActive, userCanEdit, setIsActive, ...boxProps } = props;

  const screenSize = useContext(ResponsiveContext);
  const isMobile = screenSize === ('small' || 'xsmall');

  return (
    <Box
      id="info-bar"
      direction={isMobile ? 'column' : 'row'}
      justify="between"
      gap="medium"
      margin={{ vertical: '2rem' }}
      pad={{ vertical: '1rem' }}
      border={{ color: 'light-2', side: 'horizontal', size: 'small' }}
      {...boxProps}
    >
      <Box justify="center" gap="xsmall" width={isMobile ? undefined : '25%'}>
        <Text color="dark-4" size="medium">
          Status
        </Text>
        {isFuseAdmin ? (
          <UserStatusSelect isActive={isActive} setIsActive={setIsActive} userCanEdit={userCanEdit} />
        ) : (
          <Box direction="row">
            <Box alignSelf="center">
              {isActive ? <StatusGood color="green" size="16px" /> : <CircleAlert size="16px" />}
            </Box>
            <Text alignSelf="center" margin={{ left: '0.25rem' }} size="medium" fontFamily="Lato, sans-serif">
              {isActive ? EntityStatus.Active : EntityStatus.Inactive}
            </Text>
          </Box>
        )}
      </Box>
      <Box width="10%" />
      <Box justify="center" gap="xsmall" flex="grow">
        <Text color="dark-4" size="medium">
          Last Login
        </Text>
        <Box direction="row">
          <Text alignSelf="center" size="medium" fontFamily="Lato, sans-serif">
            {user.last_login_at ? dayjs(user.last_login_at).format('MM/DD/YYYY @ h:mm A') : 'Never'}
          </Text>
        </Box>
      </Box>
      <Box justify="center" gap="xsmall" flex="grow">
        <Text color="dark-4" size="medium">
          Created
        </Text>
        <Box direction="row">
          <Text alignSelf="center" size="medium" fontFamily="Lato, sans-serif">
            {user.created_at ? dayjs(user.created_at).format('MM/DD/YYYY @ h:mm A') : 'Unknown'}
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export type UserInfoBarProps = BoxProps & {
  user: User;
  isActive: boolean;
  userCanEdit: boolean;
  setIsActive: (isActive: boolean) => void;
};
