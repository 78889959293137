import { Button, CardFooter } from 'grommet';
import { Close } from 'grommet-icons';
import React, { useEffect, useState } from 'react';
import { Box, BoxProps, Card, CardBody, CardHeader, Input, LoadingSpinner, Select, Text } from '/src/components';
import { UserService } from '/src/api';
import { useClientStore, useGlobalStore } from '/src/context';
import { SalesCommission } from '/src/lib/models';
import { toastMessages } from '/src/lib/toast';
import { SalesCommissionDataForm } from '/src/lib/types';

export const defaultFormData: SalesCommissionDataForm = {
  user_id: NaN,
  percent_rate: '0',
  start_reporting_quarter: '',
  end_reporting_quarter: '',
  comments: '',
};

export const SalesCommissionModal: React.FC<SalesCommissionModalProps> = (props) => {
  const { salesCommission, setIsVisible, ...boxProps } = props;
  const { selectedClientUsers } = useClientStore();
  const { roles, handleApiError } = useGlobalStore();

  const [isUpdating, setIsUpdating] = useState(false);
  const [formErrors, setFormErrors] = useState<Record<string, string>>({});
  const [formValues, setFormValues] = useState<SalesCommissionDataForm>(defaultFormData);

  useEffect(() => {
    UserService.get({ id: salesCommission.user.id })
      .then((user) => {
        setFormValues({
          user_id: user.id,
          percent_rate: salesCommission.percent_rate,
          start_reporting_quarter: salesCommission.start_reporting_quarter,
          end_reporting_quarter: salesCommission.end_reporting_quarter,
          comments: salesCommission.comments,
        });
      })
      .catch((err) => handleApiError(err, toastMessages.fetchUser.error));
  }, []);

  return (
    <Card style={{ borderRadius: '8px' }} width="31.25rem" {...boxProps}>
      <CardHeader pad="1rem">
        <Box direction="row" justify="between" flex="grow" height="30px">
          <Text alignSelf="center" size="1.25rem">
            Commission Details
          </Text>
          <Button alignSelf="center" pad="0.5rem" onClick={() => setIsVisible(false)} icon={<Close size="20px" />} />
        </Box>
      </CardHeader>
      <CardBody>
        {!selectedClientUsers ? (
          <LoadingSpinner />
        ) : (
          <Box pad="1rem" gap="1rem">
            <Select
              label="Name"
              value={formValues.user_id as number}
              setValue={(value) =>
                setFormValues({
                  ...formValues,
                  user_id: value,
                })
              }
              options={[{ label: salesCommission.user.name, value: salesCommission.user.id }]}
              error={formErrors['user_id']}
              required
            />
            <Select
              label="Role"
              value={formValues.role_id as number}
              setValue={(value) =>
                setFormValues({
                  ...formValues,
                  role_id: value,
                })
              }
              options={roles.map((role) => ({
                label: role.name,
                value: role.id,
              }))}
              error={formErrors['role_id']}
              required
              disabled
            />
            <Box direction="row" gap="1rem">
              <Box width="33.3%">
                <Input
                  label="Commission"
                  value={formValues.percent_rate}
                  setValue={(value) =>
                    setFormValues({
                      ...formValues,
                      percent_rate: value,
                    })
                  }
                  error={formErrors['percent_rate']}
                  required
                  componentType="percent"
                  flex="grow"
                />
              </Box>
              <Box width="66.6%">
                <Select
                  label="Payment Structure"
                  value={formValues.payment_structure as string}
                  setValue={(value) =>
                    setFormValues({
                      ...formValues,
                      payment_structure: value,
                    })
                  }
                  options={[]}
                  error={formErrors['payment_structure']}
                  required
                  flex="grow"
                />
              </Box>
            </Box>
            <Box direction="row" justify="between" gap="1rem">
              {/* <DateInput
                label="Effective Start Date"
                value={formValues.start_date as string}
                setValue={(value) =>
                  setFormValues({
                    ...formValues,
                    start_date: value,
                  })
                }
                error={formErrors['start_date']}
                required
              /> */}
              {/* <DateInput
                label="Effective End Date"
                value={formValues.end_date as string}
                setValue={(value) =>
                  setFormValues({
                    ...formValues,
                    end_date: value,
                  })
                }
                error={formErrors['end_date']}
                required
              /> */}
            </Box>
          </Box>
        )}
      </CardBody>
      <CardFooter border={{ side: 'top', size: '0.8px', color: 'light-2' }} pad="1rem">
        <Box flex="grow">
          <Button onClick={() => updateSalesCommission()}>
            <Box
              pad="0.75rem"
              background={!isUpdating ? 'accent-1' : 'light-2'}
              border={{ color: 'light-2', size: '0.05rem' }}
              style={{ borderRadius: '6px', cursor: 'pointer' }}
            >
              <Text alignSelf="center" size="1rem" weight={500} color="white">
                {!isUpdating ? 'SAVE' : 'UPDATING...'}
              </Text>
            </Box>
          </Button>
        </Box>
      </CardFooter>
    </Card>
  );
};

export type SalesCommissionModalProps = BoxProps & {
  salesCommission: SalesCommission;
  setIsVisible: (isVisible: boolean) => void;
};
