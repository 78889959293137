import { TListResponse } from '/src/api/models';
import { IncentiveStatement, TClientId, TClientReportingPeriodId, TIncentiveStatementId } from '/src/lib/models';

/**
 * List Incentive Statements
 */
export type TListIncentiveStatementsRequest = {
  client_id: TClientId;
  page?: number;
  limit?: number;
  client_reporting_period_id?: TClientReportingPeriodId;
};

export type TListIncentiveStatementsResponse = TListResponse<IncentiveStatement[]>;

/**
 * Get Public Incentive Statement
 */
export type TGetPublicIncentiveStatementRequest = {
  slug: string;
};

export type TGetPublicIncentiveStatementResponse = IncentiveStatement;

/**
 * Get Incentive Statement
 */
export type TGetIncentiveStatementRequest = {
  id: TIncentiveStatementId;
};

export type TGetIncentiveStatementResponse = IncentiveStatement;
