import { StorageKeys } from '/src/lib/types';

class Storage {
  get(key: StorageKeys | string, defaultValue: any = '') {
    if (typeof window !== 'undefined') {
      let value = localStorage.getItem(key);
      if (value) return JSON.parse(value);
      return defaultValue;
    }
    return false;
  }

  set(key: StorageKeys | string, value: any) {
    if (typeof window !== 'undefined') {
      let valueToStr = JSON.stringify(value || '');
      localStorage.setItem(key, valueToStr);
    }
  }

  remove(key: StorageKeys | string) {
    if (typeof window !== 'undefined') {
      localStorage.removeItem(key);
    }
  }

  removeAll() {
    if (typeof window !== 'undefined') {
      localStorage.clear();
    }
  }
}

const storage = new Storage();

export default storage;
