import { TextInput, Tip } from 'grommet';
import { SettingsOption } from 'grommet-icons';
import { observer } from 'mobx-react-lite';
import React, { useRef } from 'react';
import { Box, BoxProps, Link, Text, UsagesDataTableHeader } from '/src/components';
import { Equipment, EquipmentUsage } from '/src/lib/models';
import { getQuarterFromDateString } from '/src/utils';

export const UsagesDataTable: React.FC<UsagesDataTableProps> = observer((props) => {
  const { formValues, updateFormValues, equipmentUsages, equipment, showQuarterColumn, isEditable, ...boxProps } =
    props;

  const quarterColumnWidth = useRef(8);
  const leftoverWidth = useRef(100 - quarterColumnWidth.current);
  const width = useRef(showQuarterColumn ? `${leftoverWidth.current / 6}%` : `${100 / 6}%`);

  const rowLabels = useRef([
    'Charge Cycles / Shift',
    'Shifts / Day',
    'Work Days / Quarter',
    'Charger Efficiency Rating',
    'Charge Return Factor',
    'Depth of Discharge',
  ]);

  const renderEditRow = (equipmentUsage: EquipmentUsage) => (
    <Box row background="light-6">
      <Box
        width="3rem"
        justify="center"
        align="center"
        border={{ side: 'all', size: '0.4px', color: 'light-2' }}
        pad="small"
      >
        <Link to={`/clients/${equipment?.client_id}/equipment/${equipment?.id}/usages/${equipmentUsage.id}`}>
          <Box align="center" justify="center">
            <Tip content={`View ${getQuarterFromDateString(equipmentUsage.start_reporting_quarter)} Usage Details`}>
              <SettingsOption size="20px" color="accent-1" />
            </Tip>
          </Box>
        </Link>
      </Box>
      {showQuarterColumn && (
        <Box
          width={`${quarterColumnWidth}%`}
          align="center"
          border={{ side: 'all', size: '0.4px', color: 'light-2' }}
          pad="small"
        >
          <Text toUpperCase weight={500}>
            {getQuarterFromDateString(equipmentUsage.start_reporting_quarter)}
          </Text>
        </Box>
      )}
      {['charge_cycles_per_shift', 'shifts_per_day', 'work_days_per_quarter'].map((valueKey, index) => (
        <Box
          key={index}
          width={width.current}
          align="center"
          border={{ side: 'all', size: '0.4px', color: 'light-2' }}
          pad="small"
          gap="xsmall"
        >
          <TextInput
            value={formValues[valueKey]}
            type="number"
            textAlign="center"
            onChange={(e) => (valueKey ? updateFormValues(valueKey, e.target.value) : undefined)}
            style={{ background: 'white', paddingTop: '8px', paddingBottom: '8px' }}
          />
        </Box>
      ))}
      {['percent_charger_efficiency_rating', 'percent_charge_return_factor', 'percent_depth_of_discharge'].map(
        (valueKey, index) => (
          <Box
            key={index}
            width={width.current}
            align="center"
            border={{ side: 'all', size: '0.4px', color: 'light-2' }}
            pad={{ vertical: 'small', horizontal: 'medium' }}
            gap="xsmall"
            row
          >
            <TextInput
              value={formValues[valueKey]}
              type="number"
              inputMode="numeric"
              textAlign="center"
              onChange={(e) => (valueKey ? updateFormValues(valueKey, e.target.value) : undefined)}
              style={{ background: 'white', paddingTop: '8px', paddingBottom: '8px' }}
            />
            <Text>%</Text>
          </Box>
        )
      )}
    </Box>
  );

  const renderRow = (equipmentUsage: EquipmentUsage, rowIndex: number) => {
    const renderCell = (value: string | number, index: number, isPercent?: boolean, cellWidth?: string) => (
      <Box
        key={index}
        width={cellWidth || width.current}
        align="center"
        border={{ side: 'all', size: '0.4px', color: 'light-2' }}
        pad="small"
      >
        <Text toUpperCase>
          {value}
          {isPercent && '%'}
        </Text>
      </Box>
    );
    const row = [
      renderCell(equipmentUsage.charge_cycles_per_shift ?? '-', 0, false),
      renderCell(equipmentUsage.shifts_per_day ?? '-', 1, false),
      renderCell(equipmentUsage.work_days_per_quarter ?? '-', 2, false),
      renderCell(equipmentUsage.percent_charger_efficiency_rating ?? '-', 3, true),
      renderCell(equipmentUsage.percent_charge_return_factor ?? '-', 4, true),
      renderCell(equipmentUsage.percent_depth_of_discharge ?? '-', 5, true),
    ];

    return (
      <Box key={rowIndex} row background={rowIndex % 2 ? 'white' : 'light-6'}>
        <Box
          width="3rem"
          justify="center"
          align="center"
          border={{ side: 'all', size: '0.4px', color: 'light-2' }}
          pad="small"
        >
          <Link to={`/clients/${equipment?.client_id}/equipment/${equipment?.id}/usages/${equipmentUsage.id}`}>
            <Box align="center" justify="center">
              <Tip content={`View ${getQuarterFromDateString(equipmentUsage.start_reporting_quarter)} Usage Details`}>
                <SettingsOption size="20px" color="accent-1" />
              </Tip>
            </Box>
          </Link>
        </Box>
        {showQuarterColumn && (
          <Box
            width={`${quarterColumnWidth}%`}
            align="center"
            border={{ side: 'all', size: '0.4px', color: 'light-2' }}
            pad="small"
          >
            <Text toUpperCase weight={500}>
              {getQuarterFromDateString(equipmentUsage.start_reporting_quarter)}
            </Text>
          </Box>
        )}
        {row}
      </Box>
    );
  };

  return (
    <Box border={{ side: 'all', size: '0.4px', color: 'light-2' }}>
      {/* Header */}
      <UsagesDataTableHeader
        rowLabels={rowLabels.current}
        width={width.current}
        quarterColumnWidth={quarterColumnWidth.current}
        showQuarterColumn={showQuarterColumn}
      />

      {/* Current Usage */}
      {isEditable && renderEditRow(equipmentUsages[0])}

      {/* Historical Usages */}
      {!isEditable && equipmentUsages.map(renderRow)}
    </Box>
  );
});

export type UsagesDataTableProps = BoxProps & {
  formValues: Record<string, string>;
  updateFormValue: (key: string, value: string) => void;
  equipmentUsages: EquipmentUsage[];
  equipment?: Equipment;
  showQuarterColumn?: boolean;
  isEditable?: boolean;
};
