import type { CancelablePromise } from '/src/api';
import { OpenAPI, request as __request } from '/src/api';
import { FSERegistrationStatus } from '/src/lib/models';

export class FSERegistrationStatusService {
  /**
   * List FSERegistrationStatuses
   * @returns FSERegistrationStatus[] FSERegistrationStatus[]
   * @throws ApiError
   */
  public static listFSERegistrationStatuses(): CancelablePromise<FSERegistrationStatus[]> {
    return __request(OpenAPI, { method: 'GET', url: '/fseRegistrationStatuses' });
  }
}
