import type {
  CancelablePromise,
  TCreateClientFileRequest,
  TCreateClientFileResponse,
  TGetClientFileRequest,
  TGetClientFileResponse,
  TListClientFilesRequest,
  TListClientFilesResponse,
  TUpdateClientFileRequest,
  TUpdateClientFileResponse,
} from '/src/api';
import { request as __request, OpenAPI } from '/src/api';

export class ClientFileService {
  /**
   * List ClientFiles
   * @returns ClientFile[] ClientFile[]
   * @throws ApiError
   */
  public static listClientFiles(req?: TListClientFilesRequest): CancelablePromise<TListClientFilesResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/client-files',
      query: req?.page ? { page: req.page } : undefined,
    });
  }

  /**
   * Get ClientFile
   * @param email string
   * @param password string
   * @returns ClientFile ClientFile
   * @throws ApiError
   */
  public static getClientFile({ id }: TGetClientFileRequest): CancelablePromise<TGetClientFileResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/client-files/{id}',
      path: { id },
    });
  }

  /**
   * Create ClientFile
   * @param clientFile ClientFile
   * @returns ClientFile ClientFile
   * @throws ApiError
   */
  public static createClientFile(req: TCreateClientFileRequest): CancelablePromise<TCreateClientFileResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/client-files',
      body: req,
    });
  }

  /**
   * Update ClientFile
   * @param clientFile ClientFile
   * @returns ClientFile ClientFile
   * @throws ApiError
   */
  public static updateClientFile(req: TUpdateClientFileRequest): CancelablePromise<TUpdateClientFileResponse> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/client-files/{id}',
      path: { id: req.id },
      body: req,
    });
  }
}
