import { SelectExtendedProps } from 'grommet';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { ApiError, UtilityService } from '/src/api';
import { BoxProps, Select } from '/src/components';
import { useGlobalStore, useUserStore } from '/src/context';
import { TRegionId, TUtilityId, Utility } from '/src/lib/models';
import { toastMessages } from '/src/lib/toast';
import { Nullable, SelectOption } from '/src/lib/types';

export const UtilitySelect: React.FC<UtilitiesSelectProps> = observer((props) => {
  const {
    utilities,
    value,
    setValue,
    error,
    required,
    fill,
    width,
    hideOptionalText,
    hideLabel,
    emptySearchMessage,
    searchPlaceholder,
    label,
    placeholder,
    regionId,
    id,
    name,
    disabled,
    onSubmit,
  } = props;

  /** Stores **/
  const globalStore = useGlobalStore();
  const userStore = useUserStore();

  /** State **/
  const [isLoadingUtilities, setIsLoadingUtilities] = useState(true);
  const [utilitiesList, setUtilitiesList] = useState<Utility[]>([]);
  const [defaultSelectOptions, setDefaultSelectOptions] = useState<SelectOption<TUtilityId>[]>([]);
  const [selectOptions, setSelectOptions] = useState<SelectOption<TUtilityId | undefined>[]>([]);

  /** Methods **/
  const fetchUtilitiesList = async (region_id: TRegionId) => {
    if (!userStore.user) return;

    try {
      setIsLoadingUtilities(true);

      const utilities = await UtilityService.listUtilities({ region_id });
      setUtilitiesList(utilities);

      const options = utilities.map((utility) => ({
        label: utility.name,
        value: utility.id,
      }));
      setSelectOptions(options);
      setDefaultSelectOptions(options);
    } catch (err) {
      globalStore.handleApiError(err as ApiError, toastMessages.listFacilities.error);
    } finally {
      setIsLoadingUtilities(false);
    }
  };

  const filterUtilities = (query: string) => {
    if (query === '') setSelectOptions(defaultSelectOptions);
    const exp = new RegExp(query.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&'), 'i');
    const filteredOptions = defaultSelectOptions.filter(({ label }) => exp.test(label));
    setSelectOptions(filteredOptions);
  };

  /** Effects **/
  useEffect(() => {
    if (isLoadingUtilities) {
      if (!utilitiesList.length) {
        if (utilities) {
          setUtilitiesList(utilities);
          const options = utilities.map((utility) => ({
            label: utility.name,
            value: utility.id,
          }));
          setSelectOptions(options);
          setDefaultSelectOptions(options);
        } else if (regionId) {
          fetchUtilitiesList(regionId);
        }
      } else {
        setIsLoadingUtilities(false);
      }
    }
  }, [utilities, utilitiesList, regionId, isLoadingUtilities, value]);

  useEffect(() => {
    if (regionId) {
      fetchUtilitiesList(regionId);
    }
  }, [regionId]);

  return (
    <Select
      id={id}
      name={id ? undefined : name || 'selected_utility'}
      label={label ?? 'Utility'}
      placeholder={placeholder}
      hideLabel={hideLabel}
      options={selectOptions}
      onOpen={() => filterUtilities('')}
      value={!isLoadingUtilities ? value : ''}
      fill={fill}
      width={width}
      setValue={setValue}
      error={error}
      required={required}
      disabled={disabled}
      onSearch={(query) => filterUtilities(query)}
      emptySearchMessage={emptySearchMessage}
      searchPlaceholder={searchPlaceholder}
      onSubmit={onSubmit}
      hideOptionalText={hideOptionalText}
    />
  );
});

export type UtilitiesSelectProps = {
  value?: Nullable<number>;
  setValue: (index: number) => void;
  utilities?: Utility[];
  error?: string;
  required?: boolean;
  regionId?: Nullable<TRegionId>;
  hideOptionalText?: boolean;
  hideLabel?: boolean;
  emptySearchMessage?: string;
  searchPlaceholder?: string;
  style?: SelectExtendedProps['style'];
  fill?: BoxProps['fill'];
  width?: BoxProps['width'];
  label?: string;
  placeholder?: string;
  id?: string;
  name?: string;
  disabled?: boolean;
  onSubmit?: (event: React.KeyboardEvent<HTMLElement>) => void;
};
