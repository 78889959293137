import type { CancelablePromise, TListProgramsRequest, TListProgramsResponse } from '/src/api';
import { OpenAPI, request as __request } from '/src/api';

export class ProgramService {
  /**
   * List Programs
   * @returns Program[] Program[]
   * @throws ApiError
   */
  public static list(req?: TListProgramsRequest): CancelablePromise<TListProgramsResponse> {
    return __request(OpenAPI, { method: 'GET', url: '/programs', query: req });
  }
}
